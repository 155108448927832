import { Area, Line } from 'd3';
import React, { createContext, PropsWithChildren, useContext } from 'react';

import { MicroChartSeriesDataPoint } from './MicroChartSeries';

export interface MicroChartContextValue {
  width: number;
  height: number;
  lineGenerator: Line<MicroChartSeriesDataPoint>;
  areaGenerator: Area<MicroChartSeriesDataPoint>;
}

export const MicroChartContext = createContext<MicroChartContextValue | null>(null);

export const MicroChartProvider: React.FC<PropsWithChildren<MicroChartContextValue>> = ({
  children,
  ...rest
}) => <MicroChartContext.Provider value={rest}>{children}</MicroChartContext.Provider>;

export function useMicroChartContext() {
  const context = useContext(MicroChartContext);

  if (!context) {
    throw new Error('useMicroChartContext must be called within MicroChartProvider');
  }

  return context;
}
