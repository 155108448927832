import { styled } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

import { fadeIn } from '../../../../styles';
import { useTokenAnimation } from '../../providers';

const StyledBlockquote = styled('blockquote')(({ theme }) => ({
  margin: theme.spacing(theme.tokens.spacing['xsmall'], 0),
  paddingInlineStart: theme.tokens.spacing['small'],
  borderLeft: '2px solid currentColor',
  color: theme.tokens.color['text.subtle'],

  opacity: 0,
  animationName: fadeIn,
  animationFillMode: 'forwards',
}));

const AnimatedBlockquote: FC<{ children: ReactNode }> = ({ children }) => {
  const { duration, delayPerToken, getAndIncrement } = useTokenAnimation();
  const tokenIndex = getAndIncrement(1);
  return (
    <StyledBlockquote
      style={{
        animationDelay: `${tokenIndex * delayPerToken}ms`,
        animationDuration: `${duration}ms`,
      }}
    >
      {children}
    </StyledBlockquote>
  );
};

export default AnimatedBlockquote;
