import { ColorTokenKey, defaultTheme } from '@aily/ui-theme';
import { useTheme } from '@mui/material';
import React from 'react';

import { CircularProgressBar, CircularProgressBarProps } from '../CircularProgressBar';
import GaugeComparisonContent from './GaugeComparisonContent';
import GaugeDefaultContent from './GaugeDefaultContent';

export interface GaugeProps {
  value?: number;
  sentiment?: GaugeSentiment;
  targetValue?: number;
  children?: React.ReactNode;
}

export type GaugeSentiment = 'positive' | 'negative' | 'warning';

const sentimentColorMap: Record<GaugeSentiment, ColorTokenKey> = {
  positive: 'gradient.mint.vertical',
  negative: 'gradient.pink.vertical',
  warning: 'gradient.orange.vertical',
};

const circularProgressBarProps: Omit<
  CircularProgressBarProps,
  'value' | 'progressColor' | 'targetValue'
> = {
  size: 154,
  strokeWidth: 10,
  trackColor: defaultTheme.color['background.light'],
  backgroundColor: defaultTheme.color['neutral.black'],
  glow: true,
  shadowTip: true,
  targetSize: 14,
  targetColor: defaultTheme.color['neutral.white'],
};

// Enhance the component type to include subcomponents
interface GaugeComponent extends React.FC<GaugeProps> {
  DefaultContent: typeof GaugeDefaultContent;
  ComparisonContent: typeof GaugeComparisonContent;
}

const Gauge: GaugeComponent = ({ value = 0, sentiment = 'positive', targetValue, children }) => {
  const theme = useTheme();
  const progressColor = theme.tokens.color[sentimentColorMap[sentiment]];

  return (
    <CircularProgressBar
      value={value}
      progressColor={progressColor}
      targetValue={targetValue}
      data-testid="gauge"
      {...circularProgressBarProps}
    >
      <foreignObject x="0" y="0" width="100%" height="100%">
        {children}
      </foreignObject>
    </CircularProgressBar>
  );
};

Gauge.DefaultContent = GaugeDefaultContent;
Gauge.ComparisonContent = GaugeComparisonContent;

export default Gauge;
