import { defaultTheme } from '@aily/ui-theme';
import React, { memo, useMemo } from 'react';

import {
  MicroChart,
  MicroChartPathTweenOrigin,
  MicroChartProps,
  MicroChartSeriesDataPoint,
  MicroChartSeriesLineStyle,
  MicroChartSeriesType,
} from '../MicroChart';
import { duplicateSingleNumericDataPoint } from './utils';

export interface MicroLineChartProps extends MicroChartProps {
  data: MicroChartSeriesDataPoint[];
  trendlineData?: MicroChartSeriesDataPoint[] | null;
  color?: string;
  lineWidth?: number;
  lineOpacity?: number;
  trendlineWidth?: number;
  trendlineOpacity?: number;
  fillOpacity?: number;
  width?: number;
  height?: number;
  offsetTop?: number;
  offsetBottom?: number;
  animated?: boolean;
  tweenOrigin?: MicroChartPathTweenOrigin;
  gradientHeight?: string;
}

const MicroLineChart = ({
  data: dataProp,
  trendlineData: trendlineDataProp,
  color = defaultTheme.color['brand.aqua'],
  lineWidth = 1,
  lineOpacity = 1,
  trendlineWidth = lineWidth,
  trendlineOpacity = 1,
  fillOpacity = 0.3,
  width = 70,
  height = 24,
  offsetTop = 0.1,
  offsetBottom = 0.1,
  animated,
  tweenOrigin,
  gradientHeight,
  ...rest
}: MicroLineChartProps) => {
  const data = useMemo(() => duplicateSingleNumericDataPoint(dataProp), [dataProp]);

  const trendlineData = useMemo(
    () => (trendlineDataProp ? duplicateSingleNumericDataPoint(trendlineDataProp) : undefined),
    [trendlineDataProp],
  );

  return (
    <MicroChart
      width={width}
      height={height}
      maxPadding={offsetTop}
      minPadding={offsetBottom}
      {...rest}
    >
      <MicroChart.Series
        type={MicroChartSeriesType.Area}
        data={data}
        color={color}
        lineWidth={lineWidth}
        lineOpacity={lineOpacity}
        fillOpacity={fillOpacity}
        animationDuration={animated ? 300 : 0}
        tweenOrigin={tweenOrigin}
        gradientHeight={gradientHeight}
      />
      {!!trendlineData && (
        <MicroChart.Series
          type={MicroChartSeriesType.Line}
          data={trendlineData}
          color={color}
          lineWidth={trendlineWidth}
          lineOpacity={trendlineOpacity}
          lineStyle={MicroChartSeriesLineStyle.Dashed}
          animationDuration={animated ? 300 : 0}
          tweenOrigin={tweenOrigin}
        />
      )}
    </MicroChart>
  );
};

export default memo(MicroLineChart);
