import { ApolloCache, NormalizedCacheObject } from '@apollo/client/core';

import { getPersistHandler } from './getPersistHandler';
import { getPurgeHandler } from './getPurgeHandler';
import { getRemoveConfigurationHandler } from './getRemoveConfigurationHandler';
import { getRestoreHandler } from './getRestoreHandler';

/**
 * Creates a cache persistor that automatically saves Apollo cache state to localStorage
 * @param cache - The Apollo cache instance to persist
 * @param key - The localStorage key to store the cache under
 * @returns An object with methods to:
 * - restore: Load cached data from localStorage back into Apollo cache
 * - purge: Remove cached data from both localStorage and memory
 * - removeConfiguration: Remove page content configuration from the cache
 *
 * The persistor also automatically saves the cache state after any:
 * - write operation
 * - eviction
 * - modification
 * - garbage collection
 */
export const CachePersistor = (cache: ApolloCache<NormalizedCacheObject>, key: string) => {
  const write = cache.write;
  const evict = cache.evict;
  const modify = cache.modify;
  const gc = cache.gc;

  const persist = getPersistHandler(cache, key);

  cache.write = (...args) => {
    const result = write.apply(cache, args);
    persist();
    return result;
  };

  cache.evict = (...args) => {
    const result = evict.apply(cache, args);
    persist();
    return result;
  };

  cache.modify = ((...args: Parameters<typeof modify>) => {
    const result = (modify as any).apply(cache, args);
    persist();
    return result;
  }) as typeof modify;

  cache.gc = (...args) => {
    const result = gc.apply(cache, args);
    persist();
    return result;
  };

  return {
    restore: getRestoreHandler(cache, key),
    purge: getPurgeHandler(cache, key),
    removeConfiguration: getRemoveConfigurationHandler(cache),
  };
};
