import { styled } from '@mui/material/styles';
import React from 'react';

import { ButtonBase } from '../../../../../buttons';
import { Typography } from '../../../../../Typography';

export interface AgentCardButtonProps {
  variant?: AgentCardButtonVariant;
  text: string;
  onClick?: () => void;
}

export type AgentCardButtonVariant = 'dark' | 'light';

const ButtonRoot = styled(ButtonBase)<{ $variant?: AgentCardButtonVariant }>(
  ({ theme, $variant = 'dark' }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.tokens.spacing['xsmall'],
    minWidth: 153,
    height: 38,
    borderRadius: theme.tokens.borderRadius['large'],

    ...($variant === 'dark' && {
      backgroundColor: theme.tokens.color['neutral.grey-darker'],
      color: theme.tokens.color['text.regular'],
    }),

    ...($variant === 'light' && {
      backgroundColor: theme.tokens.color['neutral.white'],
      color: theme.tokens.color['neutral.black'],
    }),
  }),
);

export const AgentCardButton: React.FC<AgentCardButtonProps> = ({
  variant = 'dark',
  text,
  onClick,
}) => (
  <ButtonRoot $variant={variant} onClick={onClick}>
    <Typography variant="heading.button" lineClamp={1}>
      {text}
    </Typography>
  </ButtonRoot>
);

export default AgentCardButton;
