import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import { ComponentType, FC } from 'react';

export interface LegendItemSymbolProps {
  type: LegendItemSymbolType;
  color?: ColorTokenKey;
}

export type LegendItemSymbolType = 'filled-circle' | 'outlined-circle' | 'vertical-bar';

const LegendItemSymbolRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: ColorTokenKey }>(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.tokens.color[color],
}));

const FilledCircleSymbol = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
    <circle cx="6.5" cy="6.5" r="5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const OutlinedCircleSymbol = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <circle cx="6.5" cy="6.5" r="5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const VerticalBarSymbol = () => (
  <svg width="3" height="15" viewBox="0 0 3 15" fill="currentColor">
    <path
      d="M1.5 13.2029L1.5 1.20288"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const symbolComponentMap: Record<LegendItemSymbolType, ComponentType> = {
  'filled-circle': FilledCircleSymbol,
  'outlined-circle': OutlinedCircleSymbol,
  'vertical-bar': VerticalBarSymbol,
};

const LegendItemSymbol: FC<LegendItemSymbolProps> = ({ type, color = 'text.regular' }) => {
  const SymbolComponent = symbolComponentMap[type];
  return (
    <LegendItemSymbolRoot color={color}>
      <SymbolComponent />
    </LegendItemSymbolRoot>
  );
};

export default LegendItemSymbol;
