import { ButtonProps, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';

import { ButtonBase } from '../buttons';
import { CorrelatorIcon } from '../icons';
import { ModuleCode, ModuleIcon } from '../ModuleIcon';

export interface ModuleSelectItemProps extends Omit<ButtonProps, 'children'> {
  moduleCode: ModuleCode;
  showCorrelatorIcon?: boolean;
}

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 104,
  height: 104,
  opacity: 1,
  borderRadius: theme.tokens.borderRadius['medium'],
  padding: 0,
  background: theme.tokens.color['gradient.aqua.vertical'],
  color: theme.tokens.color['text.regular'],
  transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  ...theme.tokens.typography['heading.H5-bold'],
  svg: {
    width: 'auto',
    height: '1em',
  },
}));

const ModuleSelectItem: React.FC<ModuleSelectItemProps> = ({
  showCorrelatorIcon,
  moduleCode,
  ...rest
}) => (
  <StyledButton
    sx={(theme) => ({
      background: showCorrelatorIcon ? alpha(theme.tokens.color['brand.aqua'], 0.2) : undefined,
    })}
    disableHoverStyle
    {...rest}
  >
    {showCorrelatorIcon ? (
      <CorrelatorIcon size="icon.xlarge" />
    ) : (
      <ModuleIcon moduleCode={moduleCode} size="icon.small" showDot={true} />
    )}
  </StyledButton>
);

export default ModuleSelectItem;
