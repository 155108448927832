import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const EditIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M18.4533 2.90076C17.2523 1.69975 15.305 1.69975 14.104 2.90076L4.18932 12.8155C3.95004 13.0547 3.7769 13.352 3.68678 13.6781L2.0729 19.5195C1.66809 20.9847 3.01528 22.3319 4.48046 21.9271L10.3219 20.3132C10.648 20.2231 10.9453 20.05 11.1845 19.8107L21.0992 9.89597C22.3003 8.69496 22.3003 6.74774 21.0992 5.54673L18.4533 2.90076ZM15.2902 4.08691C15.8361 3.541 16.7212 3.541 17.2671 4.08691L19.9131 6.73289C20.459 7.2788 20.459 8.1639 19.9131 8.70981L10.3193 18.3036L5.69639 13.6807L15.2902 4.08691ZM4.9713 15.3279L3.6898 19.9663C3.63197 20.1756 3.82442 20.368 4.03374 20.3102L8.67208 19.0287L4.9713 15.3279Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default EditIcon;
