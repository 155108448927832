import { Grid2 as Grid } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import ModuleSelectItem, { ModuleSelectItemProps } from './ModuleSelectItem';

interface Props {
  children?:
    | React.ReactElement<ModuleSelectItemProps>
    | React.ReactElement<ModuleSelectItemProps>[];
}

const ModuleSelect: React.FC<Props> & {
  Item: typeof ModuleSelectItem;
} = ({ children }) => (
  <Grid container columnSpacing={2} rowSpacing={2.5}>
    {React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return (
          <Grid
            key={index}
            component={motion.div}
            custom={index}
            animate="visible"
            initial={{ opacity: 0 }}
            variants={{
              visible: (index: number) => ({
                opacity: 1,
                transition: { delay: index * 0.02, duration: 0.1 },
              }),
            }}
          >
            {child}
          </Grid>
        );
      }

      return child;
    })}
  </Grid>
);

ModuleSelect.Item = ModuleSelectItem;

export default ModuleSelect;
