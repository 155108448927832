import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const CommunicationIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M1.66699 14.1665V2.5415C1.66699 2.34706 1.75727 2.15262 1.93783 1.95817C2.11838 1.76373 2.30588 1.6665 2.50033 1.6665H13.3128C13.5212 1.6665 13.7156 1.76025 13.8962 1.94775C14.0767 2.13525 14.167 2.33317 14.167 2.5415V9.95817C14.167 10.1526 14.0767 10.3471 13.8962 10.5415C13.7156 10.7359 13.5212 10.8332 13.3128 10.8332H5.00033L1.66699 14.1665ZM5.85449 14.9998C5.66005 14.9998 5.46908 14.9026 5.28158 14.7082C5.09408 14.5137 5.00033 14.3193 5.00033 14.1248V12.0832H15.417V4.99984H17.5003C17.6948 4.99984 17.8823 5.09706 18.0628 5.2915C18.2434 5.48595 18.3337 5.68734 18.3337 5.89567V18.3123L15.0212 14.9998H5.85449ZM12.917 2.9165H2.91699V11.1457L4.47949 9.58317H12.917V2.9165Z" />
  </SvgIcon>
);

export default CommunicationIcon;
