import { buttonClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { ArrowUpAltIcon, ButtonBase } from '../../../../components';

export interface SendButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

const SendButtonRoot = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 28,
  height: 28,
  borderRadius: 28,
  backgroundColor: theme.tokens.color['text.regular'],

  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: theme.tokens.color['neutral.grey-lighter'],
  },
}));

const SendButton: FC<SendButtonProps> = (props) => (
  <SendButtonRoot {...props}>
    <ArrowUpAltIcon size="icon.small" color="background.regular" />
  </SendButtonRoot>
);

export default SendButton;
