import { styled } from '@mui/material';
import React from 'react';

import { MaybeButtonBase } from '../../buttons';
import { Typography } from '../../Typography';

export interface GaugeDefaultContentProps {
  value?: string;
  label?: string;
  onValueClick?: () => void;
  onLabelClick?: () => void;
}

const GaugeDefaultContentRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.tokens.spacing['xxsmall'],
  height: '100%',
  padding: theme.tokens.spacing['xlarge'],
  userSelect: 'none',

  '& > *': {
    maxWidth: '100%',
  },
}));

const GaugeDefaultContent: React.FC<GaugeDefaultContentProps> = ({
  value,
  label,
  onValueClick,
  onLabelClick,
}) => (
  <GaugeDefaultContentRoot>
    {value && (
      <MaybeButtonBase onClick={onValueClick} disableHoverStyle data-testid="gauge__value-button">
        <Typography variant="heading.H4" lineClamp={1} data-testid="gauge__value">
          {value}
        </Typography>
      </MaybeButtonBase>
    )}
    {label && (
      <MaybeButtonBase onClick={onLabelClick} disableHoverStyle data-testid="gauge__label-button">
        <Typography variant="body.bold-condensed" lineClamp={1} data-testid="gauge__label">
          {label}
        </Typography>
      </MaybeButtonBase>
    )}
  </GaugeDefaultContentRoot>
);

export default GaugeDefaultContent;
