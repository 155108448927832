import * as T from '@aily/graphql-sdk/schema';
import { MicroLineChart, MyPrioritiesRow, MyPrioritiesRowPulseProps } from '@aily/ui-components';
import {
  Box,
  CardActionArea,
  CardHeader,
  CardMedia,
  CardProps,
  styled,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';

import { colors } from '../../theme/default/colors';
import {
  mapSentimentToColor,
  mapSentimentToMyPrioritiesRowPulseRankSentiment,
  mapSentimentToMyPrioritiesRowPulseValueSentiment,
} from '../../utils';
import { FitText } from '../FitText';
import { StyledCard } from './MicroChartCard';

interface Props extends CardProps {
  card: T.PulseMicroChartCardResult;
  onCardClick?: () => void;
}

const PulseContent = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1.75),
  paddingRight: theme.spacing(1.75),
  paddingTop: theme.spacing(0.5),
}));

export const mapPulseCardToMyPrioritiesRowPulse = (
  pulseMicroChartCard: T.PulseMicroChartCardResult,
): MyPrioritiesRowPulseProps => {
  const {
    pulseValue,
    pulseSentiment,
    medianValue,
    leaderboardPositionValue,
    leaderboardPositionSentiment,
  } = pulseMicroChartCard;

  return {
    progressValue: Number(pulseValue || 0),
    value: String(pulseValue || 0),
    valueSentiment: mapSentimentToMyPrioritiesRowPulseValueSentiment(pulseSentiment),
    total: String(medianValue || 0),
    rank: leaderboardPositionValue ? `#${leaderboardPositionValue}` : '#0',
    rankSentiment: mapSentimentToMyPrioritiesRowPulseRankSentiment(leaderboardPositionSentiment),
  };
};

const PulseMicroChartCard: FC<Props> = ({ card, onCardClick, ...rest }) => {
  return (
    <StyledCard {...rest}>
      <CardActionArea component="div" onClick={onCardClick}>
        <CardHeader
          disableTypography
          title={
            <Typography variant="h9" data-testid="Title">
              <FitText minFontSize={90}>{card.title.value ?? ''}</FitText>
            </Typography>
          }
          subheader={
            !!card.title.subLabels?.length && (
              <Typography variant="small" color="text.secondary">
                {card.title.subLabels[0]}
              </Typography>
            )
          }
        />
        <PulseContent>
          <MyPrioritiesRow.Pulse {...mapPulseCardToMyPrioritiesRowPulse(card)} />
        </PulseContent>
        <CardMedia>
          <MicroLineChart
            data={(card.microChart?.seriesData ?? []) as number[]}
            trendlineData={(card.microChart?.trendlineData ?? []) as number[]}
            color={mapSentimentToColor(card.microChart?.sentiment ?? T.Sentiment.Neutral, {
              [T.Sentiment.Neutral]: colors.primary.orange,
            })}
            lineWidth={2}
            trendlineWidth={0.5}
            trendlineOpacity={0.5}
            fillOpacity={1}
            offsetTop={0.1}
            offsetBottom={0.3}
            animated
          />
        </CardMedia>
      </CardActionArea>
    </StyledCard>
  );
};

export default PulseMicroChartCard;
