import { alpha, styled } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

import { Glow } from './Glow';

export interface CardRecommendationListProps {
  children: ReactNode;
  height?: number | string;
  disableGlow?: boolean;
}

const borderWidth = 1;

/**
 * Outer container with a gradient background (border).
 */
const Root = styled('div')<{ $height?: number | string }>(({ theme, $height = 'auto' }) => ({
  position: 'relative',
  width: '100%',
  height: $height,
  borderRadius: theme.tokens.borderRadius['medium'],
  padding: borderWidth,
  background: `linear-gradient(
    230deg,
    ${theme.tokens.color['neutral.white']} 0%,
    ${alpha(theme.tokens.color['brand.mint'], 0.5)} 15%,
    ${theme.tokens.color['brand.aqua']} 35%,
    ${alpha(theme.tokens.color['brand.aqua'], 0.5)} 70%
  )`,
}));

/**
 * Inner content container with padding that reveals the gradient border.
 */
const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.tokens.spacing['xlarge'],
  width: '100%',
  height: '100%',
  borderRadius: parseInt(theme.tokens.borderRadius['medium']) - borderWidth / 2, // Correct way to calculate inner border radius
  padding: theme.tokens.spacing['medium'],
  backgroundColor: theme.tokens.color['background.regular'],
  overflow: 'hidden',
  button: {
    width: '100%',
    height: '100%',
  },
}));

/**
 * Container for the glow effect.
 */
const GlowContainer = styled('div')({
  position: 'absolute',
  top: -10,
  right: 30,
  svg: {
    width: '100%',
    height: '100%',
  },
});

/**
 * CardRecommendationBase composes the gradient border effect with inner padding and glow.
 */
export const CardRecommendationBase: FC<CardRecommendationListProps> = ({
  children,
  height,
  disableGlow,
}) => (
  <Root $height={height}>
    <Content>{children}</Content>
    {!disableGlow && (
      <GlowContainer>
        <Glow />
      </GlowContainer>
    )}
  </Root>
);
