import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const PplIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 50 35" {...props}>
    <path d="M5.41318 26.1252C5.41318 26.8752 5.15646 27.5099 4.67154 28.0003C4.18662 28.4907 3.55908 28.7215 2.78891 28.7215C2.01875 28.7215 1.39121 28.4907 0.906291 28.0003C0.421373 27.5099 0.193176 26.8752 0.193176 26.1252C0.193176 25.3463 0.421373 24.7116 0.906291 24.2212C1.39121 23.7308 1.99023 23.5 2.78891 23.5C3.53055 23.5 4.1581 23.7308 4.67154 24.2212C5.15646 24.7116 5.41318 25.3463 5.41318 26.1252Z" />
    <path d="M20.7836 18.9444C20.7836 16.9917 20.3361 15.4729 19.4411 14.388C18.5703 13.3032 17.1553 12.7608 15.1959 12.7608C14.7847 12.7608 14.3493 12.7849 13.8897 12.8331C13.4543 12.8572 13.0189 12.9295 12.5835 13.0501V23.8623C12.9705 24.1275 13.4785 24.3806 14.1074 24.6217C14.7605 24.8387 15.4499 24.9472 16.1756 24.9472C17.7721 24.9472 18.9332 24.4047 19.6588 23.3199C20.4087 22.2351 20.7836 20.7765 20.7836 18.9444ZM25.2828 18.872C25.2828 20.3185 25.0893 21.6444 24.7023 22.8498C24.3394 24.0552 23.8073 25.0918 23.1058 25.9597C22.4043 26.8275 21.5214 27.5026 20.4571 27.9847C19.417 28.4669 18.2317 28.7079 16.9013 28.7079C16.0063 28.7079 15.1838 28.5994 14.434 28.3825C13.6841 28.1655 13.0673 27.9244 12.5835 27.6593V35H8.19318V10.0125C9.08818 9.77144 10.1888 9.54242 11.495 9.32545C12.8012 9.10848 14.18 9 15.6313 9C17.1311 9 18.4736 9.22902 19.6588 9.68707C20.8441 10.1451 21.848 10.8081 22.6704 11.6759C23.517 12.5197 24.158 13.5563 24.5934 14.7858C25.053 15.9912 25.2828 17.3533 25.2828 18.872Z" />
    <path d="M39.694 18.9444C39.694 16.9917 39.2465 15.4729 38.3515 14.388C37.4807 13.3032 36.0656 12.7608 34.1063 12.7608C33.6951 12.7608 33.2597 12.7849 32.8001 12.8331C32.3647 12.8572 31.9293 12.9295 31.4939 13.0501V23.8623C31.8809 24.1275 32.3889 24.3806 33.0178 24.6217C33.6709 24.8387 34.3603 24.9472 35.086 24.9472C36.6824 24.9472 37.8435 24.4047 38.5692 23.3199C39.3191 22.2351 39.694 20.7765 39.694 18.9444ZM44.1932 18.872C44.1932 20.3185 43.9997 21.6444 43.6126 22.8498C43.2498 24.0552 42.7176 25.0918 42.0162 25.9597C41.3147 26.8275 40.4318 27.5026 39.3674 27.9847C38.3273 28.4669 37.142 28.7079 35.8116 28.7079C34.9166 28.7079 34.0942 28.5994 33.3443 28.3825C32.5945 28.1655 31.9776 27.9244 31.4939 27.6593V35H27.1035V10.0125C27.9985 9.77144 29.0991 9.54242 30.4054 9.32545C31.7116 9.10848 33.0903 9 34.5417 9C36.0414 9 37.3839 9.22902 38.5692 9.68707C39.7545 10.1451 40.7583 10.8081 41.5807 11.6759C42.4274 12.5197 43.0684 13.5563 43.5038 14.7858C43.9634 15.9912 44.1932 17.3533 44.1932 18.872Z" />
    <path d="M49.1932 12.4517V28.3916C49.1932 28.7972 49.0208 29 48.6759 29H45.7104C45.3656 29 45.1932 28.7972 45.1932 28.3916V0.608391C45.1932 0.202797 45.3656 0 45.7104 0H48.6759C49.0208 0 49.1932 0.202797 49.1932 0.608391V8.19301V12.4517Z" />
  </SvgIcon>
);

export default PplIcon;
