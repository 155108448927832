import MyPrioritiesRowPulse from './MyPrioritiesRowPulse';

const MyPrioritiesRow = {
  // Default: MyPrioritiesRowDefault,
  // Kpi: MyPrioritiesRowKpi,
  // Milestone: MyPrioritiesRowMilestone,
  Pulse: MyPrioritiesRowPulse,
};

export default MyPrioritiesRow;
