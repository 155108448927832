import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const FinIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 48 35" {...props}>
      <path d="M18.6677 4.15205C18.1422 4.08768 17.5116 4.02331 16.7059 4.02331C15.5849 4.02331 14.7091 4.34517 14.1136 4.9889C13.518 5.63263 13.2027 6.53385 13.2027 7.69256V8.59379H18.2123C18.6327 8.59379 18.8779 8.7869 18.8779 9.20533V12.0699C18.8779 12.4562 18.6677 12.6815 18.2123 12.6815H13.2378V28.3885C13.2378 28.7747 13.0276 29 12.5722 29H8.85878C8.4384 29 8.19318 28.8069 8.19318 28.3885V7.69256C8.19318 5.21421 8.92885 3.31521 10.4002 1.99556C11.8715 0.675916 13.7282 0 15.9702 0C17.0562 0 17.932 0.0643729 18.5976 0.160932C19.018 0.193119 19.1932 0.386238 19.1932 0.804662V3.54051C19.1932 3.95893 19.018 4.15205 18.6677 4.15205Z" />
      <path d="M24.365 29H21.0521C20.684 29 20.4692 28.8045 20.4692 28.3809V8.7C20.4692 8.30899 20.6533 8.14607 21.0521 8.14607H24.365C24.733 8.14607 24.8864 8.34157 24.8864 8.7V28.4135C24.8864 28.8045 24.7024 29 24.365 29ZM24.4877 0.749438C24.9478 1.2382 25.1932 1.8573 25.1932 2.63933C25.1932 3.38876 24.9478 4.00787 24.4877 4.52921C24.0275 5.05056 23.4447 5.31124 22.7085 5.31124C22.003 5.31124 21.3895 5.05056 20.9294 4.52921C20.4386 4.00787 20.1932 3.38876 20.1932 2.63933C20.1932 1.88989 20.4386 1.27079 20.9294 0.749438C21.4202 0.260674 22.003 0 22.7085 0C23.4447 0 24.0275 0.260674 24.4877 0.749438Z" />
      <path d="M33.3065 28.9665H29.8184C29.4235 28.9665 29.1932 28.7652 29.1932 28.3293V9.34756C29.1932 8.97866 29.3577 8.7439 29.6539 8.60976C32.089 7.53659 34.7873 7 37.7489 7C40.7105 7 43.014 7.73781 44.6923 9.24695C46.3705 10.7561 47.1932 13.1037 47.1932 16.2896V28.3628C47.1932 28.7652 46.9957 29 46.6338 29H43.0798C42.6849 29 42.4546 28.7988 42.4546 28.3628V16.3567C42.4546 14.5122 42.0597 13.2043 41.27 12.4329C40.4802 11.6616 39.2955 11.2927 37.716 11.2927C36.301 11.2927 35.0177 11.4604 33.8988 11.7622V28.3628C33.9317 28.7652 33.7343 28.9665 33.3065 28.9665Z" />
      <path d="M5.41318 26.1244C5.41318 26.8743 5.15646 27.5087 4.67154 27.999C4.18662 28.4893 3.55908 28.72 2.78892 28.72C2.01875 28.72 1.39121 28.4893 0.906291 27.999C0.421373 27.5087 0.193176 26.8743 0.193176 26.1244C0.193176 25.3457 0.421373 24.7113 0.906291 24.221C1.39121 23.7307 1.99023 23.5 2.78892 23.5C3.53055 23.5 4.1581 23.7307 4.67154 24.221C5.15646 24.7113 5.41318 25.3457 5.41318 26.1244Z" />
    </SvgIcon>
  );
};

export default FinIcon;
