import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const ProIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 51 35" {...props}>
    <path d="M12.8677 18.9889C12.8677 17.0416 12.4183 15.527 11.5194 14.4452C10.6447 13.3634 9.22347 12.8225 7.25558 12.8225C6.84256 12.8225 6.40525 12.8465 5.94365 12.8946C5.50633 12.9186 5.06902 12.9908 4.63171 13.111V23.8932C5.02043 24.1577 5.53063 24.4101 6.1623 24.6505C6.81827 24.8669 7.51067 24.975 8.23952 24.975C9.843 24.975 11.0092 24.4341 11.738 23.3523C12.4912 22.2705 12.8677 20.816 12.8677 18.9889ZM17.3866 18.9168C17.3866 20.3592 17.1922 21.6815 16.8035 22.8835C16.4391 24.0855 15.9046 25.1193 15.2 25.9847C14.4955 26.8502 13.6087 27.5233 12.5397 28.0042C11.4951 28.485 10.3046 28.7254 8.96838 28.7254C8.06946 28.7254 7.24343 28.6172 6.49028 28.4008C5.73714 28.1845 5.11761 27.9441 4.63171 27.6796V35H0.222168V10.0818C1.12108 9.84142 2.22651 9.61304 3.53844 9.39667C4.85037 9.1803 6.23519 9.07212 7.69289 9.07212C9.19918 9.07212 10.5476 9.30051 11.738 9.75728C12.9285 10.2141 13.9367 10.8752 14.7627 11.7406C15.6131 12.5821 16.2569 13.6158 16.6942 14.8419C17.1558 16.0439 17.3866 17.4022 17.3866 18.9168Z" />
    <path d="M30.9688 13.3994C30.6044 13.2792 30.0942 13.159 29.4383 13.0388C28.8066 12.8946 28.0656 12.8225 27.2153 12.8225C26.7294 12.8225 26.207 12.8706 25.6482 12.9667C25.1137 13.0629 24.7372 13.147 24.5185 13.2191V28.3287H20.109V10.3703C20.9593 10.0578 22.0161 9.7693 23.2795 9.50485C24.5671 9.21637 25.9884 9.07212 27.5432 9.07212C27.8348 9.07212 28.1749 9.09616 28.5636 9.14425C28.9524 9.16829 29.3411 9.21637 29.7298 9.28849C30.1185 9.33657 30.4951 9.40869 30.8595 9.50485C31.2239 9.57698 31.5155 9.6491 31.7341 9.72122L30.9688 13.3994Z" />
    <path d="M50.2222 18.8807C50.2222 20.3712 50.0035 21.7295 49.5662 22.9556C49.1289 24.1817 48.5094 25.2275 47.7076 26.0929C46.9059 26.9584 45.9341 27.6315 44.7922 28.1123C43.6747 28.5932 42.4356 28.8336 41.0751 28.8336C39.7146 28.8336 38.4755 28.5932 37.358 28.1123C36.2404 27.6315 35.2807 26.9584 34.479 26.0929C33.6773 25.2275 33.0456 24.1817 32.584 22.9556C32.1467 21.7295 31.928 20.3712 31.928 18.8807C31.928 17.3902 32.1467 16.0439 32.584 14.8419C33.0456 13.6158 33.6773 12.57 34.479 11.7046C35.305 10.8391 36.2768 10.178 37.3944 9.72122C38.512 9.24041 39.7389 9 41.0751 9C42.4113 9 43.6382 9.24041 44.7558 9.72122C45.8977 10.178 46.8695 10.8391 47.6712 11.7046C48.4729 12.57 49.0924 13.6158 49.5298 14.8419C49.9914 16.0439 50.2222 17.3902 50.2222 18.8807ZM45.7033 18.8807C45.7033 17.0055 45.2903 15.527 44.4642 14.4452C43.6625 13.3393 42.5328 12.7864 41.0751 12.7864C39.6174 12.7864 38.4755 13.3393 37.6495 14.4452C36.8478 15.527 36.4469 17.0055 36.4469 18.8807C36.4469 20.7799 36.8478 22.2825 37.6495 23.3884C38.4755 24.4942 39.6174 25.0472 41.0751 25.0472C42.5328 25.0472 43.6625 24.4942 44.4642 23.3884C45.2903 22.2825 45.7033 20.7799 45.7033 18.8807Z" />
  </SvgIcon>
);

export default ProIconNoDot;
