import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ArrowDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <path d="M10.48 13.5a.5.5 0 0 0-.347.16.5.5 0 0 0 .027.707l6.5 6a.5.5 0 0 0 .68 0l6.5-6a.5.5 0 0 0 .027-.707.5.5 0 0 0-.707-.027L17 19.32l-6.16-5.687a.5.5 0 0 0-.36-.133Z" />
  </SvgIcon>
);

export default ArrowDownIcon;
