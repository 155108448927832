import { styled } from '@mui/material';
import { FC } from 'react';

import { Typography } from '../../../Typography';
import { LegendSize } from './Legend';
import LegendItemSymbol, { LegendItemSymbolProps } from './LegendItemSymbol';

export interface LegendItemProps {
  name: string;
  symbol: LegendItemSymbolProps;
  legendSize?: LegendSize;
}

const LegendItemRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.tokens.spacing['xsmall'],
}));

const LegendItem: FC<LegendItemProps> = ({ name, symbol, legendSize = 'default' }) => (
  <LegendItemRoot>
    <LegendItemSymbol {...symbol} />
    <Typography
      variant={legendSize === 'small' ? 'body.xsmall' : 'body.small'}
      color="text.subtle"
      lineClamp={1}
    >
      {name}
    </Typography>
  </LegendItemRoot>
);

export default LegendItem;
