import React, { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from 'react';

interface TokenAnimationProviderProps {
  duration?: number;
  delayPerToken?: number;
  children?: ReactNode;
}

interface TokenAnimationContextValue {
  duration: number;
  delayPerToken: number;
  getAndIncrement: (by: number) => number;
}

const TokenAnimationContext = createContext<TokenAnimationContextValue | undefined>(undefined);

export const TokenAnimationProvider = ({
  duration = 200,
  delayPerToken = 20,
  children,
}: TokenAnimationProviderProps) => {
  const tokenCounterRef = useRef(0);

  const getAndIncrement = useCallback((by: number): number => {
    const current = tokenCounterRef.current;
    tokenCounterRef.current += by;
    return current;
  }, []);

  const value = useMemo(
    () => ({ duration, delayPerToken, getAndIncrement }),
    [duration, delayPerToken, getAndIncrement],
  );

  return <TokenAnimationContext.Provider value={value}>{children}</TokenAnimationContext.Provider>;
};

export const useTokenAnimation = () => {
  const context = useContext(TokenAnimationContext);

  if (context === undefined) {
    throw new Error('useTokenAnimation must be used within a TokenAnimationProvider');
  }

  return context;
};
