import { buttonClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '../../icons';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface ExpandButtonProps extends ButtonBaseProps {
  expanded?: boolean;
}

const ExpandButtonRoot = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.tokens.color['text.regular'],

  ...theme.tokens.typography['body.regular-condensed'],
  lineHeight: 1,

  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
  },
}));

const IconWrapper = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.tokens.spacing['xsmall'],
}));

const ExpandButton: React.FC<ExpandButtonProps> = ({ expanded, children, ...rest }) => {
  const IconComponent = expanded ? ChevronUpIcon : ChevronDownIcon;
  return (
    <ExpandButtonRoot {...rest}>
      {children}
      <IconWrapper>
        <IconComponent size="icon.xsmall" color="neutral.400" />
      </IconWrapper>
    </ExpandButtonRoot>
  );
};

export default ExpandButton;
