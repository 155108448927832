import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const PplIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 42 35" {...props}>
    <path d="M12.8834 18.9444C12.8834 16.9917 12.4359 15.4729 11.5409 14.388C10.6701 13.3032 9.25506 12.7608 7.29573 12.7608C6.88452 12.7608 6.44911 12.7849 5.98952 12.8331C5.55411 12.8572 5.11871 12.9295 4.6833 13.0501V23.8623C5.07033 24.1275 5.5783 24.3806 6.20722 24.6217C6.86033 24.8387 7.54972 24.9472 8.27539 24.9472C9.87188 24.9472 11.033 24.4047 11.7586 23.3199C12.5085 22.2351 12.8834 20.7765 12.8834 18.9444ZM17.3826 18.872C17.3826 20.3185 17.1891 21.6444 16.8021 22.8498C16.4392 24.0552 15.9071 25.0918 15.2056 25.9597C14.5041 26.8275 13.6212 27.5026 12.5569 27.9847C11.5167 28.4669 10.3315 28.7079 9.00107 28.7079C8.10607 28.7079 7.28364 28.5994 6.53377 28.3825C5.78391 28.1655 5.16709 27.9244 4.6833 27.6593V35H0.292969V10.0125C1.18797 9.77144 2.28858 9.54242 3.59479 9.32545C4.90101 9.10848 6.27979 9 7.73114 9C9.23087 9 10.5734 9.22902 11.7586 9.68707C12.9439 10.1451 13.9478 10.8081 14.7702 11.6759C15.6168 12.5197 16.2578 13.5563 16.6932 14.7858C17.1528 15.9912 17.3826 17.3533 17.3826 18.872Z" />
    <path d="M31.7938 18.9444C31.7938 16.9917 31.3463 15.4729 30.4513 14.388C29.5805 13.3032 28.1654 12.7608 26.2061 12.7608C25.7949 12.7608 25.3595 12.7849 24.8999 12.8331C24.4645 12.8572 24.0291 12.9295 23.5937 13.0501V23.8623C23.9807 24.1275 24.4887 24.3806 25.1176 24.6217C25.7707 24.8387 26.4601 24.9472 27.1857 24.9472C28.7822 24.9472 29.9433 24.4047 30.669 23.3199C31.4189 22.2351 31.7938 20.7765 31.7938 18.9444ZM36.293 18.872C36.293 20.3185 36.0995 21.6444 35.7124 22.8498C35.3496 24.0552 34.8174 25.0918 34.1159 25.9597C33.4145 26.8275 32.5316 27.5026 31.4672 27.9847C30.4271 28.4669 29.2418 28.7079 27.9114 28.7079C27.0164 28.7079 26.194 28.5994 25.4441 28.3825C24.6943 28.1655 24.0774 27.9244 23.5937 27.6593V35H19.2033V10.0125C20.0983 9.77144 21.1989 9.54242 22.5051 9.32545C23.8114 9.10848 25.1901 9 26.6415 9C28.1412 9 29.4837 9.22902 30.669 9.68707C31.8543 10.1451 32.8581 10.8081 33.6805 11.6759C34.5272 12.5197 35.1682 13.5563 35.6036 14.7858C36.0632 15.9912 36.293 17.3533 36.293 18.872Z" />
    <path d="M41.293 12.4517V28.3916C41.293 28.7972 41.1206 29 40.7757 29H37.8102C37.4654 29 37.293 28.7972 37.293 28.3916V0.608391C37.293 0.202797 37.4654 0 37.8102 0H40.7757C41.1206 0 41.293 0.202797 41.293 0.608391V8.19301V12.4517Z" />
  </SvgIcon>
);

export default PplIconNoDot;
