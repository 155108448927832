import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const SpendIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 106 35" {...props}>
    <circle cx="3" cy="26" r="3" />
    <path d="M14.3794 25.0937C15.5502 25.0937 16.404 24.9601 16.9407 24.693C17.4773 24.4017 17.7457 23.9161 17.7457 23.2362C17.7457 22.6049 17.4529 22.0829 16.8675 21.6701C16.3064 21.2574 15.3673 20.8082 14.05 20.3226C13.2451 20.0312 12.5011 19.7277 11.818 19.4121C11.1594 19.0721 10.5862 18.6837 10.0983 18.2466C9.61044 17.8096 9.22014 17.2875 8.92742 16.6805C8.65909 16.0493 8.52493 15.2844 8.52493 14.3861C8.52493 12.6379 9.17136 11.266 10.4642 10.2706C11.7571 9.25078 13.5134 8.74089 15.7332 8.74089C16.8553 8.74089 17.9286 8.85016 18.9531 9.06868C19.9777 9.26292 20.746 9.45716 21.2583 9.6514L20.4533 13.2206C19.9655 13.0021 19.3434 12.8078 18.5872 12.6379C17.831 12.4436 16.9529 12.3465 15.9527 12.3465C15.0502 12.3465 14.3184 12.5043 13.7573 12.82C13.1963 13.1113 12.9158 13.5727 12.9158 14.204C12.9158 14.5196 12.9645 14.7988 13.0621 15.0416C13.1841 15.2844 13.3792 15.5151 13.6476 15.7336C13.9159 15.9279 14.2696 16.1342 14.7087 16.3528C15.1478 16.547 15.6844 16.7534 16.3186 16.9719C17.3676 17.3604 18.2579 17.7489 18.9897 18.1374C19.7215 18.5016 20.3192 18.9265 20.7826 19.4121C21.2705 19.8734 21.6242 20.4076 21.8438 21.0146C22.0633 21.6216 22.1731 22.35 22.1731 23.1998C22.1731 25.0208 21.49 26.4048 20.124 27.3517C18.7824 28.2744 16.8553 28.7357 14.3428 28.7357C12.6596 28.7357 11.3058 28.59 10.2813 28.2986C9.25673 28.0316 8.53713 27.813 8.12244 27.6431L8.89083 23.9646C9.54946 24.2317 10.33 24.4866 11.2326 24.7294C12.1596 24.9723 13.2085 25.0937 14.3794 25.0937Z" />
    <path d="M38.6398 18.8293C38.6398 16.8626 38.1885 15.333 37.286 14.2404C36.4078 13.1478 34.9808 12.6015 33.0049 12.6015C32.5902 12.6015 32.1511 12.6257 31.6877 12.6743C31.2486 12.6986 30.8095 12.7714 30.3704 12.8928V23.7825C30.7607 24.0496 31.273 24.3045 31.9072 24.5473C32.5658 24.7659 33.261 24.8751 33.9928 24.8751C35.6028 24.8751 36.7737 24.3288 37.5055 23.2362C38.2617 22.1436 38.6398 20.6746 38.6398 18.8293ZM43.177 18.7565C43.177 20.2133 42.9818 21.5487 42.5915 22.7627C42.2256 23.9768 41.689 25.0208 40.9816 25.8949C40.2742 26.769 39.3838 27.4488 38.3105 27.9344C37.2616 28.42 36.0663 28.6629 34.7246 28.6629C33.8221 28.6629 32.9927 28.5536 32.2365 28.3351C31.4803 28.1165 30.8583 27.8737 30.3704 27.6067V35H25.943V9.83351C26.8456 9.5907 27.9555 9.36004 29.2727 9.14152C30.59 8.923 31.9804 8.81373 33.444 8.81373C34.9564 8.81373 36.3102 9.0444 37.5055 9.50572C38.7008 9.96705 39.7131 10.6348 40.5425 11.5088C41.3963 12.3587 42.0427 13.4027 42.4818 14.641C42.9452 15.855 43.177 17.2268 43.177 18.7565Z" />
    <path d="M46.3129 18.8293C46.3129 17.154 46.5568 15.6851 47.0447 14.4225C47.5569 13.1599 48.2278 12.1159 49.0571 11.2903C49.8865 10.4405 50.8379 9.80923 51.9112 9.39646C52.9845 8.95942 54.0822 8.74089 55.2043 8.74089C57.8388 8.74089 59.8878 9.55428 61.3514 11.1811C62.8394 12.8078 63.5834 15.2359 63.5834 18.4651C63.5834 18.7079 63.5712 18.9872 63.5468 19.3028C63.5468 19.5942 63.5346 19.8613 63.5103 20.1041H50.8866C51.0086 21.6337 51.5453 22.8234 52.4966 23.6733C53.4723 24.4988 54.875 24.9116 56.7045 24.9116C57.7778 24.9116 58.7535 24.8144 59.6317 24.6202C60.5343 24.4259 61.2417 24.2196 61.7539 24.001L62.3394 27.6067C62.0954 27.7281 61.7539 27.8616 61.3148 28.0073C60.9002 28.1287 60.4123 28.2379 59.8512 28.3351C59.3146 28.4565 58.7291 28.5536 58.0949 28.6264C57.4607 28.6993 56.8142 28.7357 56.1556 28.7357C54.4725 28.7357 53.0089 28.4929 51.7648 28.0073C50.5207 27.4974 49.4962 26.8054 48.6912 25.9313C47.8862 25.033 47.2886 23.9889 46.8983 22.7992C46.508 21.5852 46.3129 20.2619 46.3129 18.8293ZM59.156 16.8626C59.156 16.2556 59.0706 15.685 58.8999 15.1509C58.7291 14.5924 58.473 14.119 58.1315 13.7305C57.8144 13.3177 57.4119 13.0021 56.924 12.7836C56.4605 12.5408 55.8995 12.4194 55.2409 12.4194C54.5579 12.4194 53.9602 12.5529 53.448 12.82C52.9357 13.0628 52.4966 13.3906 52.1307 13.8033C51.7892 14.2161 51.5209 14.6896 51.3257 15.2237C51.1306 15.7579 50.9964 16.3042 50.9232 16.8626H59.156Z" />
    <path d="M67.9117 9.83351C68.7654 9.5907 69.8753 9.36004 71.2414 9.14152C72.6074 8.923 74.1198 8.81373 75.7785 8.81373C77.3397 8.81373 78.6448 9.03226 79.6937 9.4693C80.7426 9.88207 81.572 10.4769 82.1818 11.2539C82.816 12.0066 83.2551 12.9292 83.4991 14.0219C83.7674 15.0902 83.9016 16.2678 83.9016 17.5546V28.2622H79.4741V18.2466C79.4741 17.2268 79.401 16.3649 79.2546 15.6608C79.1326 14.9324 78.9131 14.3496 78.596 13.9126C78.3033 13.4513 77.8886 13.1235 77.3519 12.9292C76.8397 12.7107 76.2054 12.6015 75.4492 12.6015C74.8882 12.6015 74.3027 12.6379 73.6929 12.7107C73.0831 12.7836 72.6318 12.8443 72.3391 12.8928V28.2622H67.9117V9.83351Z" />
    <path d="M92.4256 18.6837C92.4256 20.6261 92.8891 22.1557 93.8161 23.2726C94.743 24.3652 96.0237 24.9116 97.658 24.9116C98.3655 24.9116 98.9631 24.8873 99.451 24.8387C99.9632 24.7659 100.378 24.693 100.695 24.6202V13.6941C100.305 13.427 99.7803 13.1842 99.1216 12.9657C98.4874 12.7229 97.8044 12.6015 97.0726 12.6015C95.4626 12.6015 94.2795 13.1478 93.5233 14.2404C92.7915 15.333 92.4256 16.8141 92.4256 18.6837ZM105.122 27.6795C104.244 27.9466 103.134 28.1894 101.793 28.4079C100.475 28.6264 99.0851 28.7357 97.6214 28.7357C96.1091 28.7357 94.7552 28.505 93.5599 28.0437C92.3646 27.5824 91.3401 26.9268 90.4864 26.077C89.657 25.2029 89.0106 24.1589 88.5471 22.9448C88.108 21.7066 87.8885 20.3226 87.8885 18.7929C87.8885 17.2875 88.0714 15.9279 88.4373 14.7138C88.8276 13.4755 89.3887 12.4194 90.1205 11.5453C90.8523 10.6712 91.7426 10.0035 92.7915 9.54214C93.8405 9.05654 95.0479 8.81373 96.414 8.81373C97.3409 8.81373 98.1581 8.923 98.8655 9.14152C99.5729 9.36004 100.183 9.60284 100.695 9.86993V0.728407L105.122 0V27.6795Z" />
  </SvgIcon>
);

export default SpendIcon;
