import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ArrowUpIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <path d="m16.648 13.469-6.4551 6.3496a0.5 0.5 0 0 0-0.0059 0.70703 0.5 0.5 0 0 0 0.70703 0.0059l6.1055-6.0039 6.1055 6.0039a0.5 0.5 0 0 0 0.70703-0.0059 0.5 0.5 0 0 0-0.0059-0.70703l-6.455-6.3496a0.50005 0.50005 0 0 0-0.70312 0z" />
  </SvgIcon>
);

export default ArrowUpIcon;
