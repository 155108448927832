import { useAuth, User } from '@aily/auth-service';
import { type ExperimentUser, FeatureFlagsProvider } from '@aily/feature-flags-service';
import React, { useMemo } from 'react';

import { featureFlagsConfig } from '@/config/featureFlags';

const createExperimentUser = (user?: User | null): ExperimentUser => {
  return { user_id: user?.profile.sub };
};

export const ConfiguredFeatureFlagsProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, isAuthenticated } = useAuth();
  // Only create the experiment user if the user is authenticated.
  const experimentUser = useMemo(
    () => (isAuthenticated ? createExperimentUser(user) : undefined),
    [user, isAuthenticated],
  );

  return (
    <FeatureFlagsProvider {...featureFlagsConfig} experimentUser={experimentUser}>
      {children}
    </FeatureFlagsProvider>
  );
};
