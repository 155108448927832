import { TextareaAutosize } from '@mui/base';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, FC, KeyboardEvent, memo, RefObject, useCallback, useState } from 'react';

import { scrollbarStyles } from '../../../../utils';
import { SendButton } from '../SendButton';

export interface InputBoxProps {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onSendButtonClick?: () => void;
  disabled?: boolean;
  inputRef?: RefObject<HTMLTextAreaElement>;
}

const InputBoxRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  alignItems: 'center',
  gap: theme.tokens.spacing['xsmall'],
  minWidth: 400,
  padding: theme.tokens.spacing['xsmall'],
  borderRadius: theme.tokens.borderRadius['medium'],
  backgroundColor: theme.tokens.color['background.light'],
  backdropFilter: 'blur(5px)',
}));

const InputBoxInput = styled(TextareaAutosize)(({ theme }) => ({
  display: 'block',
  minWidth: 0,
  width: '100%',
  resize: 'none', // Prevent manual resizing
  margin: 0,
  padding: theme.spacing(0, theme.tokens.spacing['xlarge'], 0, theme.tokens.spacing['xxsmall']),
  border: 'none',
  outline: 'none',
  background: 'none',
  color: theme.tokens.color['text.regular'],

  ...theme.tokens.typography['body.regular-condensed'],
  ...scrollbarStyles(theme.tokens.color['text.subtle'], theme.tokens.color['background.light']),

  '&::placeholder': {
    color: theme.tokens.color['text.subtle'],
  },
}));

const InputBoxToolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  alignItems: 'center',
  gap: theme.tokens.spacing['xsmall'],
}));

const InputBox: FC<InputBoxProps> = ({
  value,
  defaultValue = '',
  placeholder = 'Start typing here…',
  onChange,
  onSendButtonClick,
  disabled,
  inputRef,
}) => {
  const [localValue, setLocalValue] = useState(defaultValue);
  const currentValue = value ?? localValue;
  const isEmpty = currentValue.trim().length === 0;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setLocalValue(newValue);
      onChange?.(newValue);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent default Enter behavior
        if (!isEmpty) {
          onSendButtonClick?.();
        }
      }
    },
    [isEmpty, onSendButtonClick],
  );

  return (
    <InputBoxRoot>
      <InputBoxInput
        placeholder={placeholder}
        minRows={1}
        maxRows={5}
        value={currentValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown} // Handles Enter and Shift+Enter
        autoFocus
        disabled={disabled}
        ref={inputRef}
      />
      <InputBoxToolbar>
        <Box flex={1} />
        <SendButton disabled={disabled || isEmpty} onClick={onSendButtonClick} />
      </InputBoxToolbar>
    </InputBoxRoot>
  );
};

export default memo(InputBox);
