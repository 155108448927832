import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const QaIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 43 35" {...props}>
    <circle cx="3" cy="26" r="3" />
    <path d="M12.1137 20.9842C12.1137 23.6303 13.4889 24.9389 16.2101 24.9389C17.5561 24.9389 18.7265 24.5899 19.6921 23.9211V13.2784C18.8728 12.9295 17.7317 12.7841 16.2979 12.7841C14.9227 12.7841 13.8693 13.1039 13.1963 13.7436C12.4941 14.3834 12.143 15.4593 12.143 16.9423L12.1137 20.9842ZM7.90024 16.9714C7.90024 14.3543 8.66101 12.377 10.1533 11.0684C11.6748 9.7599 13.6645 9.09109 16.1809 9.09109C18.7558 9.09109 21.2136 9.49819 23.4959 10.3415C23.7593 10.4578 23.9056 10.6613 23.9056 10.9521V34.4475C23.9056 34.7965 23.73 35 23.3496 35H20.248C19.8969 35 19.6921 34.8255 19.6921 34.4475V27.8176C18.4632 28.341 17.1757 28.6027 15.859 28.6027C13.5182 28.6027 11.6163 27.9049 10.124 26.5091C8.63174 25.1133 7.90024 23.2523 7.90024 20.926V16.9714Z" />
    <path d="M30.6647 22.5544C30.6647 23.3686 31.0158 23.9793 31.718 24.3573C32.4203 24.7644 33.3566 24.9389 34.4978 24.9389C35.6096 24.9389 36.7801 24.7935 37.9797 24.5027V20.4317C36.663 20.2281 35.4926 20.1409 34.4685 20.1409C31.9229 20.1991 30.6647 20.9842 30.6647 22.5544ZM27.5338 11.0103C29.4065 9.73082 31.7473 9.09109 34.6441 9.09109C36.9849 9.09109 38.8283 9.70174 40.1742 10.923C41.5202 12.1443 42.1932 13.9763 42.1932 16.4189V26.9162C42.1932 27.207 42.0469 27.4105 41.7836 27.5268C39.4427 28.2538 37.0141 28.6027 34.4978 28.6027C32.1569 28.6027 30.2258 28.1375 28.7042 27.1779C27.1827 26.2183 26.422 24.7062 26.422 22.6126C26.422 20.7225 27.1535 19.2976 28.5872 18.309C30.021 17.3494 31.9521 16.855 34.3515 16.855C35.317 16.855 36.5167 16.9423 37.9797 17.1458V16.4479C37.9797 15.1394 37.7164 14.2089 37.1604 13.6564C36.6045 13.1039 35.6682 12.8131 34.3515 12.8131C32.5666 12.8131 31.0158 13.1912 29.7284 13.9763C29.4357 14.1798 29.2017 14.1507 28.9968 13.8309L27.5631 11.65C27.3875 11.3592 27.3875 11.1557 27.5338 11.0103Z" />
  </SvgIcon>
);

export default QaIcon;
