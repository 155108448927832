import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { ScrollableContainer } from '../../../../ScrollableContainer';
import { MilestonesRow, MilestonesRowProps } from './internal';

export interface RelatedDecissionsProps {
  primaryColumnItems: MilestonesRowProps[];
  secondaryColumnItems: MilestonesRowProps[];
}

const StyledStack = styled(Stack)({
  width: '100%',
  maxWidth: '650px',
  justifyContent: 'center',
});

const VerticalDivider = styled(Box)(({ theme }) => ({
  width: 2,
  marginLeft: theme.tokens.spacing.xxxlarge,
  marginRight: theme.tokens.spacing.xxxlarge,
  background: `linear-gradient(
    90deg,
    ${alpha(theme.tokens.color['brand.mint'], 0)} 0%,
    ${theme.tokens.color['brand.mint']} 50.5%,
    ${alpha(theme.tokens.color['brand.mint'], 0)} 100%
  )`,
  boxShadow: `0 0 10px ${theme.tokens.color['brand.mint']}, 0 0 20px ${theme.tokens.color['brand.mint']}, 0 0 30px ${theme.tokens.color['brand.mint']}`,
  opacity: 0.6,
}));

const Column = styled('div')(({ theme }) => ({
  width: '100%',
  gap: theme.tokens.spacing.medium,
  display: 'flex',
  flexDirection: 'column',
  height: 372,
}));

const ItemsList = styled('div')(({ theme }) => ({
  gap: theme.tokens.spacing.small,
  minHeight: 0,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const StyledScrollableContainer = styled(ScrollableContainer)({
  flex: 1,
  minHeight: 0,
  maxHeight: 372,
});

export const RelatedDecissionsTemplate: React.FC<RelatedDecissionsProps> = ({
  primaryColumnItems,
  secondaryColumnItems,
}) => {
  return (
    <StyledStack direction="row" divider={<VerticalDivider />}>
      <Column>
        <StyledScrollableContainer>
          <ItemsList>
            {primaryColumnItems.map((item) => (
              <MilestonesRow key={item.title} {...item} />
            ))}
          </ItemsList>
        </StyledScrollableContainer>
      </Column>
      <Column>
        <StyledScrollableContainer>
          <ItemsList>
            {secondaryColumnItems.map((item) => (
              <MilestonesRow key={item.title} {...item} />
            ))}
          </ItemsList>
        </StyledScrollableContainer>
      </Column>
    </StyledStack>
  );
};
