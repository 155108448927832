import * as T from '@aily/graphql-sdk/schema';
import { MicroLineChart } from '@aily/ui-components';
import { styled } from '@mui/material';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { kebabCase } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { LineChart } from '../../icons';
import { mapSentimentToColor } from '../../utils';

export interface TableCellMicroChartProps {
  microChart: T.MicroChartResult;
  drillDown?: T.DrillDownResult;
  onClick?: (microChart: T.MicroChartResult, drillDown?: T.DrillDownResult) => void;
  sentimentColorMap?: Partial<Record<T.Sentiment, string>>;
}

const [width, height] = [70, 24];

const CenteredContentBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width,
  height,
});

const FadeIn = styled((props: MotionProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.1, ease: 'linear' }}
    {...props}
  />
))({
  height: '100%',
});

export const TableCellMicroChart: React.FC<TableCellMicroChartProps> = ({
  microChart,
  drillDown,
  onClick,
  sentimentColorMap,
}) => {
  const { seriesData, trendlineData, sentiment } = microChart;
  const { ref, inView } = useInView({ initialInView: true, triggerOnce: true, rootMargin: '10%' });

  const handleClick = useCallback(() => {
    if (seriesData?.length) {
      onClick?.(microChart, drillDown);
    }
  }, [onClick, microChart, drillDown, seriesData]);

  const renderedContent = useMemo(
    () =>
      seriesData?.length ? (
        inView && (
          <FadeIn>
            <MicroLineChart
              data={seriesData}
              trendlineData={trendlineData}
              color={mapSentimentToColor(sentiment ?? T.Sentiment.Neutral, sentimentColorMap)}
              width={width}
              height={height}
              animated
            />
          </FadeIn>
        )
      ) : (
        <LineChart sx={{ fontSize: 20 }} />
      ),
    [inView, seriesData, trendlineData, sentiment],
  );

  // Show nothing if there is no data and link
  if (!seriesData?.length && !onClick) {
    return null;
  }

  return (
    <CenteredContentBox
      ref={ref}
      onClick={handleClick}
      sx={{ cursor: onClick ? 'pointer' : undefined }}
      data-testid="MicroChartCell"
      data-x-sentiment={sentiment ? kebabCase(sentiment) : undefined}
    >
      <AnimatePresence initial={false}>{renderedContent}</AnimatePresence>
    </CenteredContentBox>
  );
};

export default React.memo(TableCellMicroChart);
