import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ChevronRightIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="19" viewBox="0 0 18 19" {...props}>
    <path d="M7.324 5.582a.5.5 0 0 0-.355.143.5.5 0 0 0-.006.707L9.98 9.5l-3.017 3.068a.5.5 0 0 0 .006.707.5.5 0 0 0 .707-.005l3.361-3.418a.5.5 0 0 0 0-.702L7.676 5.73a.5.5 0 0 0-.352-.148z" />
  </SvgIcon>
);

export default ChevronRightIcon;
