import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const GraIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 58 35" {...props}>
      <path d="M6.03647 26.1252C6.03647 26.8752 5.77975 27.5099 5.29483 28.0003C4.80991 28.4907 4.18237 28.7215 3.41221 28.7215C2.64204 28.7215 2.0145 28.4907 1.52958 28.0003C1.04466 27.5099 0.816467 26.8752 0.816467 26.1252C0.816467 25.3463 1.04466 24.7116 1.52958 24.2212C2.0145 23.7308 2.61352 23.5 3.41221 23.5C4.15385 23.5 4.78139 23.7308 5.29483 24.2212C5.77975 24.7116 6.03647 25.3463 6.03647 26.1252Z" />
      <path d="M13.319 18.4305C13.319 20.2676 13.7185 21.6155 14.5173 22.4744C15.3404 23.3095 16.3934 23.727 17.6764 23.727C18.3784 23.727 19.032 23.6316 19.6372 23.4407C20.2666 23.2498 20.7749 23.0232 21.1622 22.7607V13.0624C20.8475 12.9908 20.4602 12.9312 20.0003 12.8834C19.5403 12.8119 18.9594 12.7761 18.2573 12.7761C16.6597 12.7761 15.4372 13.301 14.5899 14.3507C13.7427 15.3766 13.319 16.7365 13.319 18.4305ZM25.5559 26.3753C25.5559 29.3575 24.7812 31.5406 23.232 32.9243C21.7069 34.3081 19.3588 35 16.1876 35C15.0257 35 13.8879 34.9046 12.7744 34.7137C11.685 34.5228 10.6925 34.2723 9.79687 33.9622L10.5957 30.2761C11.3461 30.5862 12.1934 30.8367 13.1375 31.0276C14.1058 31.2185 15.1467 31.3139 16.2602 31.3139C18.0274 31.3139 19.2862 30.956 20.0366 30.2403C20.787 29.5245 21.1622 28.4629 21.1622 27.0552V26.3395C20.7265 26.5542 20.1455 26.7689 19.4193 26.9836C18.7173 27.1984 17.9063 27.3057 16.9865 27.3057C15.7761 27.3057 14.6625 27.1149 13.6458 26.7331C12.6533 26.3514 11.794 25.7907 11.0678 25.0511C10.3657 24.3115 9.80897 23.393 9.39744 22.2955C9.01013 21.1742 8.81647 19.8858 8.81647 18.4305C8.81647 17.0706 9.02223 15.818 9.43376 14.6728C9.86949 13.5276 10.4868 12.5494 11.2856 11.7383C12.1087 10.9271 13.1012 10.2948 14.2631 9.84153C15.4251 9.38822 16.7444 9.16157 18.221 9.16157C19.6493 9.16157 21.0049 9.26893 22.2879 9.48366C23.5709 9.69838 24.6602 9.92503 25.5559 10.1636V26.3753Z" />
      <path d="M40.2803 13.456C39.9172 13.3368 39.4089 13.2175 38.7553 13.0982C38.1259 12.955 37.3876 12.8834 36.5403 12.8834C36.0562 12.8834 35.5357 12.9312 34.9789 13.0266C34.4464 13.122 34.0711 13.2055 33.8533 13.2771V28.272H29.4596V10.4499C30.3069 10.1398 31.3599 9.85346 32.6187 9.59102C33.9017 9.30472 35.3178 9.16157 36.8671 9.16157C37.1576 9.16157 37.4965 9.18543 37.8838 9.23315C38.2711 9.257 38.6584 9.30472 39.0458 9.37629C39.4331 9.42401 39.8083 9.49559 40.1714 9.59102C40.5345 9.66259 40.825 9.73417 41.0429 9.80574L40.2803 13.456Z" />
      <path d="M50.4228 25.2301C51.7784 25.2301 52.8072 25.1585 53.5092 25.0153V20.2198C53.2671 20.1483 52.9161 20.0767 52.4562 20.0051C51.9962 19.9335 51.4879 19.8978 50.9311 19.8978C50.447 19.8978 49.9507 19.9335 49.4423 20.0051C48.9582 20.0767 48.5104 20.2079 48.0988 20.3988C47.7115 20.5896 47.3968 20.864 47.1548 21.2219C46.9127 21.5559 46.7916 21.9853 46.7916 22.5102C46.7916 23.5361 47.1184 24.2519 47.772 24.6575C48.4256 25.0392 49.3092 25.2301 50.4228 25.2301ZM50.0596 9.09C51.5121 9.09 52.7346 9.26893 53.7271 9.6268C54.7196 9.98468 55.5063 10.4857 56.0873 11.1299C56.6925 11.774 57.1161 12.5614 57.3582 13.4918C57.6244 14.3984 57.7576 15.4005 57.7576 16.498V27.8425C57.0798 27.9857 56.051 28.1527 54.6711 28.3436C53.3155 28.5583 51.7784 28.6656 50.0596 28.6656C48.9219 28.6656 47.881 28.5583 46.9369 28.3436C45.9928 28.1288 45.1819 27.7829 44.5041 27.3057C43.8505 26.8286 43.33 26.2083 42.9427 25.4448C42.5796 24.6813 42.398 23.7389 42.398 22.6176C42.398 21.544 42.6038 20.6374 43.0153 19.8978C43.451 19.1582 44.032 18.5617 44.7582 18.1084C45.4844 17.6312 46.3196 17.2972 47.2637 17.1063C48.232 16.8916 49.2366 16.7843 50.2775 16.7843C50.7617 16.7843 51.27 16.8201 51.8026 16.8916C52.3351 16.9393 52.904 17.0348 53.5092 17.1779V16.4622C53.5092 15.9612 53.4487 15.484 53.3276 15.0307C53.2066 14.5774 52.9887 14.1837 52.674 13.8497C52.3835 13.4918 51.9841 13.2175 51.4758 13.0266C50.9916 12.8357 50.3743 12.7403 49.6239 12.7403C48.6072 12.7403 47.6752 12.8119 46.828 12.955C45.9807 13.0982 45.2908 13.2652 44.7582 13.456L44.2136 9.94889C44.7703 9.75802 45.5813 9.56716 46.6464 9.37629C47.7115 9.18543 48.8493 9.09 50.0596 9.09Z" />
    </SvgIcon>
  );
};

export default GraIcon;
