import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const SupplyIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 108 35" {...props}>
    <path d="M6.7252 25.0415C7.88772 25.0415 8.73539 24.9083 9.26822 24.6417C9.80104 24.351 10.0674 23.8664 10.0674 23.188C10.0674 22.558 9.77682 22.037 9.19556 21.6251C8.63851 21.2132 7.70608 20.765 6.39824 20.2804C5.59901 19.9896 4.86032 19.6867 4.18218 19.3718C3.52826 19.0325 2.95911 18.6449 2.47473 18.2087C1.99034 17.7726 1.60283 17.2516 1.3122 16.6459C1.04579 16.0159 0.912586 15.2527 0.912586 14.3562C0.912586 12.6116 1.5544 11.2426 2.83801 10.2492C4.12163 9.23157 5.86542 8.72274 8.06936 8.72274C9.18345 8.72274 10.2491 8.83177 11.2663 9.04984C12.2835 9.24368 13.0464 9.43752 13.555 9.63136L12.7558 13.1931C12.2714 12.9751 11.6538 12.7812 10.903 12.6116C10.1522 12.4178 9.28033 12.3209 8.28734 12.3209C7.39123 12.3209 6.66465 12.4784 6.10761 12.7934C5.55057 13.0841 5.27205 13.5445 5.27205 14.1745C5.27205 14.4894 5.32048 14.7681 5.41736 15.0104C5.53846 15.2527 5.73221 15.4829 5.99862 15.7009C6.26503 15.8948 6.61621 16.1007 7.05216 16.3188C7.4881 16.5126 8.02093 16.7186 8.65063 16.9367C9.69205 17.3243 10.5761 17.712 11.3026 18.0997C12.0292 18.4631 12.6226 18.8872 13.0827 19.3718C13.5671 19.8321 13.9183 20.3652 14.1363 20.9709C14.3542 21.5767 14.4632 22.3036 14.4632 23.1516C14.4632 24.9688 13.7851 26.3499 12.4288 27.2949C11.0968 28.2156 9.18345 28.676 6.68887 28.676C5.01774 28.676 3.67358 28.5306 2.65637 28.2399C1.63916 27.9733 0.924697 27.7553 0.51297 27.5857L1.27588 23.9148C1.9298 24.1814 2.70481 24.4358 3.60092 24.6781C4.52125 24.9204 5.56268 25.0415 6.7252 25.0415Z" />
    <path d="M33.7913 27.622C32.9436 27.8401 31.8417 28.0581 30.4854 28.2762C29.1291 28.5185 27.6396 28.6397 26.0169 28.6397C24.4911 28.6397 23.2075 28.4216 22.1661 27.9855C21.1489 27.5493 20.3254 26.9436 19.6957 26.1682C19.0902 25.3929 18.6543 24.4721 18.3879 23.406C18.1215 22.3157 17.9883 21.1284 17.9883 19.8442V9.19522H22.3841V19.1537C22.3841 21.189 22.6747 22.6428 23.2559 23.5151C23.8614 24.3873 24.9028 24.8235 26.3802 24.8235C26.913 24.8235 27.4701 24.7992 28.0513 24.7508C28.6568 24.7023 29.1049 24.6417 29.3955 24.5691V9.19522H33.7913V27.622Z" />
    <path d="M51.629 18.7902C51.629 16.8276 51.181 15.3011 50.2849 14.2108C49.413 13.1205 47.9962 12.5753 46.0344 12.5753C45.6227 12.5753 45.1867 12.5995 44.7266 12.648C44.2906 12.6722 43.8547 12.7449 43.4187 12.866V23.7331C43.8062 23.9997 44.3148 24.2541 44.9445 24.4964C45.5985 24.7144 46.2887 24.8235 47.0153 24.8235C48.6137 24.8235 49.7763 24.2783 50.5028 23.188C51.2536 22.0976 51.629 20.6317 51.629 18.7902ZM56.1338 18.7175C56.1338 20.1713 55.9401 21.504 55.5525 22.7155C55.1893 23.927 54.6564 24.9688 53.9541 25.8411C53.2517 26.7134 52.3677 27.3918 51.3021 27.8764C50.2606 28.361 49.0739 28.6033 47.7418 28.6033C46.8457 28.6033 46.0223 28.4943 45.2715 28.2762C44.5207 28.0581 43.9031 27.8159 43.4187 27.5493V34.9273H39.0229V9.81308C39.919 9.57078 41.021 9.3406 42.3289 9.12253C43.6367 8.90446 45.0172 8.79543 46.4703 8.79543C47.9719 8.79543 49.3161 9.02561 50.5028 9.48598C51.6896 9.94635 52.6947 10.6127 53.5181 11.4849C54.3658 12.333 55.0076 13.3749 55.4436 14.6106C55.9037 15.8221 56.1338 17.1911 56.1338 18.7175Z" />
    <path d="M72.9069 18.7902C72.9069 16.8276 72.4589 15.3011 71.5628 14.2108C70.6909 13.1205 69.2741 12.5753 67.3123 12.5753C66.9006 12.5753 66.4646 12.5995 66.0045 12.648C65.5685 12.6722 65.1326 12.7449 64.6966 12.866V23.7331C65.0841 23.9997 65.5927 24.2541 66.2224 24.4964C66.8764 24.7144 67.5666 24.8235 68.2932 24.8235C69.8916 24.8235 71.0542 24.2783 71.7807 23.188C72.5315 22.0976 72.9069 20.6317 72.9069 18.7902ZM77.4117 18.7175C77.4117 20.1713 77.218 21.504 76.8305 22.7155C76.4672 23.927 75.9343 24.9688 75.232 25.8411C74.5296 26.7134 73.6456 27.3918 72.58 27.8764C71.5386 28.361 70.3518 28.6033 69.0198 28.6033C68.1236 28.6033 67.3002 28.4943 66.5494 28.2762C65.7986 28.0581 65.181 27.8159 64.6966 27.5493V34.9273H60.3008V9.81308C61.1969 9.57078 62.2989 9.3406 63.6068 9.12253C64.9146 8.90446 66.2951 8.79543 67.7482 8.79543C69.2498 8.79543 70.594 9.02561 71.7807 9.48598C72.9675 9.94635 73.9726 10.6127 74.796 11.4849C75.6437 12.333 76.2855 13.3749 76.7215 14.6106C77.1816 15.8221 77.4117 17.1911 77.4117 18.7175Z" />
    <path d="M88.1906 28.567C86.8827 28.5427 85.7929 28.3974 84.921 28.1308C84.0733 27.8643 83.3952 27.4887 82.8866 27.0042C82.378 26.4953 82.0147 25.8775 81.7967 25.1506C81.603 24.3994 81.5061 23.5514 81.5061 22.6064V0.726894L85.9019 0V21.7705C85.9019 22.3036 85.9382 22.7518 86.0109 23.1153C86.1077 23.4787 86.2652 23.7937 86.4831 24.0602C86.7011 24.3025 86.9917 24.4964 87.355 24.6417C87.7425 24.7629 88.2269 24.8598 88.8082 24.9325L88.1906 28.567Z" />
    <path d="M107.513 9.19522C105.551 16.4157 103.275 22.9578 100.683 28.8214C100.199 29.9117 99.7023 30.8446 99.1937 31.6199C98.6851 32.3953 98.1159 33.0374 97.4862 33.5462C96.8565 34.055 96.1542 34.4185 95.3791 34.6366C94.6041 34.8788 93.708 35 92.6908 35C92.0127 35 91.3345 34.9273 90.6564 34.7819C90.0025 34.6366 89.506 34.4791 89.1669 34.3094L89.9661 30.784C90.838 31.1232 91.6978 31.2928 92.5455 31.2928C93.6838 31.2928 94.5678 31.0142 95.1975 30.4569C95.8514 29.9238 96.4206 29.0879 96.905 27.9491C95.5245 25.2838 94.1924 22.3763 92.9088 19.2264C91.6494 16.0765 90.5232 12.7328 89.5302 9.19522H94.2166C94.4588 10.2129 94.7494 11.3153 95.0885 12.5026C95.4518 13.6899 95.8393 14.9013 96.251 16.1371C96.6628 17.3486 97.0987 18.5601 97.5589 19.7715C98.019 20.983 98.4792 22.1097 98.9394 23.1516C99.7144 20.9952 100.441 18.7054 101.119 16.2825C101.797 13.8352 102.415 11.4728 102.972 9.19522H107.513Z" />
  </SvgIcon>
);

export default SupplyIconNoDot;
