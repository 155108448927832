import { useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { findIndex, some, split, trim } from './lodash';

interface LastVisitedLocation {
  pathname: string;
  state: unknown;
  search: string;
}

const pages = ['plailists', 'insights', 'priorities'];

const useApplyLastVisitedState = () => {
  const lastVisited = useRef<Record<string, LastVisitedLocation>>({});
  const pagePosition = useRef(-1);
  const { pathname, state, search } = useLocation();

  const parts = split(trim(pathname, '/'), '/');

  if (pagePosition.current === -1) {
    pagePosition.current = findIndex(parts, (part) => some(pages, (page) => page === part));
  }

  if (
    pagePosition.current > -1 &&
    pagePosition.current + 1 < parts.length &&
    pages.includes(parts[pagePosition.current])
  ) {
    const pageKey = `${parts[pagePosition.current - 1]}/${parts[pagePosition.current]}`;
    lastVisited.current = { ...lastVisited.current, [pageKey]: { pathname, state, search } };
  }

  if (
    pagePosition.current > -1 &&
    pagePosition.current + 1 === parts.length &&
    pages.includes(parts[pagePosition.current])
  ) {
    const pageKey = `${parts[pagePosition.current - 1]}/${parts[pagePosition.current]}`;

    return lastVisited.current[pageKey];
  }

  return null;
};

export const ApplyLastVisitedState = () => {
  const lastVisitedState = useApplyLastVisitedState();

  if (lastVisitedState) {
    return (
      <Navigate
        to={`${lastVisitedState.pathname}${lastVisitedState.search}`}
        state={lastVisitedState.state}
        replace
      />
    );
  }

  return null;
};
