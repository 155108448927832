import { styled, SxProps, Theme } from '@mui/material/styles';
import React, { Children, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { DotNavigation } from '../DotNavigation';

export interface SliderCarouselProps {
  children: ReactNode[];
  index?: number;
  defaultIndex?: number;
  onIndexChange?: (index: number) => void;
  dragThreshold?: number;
  rootStyles?: SxProps<Theme>;
  scrollContainerStyles?: SxProps<Theme>;
  slideStyles?: SxProps<Theme>;
  dotNavigationStyles?: SxProps<Theme>;
}

const CarouselRoot = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
});

const CarouselScrollContainer = styled('div')<{
  $isGrabbing: boolean;
}>(({ $isGrabbing }) => ({
  display: 'flex',
  flex: 1,
  cursor: $isGrabbing ? 'grabbing' : 'grab',
  userSelect: 'none',
}));

const CarouselSlide = styled('div')({
  flex: '0 0 100%',
});

const SliderCarousel: React.FC<SliderCarouselProps> = ({
  children,
  index,
  defaultIndex = 0,
  onIndexChange,
  dragThreshold = 40,
  rootStyles,
  scrollContainerStyles,
  slideStyles,
  dotNavigationStyles,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [localIndex, setLocalIndex] = useState(defaultIndex);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [dragDelta, setDragDelta] = useState(0);
  const currentIndex = index ?? localIndex;

  const slideCount = children.length;

  const scrollToIndex = useCallback((index: number) => {
    const container = containerRef.current;
    if (!container) return;
    const slideWidth = getSlideWidth();
    container.style.transition = 'transform 0.3s ease';
    container.style.transform = `translateX(-${index * slideWidth}px)`;
  }, []);

  const handleIndexChange = useCallback(
    (newIndex: number) => {
      const clamped = Math.max(0, Math.min(slideCount - 1, newIndex));
      setLocalIndex(clamped);
      scrollToIndex(clamped);
      onIndexChange?.(clamped);
    },
    [onIndexChange, scrollToIndex, slideCount],
  );

  const getSlideWidth = () => {
    const container = containerRef.current;
    return container ? container.offsetWidth : 0;
  };

  const onMouseDown = (e: React.MouseEvent) => {
    setIsGrabbing(true);
    setStartX(e.clientX);
    setDragDelta(0);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isGrabbing || !containerRef.current) return;

    const slideWidth = getSlideWidth();
    const delta = e.clientX - startX;
    const maxDelta = currentIndex * slideWidth;
    const minDelta = -(slideCount - currentIndex - 1) * slideWidth;

    // Clamp the drag within bounds
    const clampedDelta = Math.max(minDelta, Math.min(maxDelta, delta));

    containerRef.current.style.transition = 'none';
    containerRef.current.style.transform = `translateX(${-currentIndex * slideWidth + clampedDelta}px)`;

    setDragDelta(clampedDelta);
  };

  const finishDrag = useCallback(() => {
    if (!isGrabbing || !containerRef.current) return;
    setIsGrabbing(false);

    if (Math.abs(dragDelta) > dragThreshold) {
      if (dragDelta < 0) {
        handleIndexChange(currentIndex + 1); // swipe left
      } else {
        handleIndexChange(currentIndex - 1); // swipe right
      }
    } else {
      scrollToIndex(currentIndex); // snap back
    }
  }, [currentIndex, dragDelta, handleIndexChange, isGrabbing, scrollToIndex, dragThreshold]);

  const onMouseUp = () => {
    finishDrag();
  };

  const onMouseLeave = () => {
    if (isGrabbing) finishDrag();
  };

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isGrabbing) finishDrag();
    };

    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isGrabbing, dragDelta, currentIndex, finishDrag]);

  return (
    <CarouselRoot sx={rootStyles}>
      <CarouselScrollContainer
        ref={containerRef}
        $isGrabbing={isGrabbing}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        sx={scrollContainerStyles}
      >
        {Children.map(children, (child, i) => (
          <CarouselSlide key={i} sx={slideStyles}>
            {child}
          </CarouselSlide>
        ))}
      </CarouselScrollContainer>

      <DotNavigation
        count={slideCount}
        index={currentIndex}
        onIndexChanged={handleIndexChange}
        sx={dotNavigationStyles}
      />
    </CarouselRoot>
  );
};

export default SliderCarousel;
