import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import React from 'react';

import { MaybeButtonBase } from '../../buttons';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';
import { GaugeSentiment } from './Gauge';

export interface GaugeComparisonContentProps {
  value?: string;
  secondaryValue?: string;
  secondaryValueSentiment?: GaugeSentiment;
  tertiaryValue?: string;
  tertiaryValueSentiment?: GaugeSentiment;
  label?: string;
  onClick?: () => void;
}

const sentimentColorMap: Record<GaugeSentiment, ColorTokenKey> = {
  positive: 'text.accent',
  negative: 'text.danger',
  warning: 'text.warning',
};

const GaugeComparisonContentRoot = styled('div')(({ theme }) => ({
  height: '100%',
  padding: theme.tokens.spacing['xxlarge'],

  '&, & > button': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.tokens.spacing['xxsmall'],
    userSelect: 'none',

    '& > *': {
      maxWidth: '100%',
    },
  },
}));

const GaugeComparisonContent: React.FC<GaugeComparisonContentProps> = ({
  value,
  secondaryValue,
  secondaryValueSentiment,
  tertiaryValue,
  tertiaryValueSentiment,
  label,
  onClick,
}) => (
  <GaugeComparisonContentRoot>
    <MaybeButtonBase onClick={onClick} disableHoverStyle data-testid="gauge__content-button">
      {value && (
        <Typography variant="heading.H6" lineClamp={1} data-testid="gauge__value">
          {value}
        </Typography>
      )}
      <Stack direction="column">
        {secondaryValue && (
          <Typography
            variant="body.regular-condensed"
            lineClamp={1}
            color={secondaryValueSentiment ? sentimentColorMap[secondaryValueSentiment] : undefined}
            data-testid="gauge__secondary-value"
          >
            {secondaryValue}
          </Typography>
        )}
        {tertiaryValue && (
          <Typography
            variant="body.regular-condensed"
            lineClamp={1}
            color={tertiaryValueSentiment ? sentimentColorMap[tertiaryValueSentiment] : undefined}
            data-testid="gauge__tertiary-value"
          >
            {tertiaryValue}
          </Typography>
        )}
        {label && (
          <Typography variant="body.bold-condensed" lineClamp={1} data-testid="gauge__label">
            {label}
          </Typography>
        )}
      </Stack>
    </MaybeButtonBase>
  </GaugeComparisonContentRoot>
);

export default GaugeComparisonContent;
