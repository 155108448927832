import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const ClinopsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 126 35" {...props}>
    <circle cx="3" cy="26" r="3" />
    <path d="M9 18.7975C9 17.3862 9.219 16.0722 9.657 14.8555C10.095 13.6145 10.7155 12.5438 11.5185 11.6435C12.3458 10.7432 13.3435 10.0375 14.5115 9.5265C15.6795 9.0155 16.9935 8.76 18.4535 8.76C20.2542 8.76 21.9575 9.0885 23.5635 9.7455L22.6145 13.359C22.1035 13.14 21.5195 12.9575 20.8625 12.8115C20.2298 12.6655 19.5485 12.5925 18.8185 12.5925C17.0908 12.5925 15.7768 13.14 14.8765 14.235C13.9762 15.3057 13.526 16.8265 13.526 18.7975C13.526 20.6955 13.9518 22.2042 14.8035 23.3235C15.6552 24.4185 17.0908 24.966 19.1105 24.966C19.8648 24.966 20.607 24.893 21.337 24.747C22.067 24.601 22.6997 24.4185 23.235 24.1995L23.8555 27.8495C23.3688 28.0928 22.6267 28.3118 21.629 28.5065C20.6557 28.7012 19.6458 28.7985 18.5995 28.7985C16.9692 28.7985 15.5457 28.5552 14.329 28.0685C13.1367 27.5575 12.139 26.864 11.336 25.988C10.5573 25.0877 9.97333 24.0292 9.584 22.8125C9.19467 21.5715 9 20.2332 9 18.7975Z" />
    <path d="M33.9178 28.689C32.6038 28.6647 31.5088 28.5187 30.6328 28.251C29.7811 27.9833 29.0998 27.6062 28.5888 27.1195C28.0778 26.6085 27.7128 25.988 27.4938 25.258C27.2991 24.5037 27.2018 23.652 27.2018 22.703V0.729999L31.6183 0V21.8635C31.6183 22.3988 31.6548 22.849 31.7278 23.214C31.8251 23.579 31.9833 23.8953 32.2023 24.163C32.4213 24.4063 32.7133 24.601 33.0783 24.747C33.4676 24.8687 33.9543 24.966 34.5383 25.039L33.9178 28.689Z" />
    <path d="M42.5637 28.324H38.1472V9.2345H42.5637V28.324ZM43.0382 3.65C43.0382 4.47733 42.7706 5.13433 42.2352 5.621C41.6999 6.10767 41.0672 6.351 40.3372 6.351C39.5829 6.351 38.9381 6.10767 38.4027 5.621C37.8674 5.13433 37.5997 4.47733 37.5997 3.65C37.5997 2.79833 37.8674 2.12917 38.4027 1.6425C38.9381 1.15583 39.5829 0.912499 40.3372 0.912499C41.0672 0.912499 41.6999 1.15583 42.2352 1.6425C42.7706 2.12917 43.0382 2.79833 43.0382 3.65Z" />
    <path d="M48.1982 9.855C49.0498 9.61167 50.157 9.3805 51.5197 9.1615C52.8823 8.9425 54.391 8.833 56.0456 8.833C57.603 8.833 58.9048 9.052 59.9511 9.49C60.9975 9.90367 61.8248 10.4998 62.4332 11.2785C63.0658 12.0328 63.5038 12.9575 63.7472 14.0525C64.0148 15.1232 64.1487 16.3033 64.1487 17.593V28.324H59.7321V18.2865C59.7321 17.2645 59.6592 16.4007 59.5132 15.695C59.3915 14.965 59.1725 14.381 58.8561 13.943C58.5641 13.4807 58.1505 13.1522 57.6152 12.9575C57.1041 12.7385 56.4715 12.629 55.7171 12.629C55.1575 12.629 54.5735 12.6655 53.9651 12.7385C53.3568 12.8115 52.9066 12.8723 52.6146 12.921V28.324H48.1982V9.855Z" />
    <path d="M86.8137 18.761C86.8137 20.2697 86.5947 21.6445 86.1567 22.8855C85.7187 24.1265 85.0982 25.185 84.2952 26.061C83.4922 26.937 82.5189 27.6183 81.3752 28.105C80.2559 28.5917 79.0149 28.835 77.6522 28.835C76.2896 28.835 75.0486 28.5917 73.9292 28.105C72.8099 27.6183 71.8487 26.937 71.0457 26.061C70.2427 25.185 69.6101 24.1265 69.1477 22.8855C68.7097 21.6445 68.4907 20.2697 68.4907 18.761C68.4907 17.2523 68.7097 15.8897 69.1477 14.673C69.6101 13.432 70.2427 12.3735 71.0457 11.4975C71.8731 10.6215 72.8464 9.95233 73.9657 9.49C75.0851 9.00333 76.3139 8.76 77.6522 8.76C78.9906 8.76 80.2194 9.00333 81.3387 9.49C82.4824 9.95233 83.4557 10.6215 84.2587 11.4975C85.0617 12.3735 85.6822 13.432 86.1202 14.673C86.5826 15.8897 86.8137 17.2523 86.8137 18.761ZM82.2877 18.761C82.2877 16.863 81.8741 15.3665 81.0467 14.2715C80.2437 13.1522 79.1122 12.5925 77.6522 12.5925C76.1922 12.5925 75.0486 13.1522 74.2212 14.2715C73.4182 15.3665 73.0167 16.863 73.0167 18.761C73.0167 20.6833 73.4182 22.2042 74.2212 23.3235C75.0486 24.4428 76.1922 25.0025 77.6522 25.0025C79.1122 25.0025 80.2437 24.4428 81.0467 23.3235C81.8741 22.2042 82.2877 20.6833 82.2877 18.761Z" />
    <path d="M104.029 18.8705C104.029 16.8995 103.579 15.3665 102.679 14.2715C101.803 13.1765 100.379 12.629 98.4082 12.629C97.9945 12.629 97.5565 12.6533 97.0942 12.702C96.6562 12.7263 96.2182 12.7993 95.7802 12.921V23.8345C96.1695 24.1022 96.6805 24.3577 97.3132 24.601C97.9702 24.82 98.6637 24.9295 99.3937 24.9295C101 24.9295 102.168 24.382 102.898 23.287C103.652 22.192 104.029 20.7198 104.029 18.8705ZM108.555 18.7975C108.555 20.2575 108.361 21.5958 107.971 22.8125C107.606 24.0292 107.071 25.0755 106.365 25.9515C105.66 26.8275 104.771 27.5088 103.701 27.9955C102.654 28.4822 101.462 28.7255 100.124 28.7255C99.2234 28.7255 98.396 28.616 97.6417 28.397C96.8873 28.178 96.2668 27.9347 95.7802 27.667V35.0765H91.3637V9.855C92.264 9.61167 93.3712 9.3805 94.6852 9.1615C95.9992 8.9425 97.3862 8.833 98.8462 8.833C100.355 8.833 101.705 9.06417 102.898 9.5265C104.09 9.98883 105.1 10.658 105.927 11.534C106.779 12.3857 107.424 13.432 107.862 14.673C108.324 15.8897 108.555 17.2645 108.555 18.7975Z" />
    <path d="M117.888 25.1485C119.056 25.1485 119.908 25.0147 120.443 24.747C120.979 24.455 121.246 23.9683 121.246 23.287C121.246 22.6543 120.954 22.1312 120.37 21.7175C119.811 21.3038 118.874 20.8537 117.56 20.367C116.757 20.075 116.015 19.7708 115.333 19.4545C114.676 19.1138 114.105 18.7245 113.618 18.2865C113.131 17.8485 112.742 17.3253 112.45 16.717C112.182 16.0843 112.048 15.3178 112.048 14.4175C112.048 12.6655 112.693 11.2907 113.983 10.293C115.273 9.271 117.025 8.76 119.239 8.76C120.358 8.76 121.429 8.8695 122.451 9.0885C123.473 9.28317 124.239 9.47783 124.75 9.6725L123.947 13.2495C123.461 13.0305 122.84 12.8358 122.086 12.6655C121.332 12.4708 120.456 12.3735 119.458 12.3735C118.558 12.3735 117.828 12.5317 117.268 12.848C116.708 13.14 116.428 13.6023 116.428 14.235C116.428 14.5513 116.477 14.8312 116.574 15.0745C116.696 15.3178 116.891 15.549 117.158 15.768C117.426 15.9627 117.779 16.1695 118.217 16.3885C118.655 16.5832 119.19 16.79 119.823 17.009C120.869 17.3983 121.757 17.7877 122.487 18.177C123.217 18.542 123.814 18.9678 124.276 19.4545C124.763 19.9168 125.115 20.4522 125.334 21.0605C125.553 21.6688 125.663 22.3988 125.663 23.2505C125.663 25.0755 124.982 26.4625 123.619 27.4115C122.281 28.3362 120.358 28.7985 117.852 28.7985C116.173 28.7985 114.822 28.6525 113.8 28.3605C112.778 28.0928 112.061 27.8738 111.647 27.7035L112.413 24.017C113.07 24.2847 113.849 24.5402 114.749 24.7835C115.674 25.0268 116.72 25.1485 117.888 25.1485Z" />
  </SvgIcon>
);

export default ClinopsIcon;
