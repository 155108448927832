import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const GtmIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 63 35" {...props}>
      <path d="M4.66634 18.4833C4.66634 20.3145 5.06058 21.6582 5.84906 22.5144C6.66144 23.3468 7.7008 23.7629 8.96715 23.7629C9.66006 23.7629 10.3052 23.6678 10.9025 23.4776C11.5237 23.2873 12.0255 23.0614 12.4078 22.7998V13.1323C12.0972 13.061 11.7149 13.0015 11.2609 12.954C10.8069 12.8826 10.2335 12.847 9.54059 12.847C7.96363 12.847 6.75701 13.3702 5.92074 14.4166C5.08448 15.4392 4.66634 16.7948 4.66634 18.4833ZM16.7445 26.4028C16.7445 29.3755 15.9799 31.5516 14.4507 32.931C12.9454 34.3103 10.6277 35 7.49771 35C6.35082 35 5.22783 34.9049 4.12874 34.7146C3.05354 34.5244 2.07391 34.2746 1.18985 33.9655L1.97833 30.2911C2.71903 30.6003 3.5553 30.85 4.48714 31.0403C5.44288 31.2305 6.47029 31.3257 7.56939 31.3257C9.31361 31.3257 10.5561 30.9689 11.2968 30.2555C12.0375 29.542 12.4078 28.4837 12.4078 27.0806V26.3671C11.9777 26.5811 11.4043 26.7952 10.6875 27.0092C9.99457 27.2232 9.19414 27.3303 8.28619 27.3303C7.09152 27.3303 5.99242 27.14 4.9889 26.7595C4.00927 26.379 3.16106 25.8201 2.44426 25.0829C1.75135 24.3456 1.2018 23.43 0.795611 22.336C0.413317 21.2183 0.222168 19.934 0.222168 18.4833C0.222168 17.1277 0.425263 15.8792 0.83145 14.7376C1.26153 13.5961 1.87081 12.621 2.6593 11.8124C3.47167 11.0038 4.4513 10.3736 5.59818 9.92175C6.74507 9.46989 8.04726 9.24396 9.50475 9.24396C10.9145 9.24396 12.2525 9.35098 13.5188 9.56502C14.7852 9.77906 15.8604 10.005 16.7445 10.2428V26.4028Z" />
      <path d="M21.6881 4.71346L26.0248 4V9.63637H32.6911V13.2394H26.0248V20.8377C26.0248 22.336 26.2637 23.4062 26.7416 24.0483C27.2195 24.6904 28.0318 25.0115 29.1787 25.0115C29.9672 25.0115 30.6601 24.9283 31.2574 24.7618C31.8787 24.5953 32.3685 24.4407 32.7269 24.298L33.4437 27.7227C32.9419 27.9367 32.2849 28.1507 31.4725 28.3648C30.6601 28.6026 29.7044 28.7215 28.6053 28.7215C27.2673 28.7215 26.1443 28.5432 25.2363 28.1864C24.3523 27.8297 23.6474 27.3184 23.1217 26.6525C22.5961 25.9628 22.2257 25.1423 22.0107 24.191C21.7957 23.216 21.6881 22.1101 21.6881 20.8734V4.71346Z" />
      <path d="M47.2052 18.4833C47.2052 16.5332 46.9543 15.13 46.4525 14.2739C45.9747 13.3939 45.0667 12.954 43.7287 12.954C43.2508 12.954 42.7252 12.9896 42.1517 13.061C41.5783 13.1323 41.1482 13.1918 40.8615 13.2394V28.2934H36.5248V10.2428C37.3611 10.005 38.4482 9.77906 39.7863 9.56502C41.1482 9.35098 42.5818 9.24396 44.0871 9.24396C45.3773 9.24396 46.4286 9.41043 47.241 9.74338C48.0773 10.0763 48.7702 10.5163 49.3197 11.0633C49.5826 10.873 49.9171 10.6709 50.3233 10.4568C50.7294 10.2428 51.1834 10.0526 51.6852 9.88608C52.1869 9.69582 52.7126 9.54123 53.2621 9.42232C53.8356 9.30341 54.409 9.24396 54.9825 9.24396C56.44 9.24396 57.6346 9.458 58.5665 9.88608C59.5222 10.2904 60.2629 10.873 60.7886 11.6341C61.3381 12.3713 61.7085 13.275 61.8996 14.3452C62.1147 15.3916 62.2222 16.5451 62.2222 17.8055V28.2934H57.8855V18.4833C57.8855 16.5332 57.6466 15.13 57.1687 14.2739C56.6908 13.3939 55.771 12.954 54.409 12.954C53.7161 12.954 53.059 13.0729 52.4378 13.3107C51.8166 13.5247 51.3507 13.7388 51.0401 13.9528C51.2312 14.5474 51.3626 15.1776 51.4343 15.8435C51.506 16.5094 51.5418 17.2229 51.5418 17.9839V28.2934H47.2052V18.4833Z" />
    </SvgIcon>
  );
};

export default GtmIconNoDot;
