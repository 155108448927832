import { styled } from '@mui/material';
import { FC } from 'react';

import { useHorizontalDragScroll } from '../../../../hooks';
import LegendItem, { LegendItemProps } from './LegendItem';

export interface LegendProps {
  items: Omit<LegendItemProps, 'legendSize'>[];
  size?: LegendSize;
  width?: string | number;
}

export type LegendSize = 'default' | 'small';

const LegendWrapper = styled('div')<{ $width: string | number }>(({ theme, $width }) => ({
  position: 'relative',
  borderRadius: theme.tokens.borderRadius['small-medium'],
  backgroundColor: theme.tokens.color['neutral.grey'],
  overflow: 'hidden',
  padding: theme.spacing(theme.tokens.spacing['xxsmall'], 0),
  width: $width,
}));

const Shadow = styled('div')<{ $position: 'left' | 'right' }>(({ theme, $position }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  [$position]: 0,
  zIndex: 1,
  width: theme.tokens.spacing['medium'],
  pointerEvents: 'none',
  background:
    $position === 'left'
      ? `linear-gradient(to right, ${theme.tokens.color['neutral.grey']} 0%, transparent 100%)`
      : `linear-gradient(to left, ${theme.tokens.color['neutral.grey']} 0%, transparent 100%)`,
}));

const LegendScroll = styled('div')<{
  $isGrabbing: boolean;
  $isScrollable: boolean;
  $size: LegendSize;
}>(({ theme, $isGrabbing, $isScrollable, $size }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: $isScrollable ? 'flex-start' : 'center',
  gap: theme.tokens.spacing['xsmall'],
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  padding: theme.spacing(0, theme.tokens.spacing[$size === 'small' ? 'small' : 'medium']),
  cursor: $isScrollable ? ($isGrabbing ? 'grabbing' : 'grab') : 'default',
  userSelect: $isGrabbing ? 'none' : 'auto',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const Legend: FC<LegendProps> = ({ items, size = 'default', width = '100%' }) => {
  const { scrollRef, isGrabbing, isScrollable, eventHandlers } = useHorizontalDragScroll();

  return (
    <LegendWrapper $width={width} role="group" aria-label="Legend">
      {isScrollable && (
        <>
          <Shadow $position="left" />
          <Shadow $position="right" />
        </>
      )}
      <LegendScroll
        ref={scrollRef}
        $size={size}
        $isGrabbing={isGrabbing}
        $isScrollable={isScrollable}
        {...eventHandlers}
      >
        {items.map((item, index) => (
          <LegendItem key={index} legendSize={size} {...item} />
        ))}
      </LegendScroll>
    </LegendWrapper>
  );
};

export default Legend;
