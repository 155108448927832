import {
  Experiment,
  ExperimentClient,
  ExperimentConfig,
  ExperimentUser,
} from '@amplitude/experiment-js-client';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Variant, Variants } from '../types';

export interface FeatureFlagsProviderProps {
  apiKey?: string;
  config?: ExperimentConfig;
  experimentUser?: ExperimentUser;
  children?: React.ReactNode;
}

interface NoopExperimentClient {
  variant: () => Variant;
  all: () => Variants;
  start: (user: ExperimentUser) => Promise<void>;
  stop: () => void;
}

type ExperimentClientType = ExperimentClient | NoopExperimentClient;

export interface FeatureFlagsContextState {
  experimentClient: ExperimentClientType;
  loading: boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextState | null>(null);

const noopExperimentClient: NoopExperimentClient = {
  variant: () => ({}),
  all: () => ({}),
  start: async () => {},
  stop: () => {},
};

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({
  apiKey,
  config,
  experimentUser,
  children,
}) => {
  const [client, setClient] = useState<ExperimentClientType>(noopExperimentClient);
  const [loading, setLoading] = useState(true);

  // Initialize the experiment client
  useEffect(() => {
    // If there is no experimentUser, skip initialization
    if (!experimentUser) {
      setClient(noopExperimentClient);
      setLoading(false);
      return;
    }

    // No API key, no need to fetch anything
    if (!apiKey) {
      setClient(noopExperimentClient);
      setLoading(false);
      return;
    }

    let experimentClient: ExperimentClientType = noopExperimentClient;

    try {
      experimentClient = Experiment.initialize(apiKey, config);
      setClient(experimentClient);
    } catch (e) {
      console.error('Failed to initialize experiment client:', e);
      experimentClient = noopExperimentClient;
      setClient(experimentClient);
    }

    return () => {
      experimentClient.stop(); // Ensure the client is stopped when the component unmounts
    };
  }, [apiKey, config, experimentUser]);

  // Start the experiment client for the user and manage loading state
  useEffect(() => {
    const startExperimentForUser = async () => {
      if (client !== noopExperimentClient && experimentUser) {
        setLoading(true);

        try {
          await client.start(experimentUser); // Fetch feature flags for the user and start polling
        } catch (e) {
          console.error('Failed to start experiment client:', e);
        } finally {
          setLoading(false);
        }
      } else {
        // No user provided, stop loading immediately
        setLoading(false);
      }
    };

    startExperimentForUser();
  }, [experimentUser, client]);

  const contextValue = useMemo(
    () => ({ experimentClient: client, loading }) satisfies FeatureFlagsContextState,
    [client, loading],
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>
  );
};

export function useFeatureFlags(): FeatureFlagsContextState {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error('useFeatureFlags must be called within FeatureFlagsProvider');
  }

  return context;
}
