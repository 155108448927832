import { Operation } from '@apollo/client';
import {
  DefinitionNode,
  DirectiveNode,
  EnumValueNode,
  OperationDefinitionNode,
  ValueNode,
} from 'graphql/index';

export interface Endpoints extends Record<string, string> {
  DEFAULT: string;
}

const isOperationDefinition = (param: DefinitionNode): param is OperationDefinitionNode =>
  param.kind === 'OperationDefinition';

const isEnumValueNode = (param: ValueNode | undefined): param is EnumValueNode =>
  param?.kind === 'EnumValue';

export const getOperationDirectiveValue = (operation: Operation) => {
  const operationDefinition = operation.query.definitions.find(isOperationDefinition);

  const apiDirective = operationDefinition?.directives?.find(
    (directive: DirectiveNode) => directive.name.value === 'Api',
  );

  const apiArgument = apiDirective?.arguments?.find(
    (argument: any) => argument.name.value === 'name',
  );

  if (isEnumValueNode(apiArgument?.value)) {
    return apiArgument?.value.value;
  }

  return undefined;
};

export const getWebSocketUrl = (url: string, newPort?: number): string | null => {
  try {
    const parsedUrl = new URL(url);

    // Replace the protocol based on the original one
    parsedUrl.protocol = parsedUrl.protocol === 'https:' ? 'wss:' : 'ws:';

    // If a new port is provided, replace the port
    if (newPort !== undefined) {
      parsedUrl.port = newPort.toString();
    }

    return parsedUrl.href;
  } catch (e) {
    console.error('Invalid URL:', e);
    return null;
  }
};

const parseJwt = <T = unknown>(token: string | null): T | null => {
  if (!token) return null;

  try {
    const payload = atob(token.split('.')[1]);
    return JSON.parse(payload);
  } catch (e) {
    console.error('Invalid JWT:', e);
    return null;
  }
};

interface AccessTokenPayload {
  role: string[];
}

export const getUserRoles = (token: string | null) => parseJwt<AccessTokenPayload>(token)?.role;
