import { AnalyticsProvider, NoopAnalyticsProvider } from '@aily/analytics-service';
import { useAuth } from '@aily/auth-service';
import React from 'react';

import { analyticsConfig } from '@/services/analyticsService';

export const ConfiguredAnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <NoopAnalyticsProvider>{children}</NoopAnalyticsProvider>;
  }

  return <AnalyticsProvider {...analyticsConfig}>{children}</AnalyticsProvider>;
};
