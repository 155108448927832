import { Stack, styled } from '@mui/material';
import React from 'react';

import { Typography } from '../../../../../../Typography';
import {
  TitleBarHorizontalRow,
  TitleBarHorizontalRowProps,
} from '../TitleBarHorizontalRow/TitleBarHorizontalRow';

export interface TitleBarHorizontalStackProps {
  title?: string;
  rows: TitleBarHorizontalRowProps[];
}

const TitleBarHorizontalStackRoot = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['4'],
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const RowsStack = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['4'],
}));

export const TitleBarHorizontalStack: React.FC<TitleBarHorizontalStackProps> = ({
  title,
  rows,
}) => {
  return (
    <TitleBarHorizontalStackRoot>
      {title && (
        <Typography lineClamp={1} variant="heading.H9">
          {title}
        </Typography>
      )}
      <RowsStack>
        {rows.map((row, index) => (
          <TitleBarHorizontalRow
            key={index}
            title={row.title}
            value={row.value}
            valueSentiment={row.valueSentiment}
            percentage={row.percentage}
            showStarIcon={row.showStarIcon}
            barSideValue={row.barSideValue}
          />
        ))}
      </RowsStack>
    </TitleBarHorizontalStackRoot>
  );
};
