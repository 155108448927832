import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PlayIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M19.0885 10.2302L6.38266 3.54289C5.05084 2.84193 3.45117 3.80771 3.45117 5.31273V18.6873C3.45117 20.1923 5.05085 21.1581 6.38267 20.4571L19.0885 13.7698C20.5132 13.02 20.5132 10.98 19.0885 10.2302Z" />
  </SvgIcon>
);

export default PlayIcon;
