import { styled } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

import { Typography } from '../../../../components';
import { withAnimatedTokens } from '../../hocs';

const AnimatedTypography = withAnimatedTokens(Typography);

const StyledTypography = styled(AnimatedTypography)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(theme.tokens.spacing['xsmall'], 0),
  // Typography style inconsistency
  fontWeight: 400,
}));

const AnimatedParagraph: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledTypography variant="body.regular-condensed">{children}</StyledTypography>
);

export default AnimatedParagraph;
