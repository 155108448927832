import { easeCubicInOut } from 'd3';
import React, { useId, useMemo, useRef } from 'react';

import { useMicroChartContext } from './MicroChartContext';
import { MicroChartSeriesProps } from './MicroChartSeries';
import useMicroChartPathTween from './useMicroChartPathTween';

export const MicroChartAreaSeries: React.FC<MicroChartSeriesProps> = ({
  data,
  color,
  lineWidth,
  lineOpacity,
  fillOpacity,
  animationDuration,
  animationEasing = animationDuration ? easeCubicInOut : undefined,
  gradientHeight = '100%',
  tweenOrigin,
}) => {
  const id = useId();
  const gradientId = useMemo(() => `${id}.gradient`, [id]);
  const { areaGenerator, lineGenerator } = useMicroChartContext();
  const lineRef = useRef<SVGPathElement>(null);
  const areaRef = useRef<SVGPathElement>(null);
  const animated = !!animationDuration && !!animationEasing;

  const tweenProps = {
    origin: tweenOrigin,
    duration: animationDuration,
    easing: animationEasing,
    disable: !animated,
  };

  const { initialData: lineInitialData } = useMicroChartPathTween({
    ref: lineRef,
    data,
    generator: lineGenerator,
    ...tweenProps,
  });

  const { initialData: areaInitialData } = useMicroChartPathTween({
    ref: areaRef,
    data,
    generator: areaGenerator,
    ...tweenProps,
  });

  return (
    <g>
      <defs>
        <linearGradient id={gradientId} x1="0%" x2="0%" y1="0%" y2={gradientHeight}>
          <stop offset="0%" stopOpacity="1" stopColor={color} />
          <stop offset="100%" stopOpacity="0" stopColor={color} />
        </linearGradient>
      </defs>
      <path
        ref={lineRef}
        stroke={color}
        strokeWidth={lineWidth}
        strokeOpacity={lineOpacity}
        fill="none"
        d={lineGenerator(animated ? lineInitialData : data) ?? ''}
        vectorEffect="non-scaling-stroke"
      />
      <path
        ref={areaRef}
        stroke="none"
        fill={`url(#${gradientId})`}
        fillOpacity={fillOpacity}
        d={areaGenerator(animated ? areaInitialData : data) ?? ''}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
};
