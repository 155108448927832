import { Area, easeCubicInOut, interpolateArray, Line, median, min, select } from 'd3';
import { RefObject, useLayoutEffect, useMemo } from 'react';

import { MicroChartSeriesDataPoint } from './MicroChartSeries';

/**
 * Defines how the animation should start.
 * - 'median': Tween from a flat line at the median value of the data.
 * - 'min': Tween from a flat line at the minimum value of the data.
 * - 'start': Reveal points sequentially from left to right.
 */
export type MicroChartPathTweenOrigin = 'median' | 'min' | 'start';

interface UseMicroChartPathTweenParams {
  ref: RefObject<SVGPathElement>;
  data: MicroChartSeriesDataPoint[];
  generator: Line<MicroChartSeriesDataPoint> | Area<MicroChartSeriesDataPoint>;
  origin?: MicroChartPathTweenOrigin;
  duration?: number;
  easing?: (t: number) => number;
  disable?: boolean;
}

function computeFromData(
  data: MicroChartSeriesDataPoint[],
  origin: MicroChartPathTweenOrigin,
  defaultValue = 0,
): MicroChartSeriesDataPoint[] {
  const numericData = data.filter((v): v is number => typeof v === 'number');

  switch (origin) {
    case 'median': {
      const value = median(numericData) ?? defaultValue;
      return data.map((v) => (typeof v === 'number' ? value : null));
    }
    case 'min': {
      const value = min(numericData) ?? defaultValue;
      return data.map((v) => (typeof v === 'number' ? value : null));
    }
    case 'start':
    default:
      return data;
  }
}

/**
 * Animates a path element from a given origin strategy to the final `data`.
 * Returns `fromData` for use as the initial render value.
 */
function useMicroChartPathTween({
  ref,
  data,
  generator,
  origin = 'start',
  duration = 400,
  easing = easeCubicInOut,
  disable = false,
}: UseMicroChartPathTweenParams) {
  const initialData = useMemo(() => {
    if (disable || origin === 'start') return data;
    return computeFromData(data, origin);
  }, [disable, origin, data]);

  useLayoutEffect(() => {
    if (disable || !ref.current || data.length < 2) return;

    const path = select(ref.current);
    const transition = path.transition().duration(duration).ease(easing);

    switch (origin) {
      case 'start': {
        const total = data.length;
        transition.tween('path', () => (t: number) => {
          const count = Math.floor(t * total);
          const partial = data.slice(0, count).map((v) => v);
          path.datum(partial).attr('d', generator);
        });
        break;
      }

      case 'median':
      case 'min': {
        const interpolator = interpolateArray(initialData, data);
        transition.tween('path', () => (t: number) => {
          path.datum(interpolator(t)).attr('d', generator);
        });
        break;
      }

      default:
        break;
    }
  }, [ref, data, initialData, generator, duration, easing, disable, origin]);

  return { initialData };
}

export default useMicroChartPathTween;
