import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const QaIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 35 35" {...props}>
    <path d="M4.21347 20.9842C4.21347 23.6303 5.5887 24.9389 8.3099 24.9389C9.65587 24.9389 10.8263 24.5899 11.7919 23.9211V13.2784C10.9726 12.9295 9.83143 12.7841 8.39768 12.7841C7.02245 12.7841 5.96908 13.1039 5.2961 13.7436C4.59385 14.3834 4.24273 15.4593 4.24273 16.9423L4.21347 20.9842ZM0 16.9714C0 14.3543 0.760769 12.377 2.25304 11.0684C3.77457 9.7599 5.76426 9.09109 8.28064 9.09109C10.8555 9.09109 13.3134 9.49819 15.5957 10.3415C15.859 10.4578 16.0053 10.6613 16.0053 10.9521V34.4475C16.0053 34.7965 15.8298 35 15.4494 35H12.3478C11.9967 35 11.7919 34.8255 11.7919 34.4475V27.8176C10.5629 28.341 9.27549 28.6027 7.95878 28.6027C5.61796 28.6027 3.71605 27.9049 2.22378 26.5091C0.731507 25.1133 0 23.2523 0 20.926V16.9714Z" />
    <path d="M22.7644 22.5544C22.7644 23.3686 23.1156 23.9793 23.8178 24.3573C24.52 24.7644 25.4564 24.9389 26.5975 24.9389C27.7094 24.9389 28.8798 24.7935 30.0795 24.5027V20.4317C28.7628 20.2281 27.5924 20.1409 26.5683 20.1409C24.0226 20.1991 22.7644 20.9842 22.7644 22.5544ZM19.6336 11.0103C21.5063 9.73082 23.8471 9.09109 26.7438 9.09109C29.0846 9.09109 30.928 9.70174 32.274 10.923C33.62 12.1443 34.293 13.9763 34.293 16.4189V26.9162C34.293 27.207 34.1467 27.4105 33.8833 27.5268C31.5425 28.2538 29.1139 28.6027 26.5975 28.6027C24.2567 28.6027 22.3255 28.1375 20.804 27.1779C19.2825 26.2183 18.5217 24.7062 18.5217 22.6126C18.5217 20.7225 19.2532 19.2976 20.687 18.309C22.1207 17.3494 24.0519 16.855 26.4512 16.855C27.4168 16.855 28.6165 16.9423 30.0795 17.1458V16.4479C30.0795 15.1394 29.8161 14.2089 29.2602 13.6564C28.7043 13.1039 27.7679 12.8131 26.4512 12.8131C24.6663 12.8131 23.1156 13.1912 21.8281 13.9763C21.5355 14.1798 21.3014 14.1507 21.0966 13.8309L19.6629 11.65C19.4873 11.3592 19.4873 11.1557 19.6336 11.0103Z" />
  </SvgIcon>
);

export default QaIconNoDot;
