import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const CmcIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 73 35" {...props}>
      <path d="M5.94241 26.4244C5.94241 27.1742 5.68569 27.8087 5.20078 28.299C4.71586 28.7893 4.08831 29.02 3.31815 29.02C2.54799 29.02 1.92044 28.7893 1.43553 28.299C0.950609 27.8087 0.722412 27.1742 0.722412 26.4244C0.722412 25.6457 0.950609 25.0113 1.43553 24.521C1.92044 24.0307 2.51946 23.8 3.31815 23.8C4.05979 23.8 4.68733 24.0307 5.20078 24.521C5.68569 25.0113 5.94241 25.6457 5.94241 26.4244Z" />
      <path d="M57.2461 19.0682C57.2461 17.6596 57.4647 16.3481 57.9018 15.1338C58.339 13.8952 58.9583 12.8266 59.7597 11.9279C60.5855 11.0293 61.5812 10.325 62.747 9.81501C63.9128 9.305 65.2242 9.04999 66.6814 9.04999C68.4786 9.04999 70.1787 9.37786 71.7816 10.0336L70.8344 13.6402C70.3244 13.4216 69.7415 13.2394 69.0858 13.0937C68.4543 12.948 67.7743 12.8751 67.0457 12.8751C65.3214 12.8751 64.0099 13.4216 63.1113 14.5145C62.2127 15.5831 61.7634 17.101 61.7634 19.0682C61.7634 20.9626 62.1884 22.4683 63.0384 23.5855C63.8885 24.6784 65.3214 25.2249 67.3372 25.2249C68.09 25.2249 68.8308 25.152 69.5594 25.0063C70.288 24.8606 70.9194 24.6784 71.4537 24.4598L72.073 28.1028C71.5873 28.3457 70.8466 28.5643 69.8508 28.7586C68.8794 28.9528 67.8715 29.05 66.8271 29.05C65.1999 29.05 63.7792 28.8071 62.5648 28.3214C61.3748 27.8114 60.3791 27.1192 59.5776 26.2449C58.8004 25.3463 58.2175 24.2898 57.829 23.0755C57.4404 21.8369 57.2461 20.5011 57.2461 19.0682Z" />
      <path d="M37.6383 18.5582C37.6383 16.5667 37.3833 15.1338 36.8733 14.2595C36.3876 13.3609 35.4647 12.9116 34.1047 12.9116C33.6189 12.9116 33.0846 12.948 32.5017 13.0209C31.9189 13.0937 31.4817 13.1544 31.1903 13.203V28.5764H26.7822V10.1429C27.6323 9.90002 28.7373 9.6693 30.0974 9.45072C31.4817 9.23214 32.9389 9.12285 34.4689 9.12285C35.7804 9.12285 36.849 9.29286 37.6748 9.63287C38.5248 9.97288 39.2291 10.4222 39.7877 10.9808C40.0549 10.7865 40.3949 10.58 40.8077 10.3615C41.2206 10.1429 41.6821 9.9486 42.1921 9.77859C42.7021 9.5843 43.2364 9.42644 43.795 9.305C44.3779 9.18357 44.9608 9.12285 45.5436 9.12285C47.0251 9.12285 48.2394 9.34143 49.1866 9.77859C50.1581 10.1915 50.911 10.7865 51.4453 11.5637C52.0039 12.3165 52.3803 13.2394 52.5746 14.3323C52.7932 15.4009 52.9025 16.5788 52.9025 17.866V28.5764H48.4944V18.5582C48.4944 16.5667 48.2516 15.1338 47.7659 14.2595C47.2801 13.3609 46.3451 12.9116 44.9608 12.9116C44.2564 12.9116 43.5886 13.033 42.9571 13.2759C42.3257 13.4944 41.8521 13.713 41.5363 13.9316C41.7306 14.5388 41.8642 15.1824 41.9371 15.8624C42.0099 16.5424 42.0464 17.271 42.0464 18.0482V28.5764H37.6383V18.5582Z" />
      <path d="M8.54248 19.0682C8.54248 17.6596 8.76106 16.3481 9.19822 15.1338C9.63538 13.8952 10.2547 12.8266 11.0561 11.9279C11.8819 11.0293 12.8776 10.325 14.0434 9.81501C15.2091 9.305 16.5206 9.04999 17.9778 9.04999C19.775 9.04999 21.4751 9.37786 23.078 10.0336L22.1308 13.6402C21.6208 13.4216 21.0379 13.2394 20.3822 13.0937C19.7507 12.948 19.0707 12.8751 18.3421 12.8751C16.6178 12.8751 15.3063 13.4216 14.4077 14.5145C13.5091 15.5831 13.0598 17.101 13.0598 19.0682C13.0598 20.9626 13.4848 22.4683 14.3348 23.5855C15.1849 24.6784 16.6178 25.2249 18.6336 25.2249C19.3864 25.2249 20.1272 25.152 20.8558 25.0063C21.5844 24.8606 22.2158 24.6784 22.7501 24.4598L23.3694 28.1028C22.8837 28.3457 22.143 28.5643 21.1472 28.7586C20.1758 28.9528 19.1679 29.05 18.1235 29.05C16.4963 29.05 15.0756 28.8071 13.8612 28.3214C12.6712 27.8114 11.6755 27.1192 10.874 26.2449C10.0968 25.3463 9.51394 24.2898 9.12536 23.0755C8.73677 21.8369 8.54248 20.5011 8.54248 19.0682Z" />
    </SvgIcon>
  );
};

export default CmcIcon;
