import { getToken } from '@aily/auth-service';
import { SiteConfigurationQuery, useSiteConfigurationQuery } from '@aily/graphql-sdk/core';
import { createSentryLink } from '@aily/monitoring-service';
import {
  ApolloClient,
  DocumentNode,
  from,
  InMemoryCache,
  LoadableQueryHookOptions,
  OperationVariables,
  PossibleTypesMap,
  QueryHookOptions,
  TypedDocumentNode,
  useBackgroundQuery as useApolloBackgroundQuery,
  useLazyQuery as useApolloLazyQuery,
  useLoadableQuery as useApolloLoadableQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
  useSubscription as useApolloSubscription,
  useSuspenseQuery as useApolloSuspenseQuery,
} from '@apollo/client';
import { ApolloCache, ApolloError, DefaultContext } from '@apollo/client/core';
import {
  BackgroundQueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationHookOptions,
  MutationTuple,
  NoInfer,
  QueryResult,
  SubscriptionHookOptions,
  SuspenseQueryHookOptions,
} from '@apollo/client/react/types/types';
import { useCallback } from 'react';

import { CachePersistor } from './CachePersistor';
import { removeUnusedCache } from './CachePersistor/utils';
import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';
import { getHttpLink } from './links/httpLink';
import { resetTokenLink } from './links/resetTokenLink';
import { getUserRoles } from './utils';

export let client: ApolloClient<any>;
let persistor: ReturnType<typeof CachePersistor>;

let siteConfigurationVersionStorageKey: string;
let cachePersistorStorageKey: string;
let rolesStorageKey: string;

const getSiteConfigurationVersionStorageKey = (env: string, tenant: string) => {
  return `aily.site.configuration.${env}.${tenant}`;
};

const getCachePersistorStorageKey = (env: string, tenant: string) => {
  return `aily.site.cache.${env}.${tenant}`;
};

const getRolesStorageKey = (env: string, tenant: string) => {
  return `aily.site.roles.${env}.${tenant}`;
};

let cache: InMemoryCache;

/**
 * Initializes an Apollo Client instance with custom configuration for the SaaS application
 * @param uri - The GraphQL API endpoint URI
 * @param sentryURI - The Sentry DSN URI for error tracking
 * @param possibleTypes - Optional map of GraphQL interface/union types
 * @param env - Environment name (defaults to 'dev')
 * @param tenant - Tenant name (defaults to 'aily')
 * @description
 * Sets up Apollo Client with:
 * - Custom link chain (reset token, auth, error handling, Sentry, HTTP)
 * - In-memory cache with possible types
 * - Cache persistence configuration
 * - Default options for queries, mutations, and subscriptions
 *
 * Also handles cache cleanup of old versions and initial cache restoration
 */
export const initSaaSApolloClient = (
  uri: string,
  sentryURI: string,
  possibleTypes?: PossibleTypesMap,
  env: string = 'dev',
  tenant: string = 'aily',
) => {
  const httpLink = getHttpLink(uri);
  const sentryLink = createSentryLink(sentryURI);
  const link = from([resetTokenLink, authLink, errorLink, sentryLink, httpLink]);

  siteConfigurationVersionStorageKey = getSiteConfigurationVersionStorageKey(env, tenant);
  cachePersistorStorageKey = getCachePersistorStorageKey(env, tenant);
  rolesStorageKey = getRolesStorageKey(env, tenant);

  removeUnusedCache(siteConfigurationVersionStorageKey, cachePersistorStorageKey);

  cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
      /**
       * Disable normalization for NavigationTabsComponent to preserve the exact order
       * and structure of navigation tabs as received from the query. This ensures that
       * the tabs list is maintained as a single unit without being split into individual
       * normalized records in Apollo's cache.
       *
       * @see https://www.apollographql.com/docs/react/caching/cache-configuration#disabling-normalization
       */
      NavigationTabsComponent: {
        keyFields: false,
      },
      FilterComponent: {
        keyFields: false,
      },
    },
  });

  persistor = CachePersistor(cache, cachePersistorStorageKey);

  persistor.restore();

  client = new ApolloClient({
    connectToDevTools: true,
    link,
    cache,
  });

  /**
   * The `defaultOptions` setting is being ignored in the `ApolloClient` constructor,
   * that's why it's passed after the instance is created
   * @see https://github.com/apollographql/react-apollo/issues/3750
   */
  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      // This is needed for the `loading` state to be updated when the query's `refetch` function is called
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
};

interface UseCacheInvalidationOptions {
  /**
   * Callback triggered whenever the site configuration version changes.
   */
  onSiteConfigurationVersionChange?: (oldVersion: string | null, newVersion: string) => void;
  /**
   * Callback triggered whenever the user roles change.
   */
  onUserRolesChange?: (
    cacheVersion: string | null,
    oldRoles: string | null,
    newRoles: string,
  ) => void;
  /**
   * If true, the query will not be executed.
   * @default false
   */
  skip?: boolean;
}

/**
 * Hook to handle cache invalidation based on site configuration version changes
 * @returns void
 * @description
 * Monitors site configuration version changes and:
 * - Compares current version with stored version
 * - Updates stored version if different
 * - Removes configuration from cache if version mismatch
 *
 * This ensures the cache stays in sync with the latest site configuration
 * by polling every 5 minutes and invalidating cache when needed.
 */
export const useCacheInvalidation = ({
  onSiteConfigurationVersionChange,
  onUserRolesChange,
  skip = false,
}: UseCacheInvalidationOptions = {}) => {
  const token = getToken();
  const newRoles = JSON.stringify(getUserRoles(token));
  const oldRoles = localStorage.getItem(rolesStorageKey);
  const cacheVersion = localStorage.getItem(siteConfigurationVersionStorageKey);

  if (token && oldRoles !== newRoles && cacheVersion !== null) {
    localStorage.setItem(rolesStorageKey, newRoles);
    persistor.removeConfiguration();

    onUserRolesChange?.(cacheVersion, oldRoles, newRoles);
  }

  const onCompleted = useCallback(
    (data: SiteConfigurationQuery) => {
      const oldVersion = localStorage.getItem(siteConfigurationVersionStorageKey);
      const newVersion = data?.siteConfiguration?.version ?? '';

      if (oldVersion !== newVersion) {
        localStorage.setItem(siteConfigurationVersionStorageKey, newVersion);
        persistor.removeConfiguration();

        onSiteConfigurationVersionChange?.(oldVersion, newVersion);
      }
    },
    [onSiteConfigurationVersionChange],
  );

  useSiteConfigurationQuery({
    pollInterval: 60000 * 5, // Poll every 5 minutes
    onCompleted,
    skip,
  });
};

export const useLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): LazyQueryResultTuple<TData, TVariables> => useApolloLazyQuery(query, { client, ...options });

export const useQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> => useApolloQuery(query, { client, ...options });

export const useMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> =>
  useApolloMutation(mutation, { client, ...options });

export const useSuspenseQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SuspenseQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) => useApolloSuspenseQuery(query, { client, ...options });

export const useSubscription = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): {
  restart(): void;
  loading: boolean;
  data?: TData | undefined;
  error?: ApolloError;
  variables?: TVariables | undefined;
} => useApolloSubscription(subscription, { client, ...options });

type BackgroundQueryHookOptionsNoInfer<
  TData,
  TVariables extends OperationVariables,
> = BackgroundQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>;
export const useBackgroundQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: BackgroundQueryHookOptionsNoInfer<TData, TVariables>,
) => useApolloBackgroundQuery(query, { client, ...options });

export const useLoadableQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LoadableQueryHookOptions,
) => useApolloLoadableQuery(query, { client, ...options });

export * from './utils';
export type * from '@apollo/client';
export { ApolloClient, ApolloProvider } from '@apollo/client';
