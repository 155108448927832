import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const EbiIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 56 35" {...props}>
      <path d="M5.97757 26.1244C5.97757 26.8743 5.72085 27.5087 5.23593 27.999C4.75101 28.4893 4.12347 28.72 3.35331 28.72C2.58314 28.72 1.9556 28.4893 1.47068 27.999C0.985765 27.5087 0.757568 26.8743 0.757568 26.1244C0.757568 25.3457 0.985765 24.7113 1.47068 24.221C1.9556 23.7307 2.55462 23.5 3.35331 23.5C4.09495 23.5 4.72249 23.7307 5.23593 24.221C5.72085 24.7113 5.97757 25.3457 5.97757 26.1244Z" />
      <path d="M8.75757 19.0418C8.75757 17.3476 9.00088 15.8621 9.4875 14.5852C9.99846 13.3084 10.6676 12.2526 11.4948 11.4177C12.3221 10.5583 13.271 9.91993 14.3416 9.50251C15.4121 9.06053 16.507 8.83954 17.6263 8.83954C20.254 8.83954 22.2978 9.66211 23.7577 11.3072C25.2419 12.9524 25.984 15.4078 25.984 18.6735C25.984 18.9191 25.9718 19.2015 25.9475 19.5207C25.9475 19.8153 25.9353 20.0854 25.911 20.3309H13.3197C13.4413 21.8779 13.9766 23.081 14.9255 23.9404C15.8988 24.7753 17.2978 25.1927 19.1226 25.1927C20.1932 25.1927 21.1664 25.0945 22.0424 24.898C22.9426 24.7016 23.6482 24.4929 24.1592 24.2719L24.7431 27.9182C24.4998 28.041 24.1592 28.176 23.7212 28.3234C23.3076 28.4461 22.821 28.5566 22.2613 28.6549C21.7261 28.7776 21.1421 28.8758 20.5095 28.9495C19.8769 29.0232 19.2321 29.06 18.5752 29.06C16.8963 29.06 15.4365 28.8145 14.1956 28.3234C12.9547 27.8077 11.9328 27.1079 11.1299 26.224C10.3269 25.3155 9.73081 24.2596 9.34152 23.0565C8.95222 21.8288 8.75757 20.4906 8.75757 19.0418ZM21.5679 17.053C21.5679 16.4391 21.4827 15.8621 21.3124 15.3219C21.1421 14.7571 20.8866 14.2783 20.546 13.8854C20.2297 13.468 19.8282 13.1488 19.3416 12.9278C18.8793 12.6823 18.3197 12.5595 17.6628 12.5595C16.9815 12.5595 16.3854 12.6946 15.8744 12.9647C15.3635 13.2102 14.9255 13.5417 14.5605 13.9591C14.2199 14.3765 13.9523 14.8553 13.7576 15.3955C13.563 15.9357 13.4291 16.4882 13.3562 17.053H21.5679Z" />
      <path d="M42.2245 18.8945C42.2245 17.0038 41.8474 15.506 41.0931 14.4011C40.3632 13.2961 39.1953 12.7437 37.5894 12.7437C36.8595 12.7437 36.1661 12.8542 35.5091 13.0752C34.8765 13.2961 34.3656 13.5417 33.9763 13.8118V24.898C34.2926 24.9717 34.694 25.0454 35.1807 25.119C35.6916 25.1681 36.2999 25.1927 37.0055 25.1927C38.6357 25.1927 39.9131 24.6402 40.8377 23.5353C41.7622 22.4058 42.2245 20.8589 42.2245 18.8945ZM46.7501 18.9682C46.7501 20.5151 46.519 21.9147 46.0567 23.167C45.6187 24.3947 44.9739 25.4505 44.1224 26.3345C43.2951 27.2184 42.2854 27.8937 41.0931 28.3602C39.9009 28.8267 38.5505 29.06 37.042 29.06C35.5821 29.06 34.1831 28.9495 32.8449 28.7285C31.531 28.5075 30.4361 28.262 29.5602 27.9919V0.736628L33.9763 0V9.98132C34.4872 9.71122 35.1077 9.46568 35.8376 9.24469C36.5919 9.0237 37.4191 8.9132 38.3194 8.9132C39.6576 8.9132 40.8498 9.15875 41.8961 9.64984C42.9666 10.1164 43.8547 10.7916 44.5603 11.6756C45.2659 12.5595 45.8012 13.6276 46.1662 14.8799C46.5555 16.1076 46.7501 17.4704 46.7501 18.9682Z" />
      <path d="M54.648 28.5812H50.2319V9.31835H54.648V28.5812ZM55.1225 3.68314C55.1225 4.51799 54.8548 5.18095 54.3195 5.67204C53.7842 6.16313 53.1516 6.40867 52.4217 6.40867C51.6674 6.40867 51.0227 6.16313 50.4874 5.67204C49.9521 5.18095 49.6845 4.51799 49.6845 3.68314C49.6845 2.82374 49.9521 2.1485 50.4874 1.65741C51.0227 1.16633 51.6674 0.920785 52.4217 0.920785C53.1516 0.920785 53.7842 1.16633 54.3195 1.65741C54.8548 2.1485 55.1225 2.82374 55.1225 3.68314Z" />
    </SvgIcon>
  );
};

export default EbiIcon;
