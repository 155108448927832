import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const SpendIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 98 35" {...props}>
    <path d="M6.47909 25.0937C7.64998 25.0937 8.50375 24.9601 9.0404 24.693C9.57706 24.4017 9.84539 23.9161 9.84539 23.2362C9.84539 22.6049 9.55266 22.0829 8.96722 21.6701C8.40617 21.2574 7.46703 20.8082 6.14978 20.3226C5.34479 20.0312 4.60079 19.7277 3.91778 19.4121C3.25915 19.0721 2.68591 18.6837 2.19804 18.2466C1.71017 17.8096 1.31987 17.2875 1.02715 16.6805C0.758825 16.0493 0.62466 15.2844 0.62466 14.3861C0.62466 12.6379 1.27109 11.266 2.56394 10.2706C3.85679 9.25078 5.61312 8.74089 7.83293 8.74089C8.95503 8.74089 10.0283 8.85016 11.0529 9.06868C12.0774 9.26292 12.8458 9.45716 13.358 9.6514L12.5531 13.2206C12.0652 13.0021 11.4432 12.8078 10.687 12.6379C9.93076 12.4436 9.0526 12.3465 8.05247 12.3465C7.14991 12.3465 6.41811 12.5043 5.85706 12.82C5.29601 13.1113 5.01548 13.5727 5.01548 14.204C5.01548 14.5196 5.06427 14.7988 5.16184 15.0416C5.28381 15.2844 5.47896 15.5151 5.74729 15.7336C6.01561 15.9279 6.36932 16.1342 6.8084 16.3528C7.24748 16.547 7.78414 16.7534 8.41837 16.9719C9.46729 17.3604 10.3576 17.7489 11.0895 18.1374C11.8213 18.5016 12.4189 18.9265 12.8824 19.4121C13.3702 19.8734 13.7239 20.4076 13.9435 21.0146C14.163 21.6216 14.2728 22.35 14.2728 23.1998C14.2728 25.0208 13.5898 26.4048 12.2237 27.3517C10.8821 28.2744 8.95503 28.7357 6.4425 28.7357C4.75935 28.7357 3.40552 28.59 2.38099 28.2986C1.35647 28.0316 0.636857 27.813 0.222168 27.6431L0.990564 23.9646C1.64919 24.2317 2.42978 24.4866 3.33233 24.7294C4.25929 24.9723 5.3082 25.0937 6.47909 25.0937Z" />
    <path d="M30.7395 18.8293C30.7395 16.8626 30.2882 15.333 29.3857 14.2404C28.5075 13.1478 27.0805 12.6015 25.1046 12.6015C24.6899 12.6015 24.2509 12.6257 23.7874 12.6743C23.3483 12.6986 22.9092 12.7714 22.4701 12.8928V23.7825C22.8604 24.0496 23.3727 24.3045 24.0069 24.5473C24.6656 24.7659 25.3608 24.8751 26.0926 24.8751C27.7025 24.8751 28.8734 24.3288 29.6052 23.2362C30.3614 22.1436 30.7395 20.6746 30.7395 18.8293ZM35.2767 18.7565C35.2767 20.2133 35.0816 21.5487 34.6913 22.7627C34.3254 23.9768 33.7887 25.0208 33.0813 25.8949C32.3739 26.769 31.4835 27.4488 30.4102 27.9344C29.3613 28.42 28.166 28.6629 26.8244 28.6629C25.9218 28.6629 25.0924 28.5536 24.3362 28.3351C23.58 28.1165 22.958 27.8737 22.4701 27.6067V35H18.0427V9.83351C18.9453 9.5907 20.0552 9.36004 21.3724 9.14152C22.6897 8.923 24.0801 8.81373 25.5437 8.81373C27.0561 8.81373 28.41 9.0444 29.6052 9.50572C30.8005 9.96705 31.8128 10.6348 32.6422 11.5088C33.496 12.3587 34.1424 13.4027 34.5815 14.641C35.045 15.855 35.2767 17.2268 35.2767 18.7565Z" />
    <path d="M38.4126 18.8293C38.4126 17.154 38.6565 15.6851 39.1444 14.4225C39.6567 13.1599 40.3275 12.1159 41.1569 11.2903C41.9862 10.4405 42.9376 9.80923 44.0109 9.39646C45.0842 8.95942 46.1819 8.74089 47.304 8.74089C49.9385 8.74089 51.9876 9.55428 53.4512 11.1811C54.9392 12.8078 55.6832 15.2359 55.6832 18.4651C55.6832 18.7079 55.671 18.9872 55.6466 19.3028C55.6466 19.5942 55.6344 19.8613 55.61 20.1041H42.9864C43.1083 21.6337 43.645 22.8234 44.5963 23.6733C45.5721 24.4988 46.9747 24.9116 48.8042 24.9116C49.8775 24.9116 50.8533 24.8144 51.7314 24.6202C52.634 24.4259 53.3414 24.2196 53.8537 24.001L54.4391 27.6067C54.1952 27.7281 53.8537 27.8616 53.4146 28.0073C52.9999 28.1287 52.512 28.2379 51.951 28.3351C51.4143 28.4565 50.8289 28.5536 50.1946 28.6264C49.5604 28.6993 48.914 28.7357 48.2554 28.7357C46.5722 28.7357 45.1086 28.4929 43.8645 28.0073C42.6205 27.4974 41.5959 26.8054 40.791 25.9313C39.986 25.033 39.3883 23.9889 38.998 22.7992C38.6077 21.5852 38.4126 20.2619 38.4126 18.8293ZM51.2558 16.8626C51.2558 16.2556 51.1704 15.685 50.9996 15.1509C50.8289 14.5924 50.5727 14.119 50.2312 13.7305C49.9141 13.3177 49.5116 13.0021 49.0238 12.7836C48.5603 12.5408 47.9992 12.4194 47.3406 12.4194C46.6576 12.4194 46.0599 12.5529 45.5477 12.82C45.0354 13.0628 44.5963 13.3906 44.2304 13.8033C43.8889 14.2161 43.6206 14.6896 43.4255 15.2237C43.2303 15.7579 43.0961 16.3042 43.023 16.8626H51.2558Z" />
    <path d="M60.0114 9.83351C60.8652 9.5907 61.9751 9.36004 63.3411 9.14152C64.7071 8.923 66.2195 8.81373 67.8783 8.81373C69.4395 8.81373 70.7445 9.03226 71.7934 9.4693C72.8423 9.88207 73.6717 10.4769 74.2815 11.2539C74.9158 12.0066 75.3549 12.9292 75.5988 14.0219C75.8671 15.0902 76.0013 16.2678 76.0013 17.5546V28.2622H71.5739V18.2466C71.5739 17.2268 71.5007 16.3649 71.3543 15.6608C71.2324 14.9324 71.0128 14.3496 70.6957 13.9126C70.403 13.4513 69.9883 13.1235 69.4516 12.9292C68.9394 12.7107 68.3052 12.6015 67.549 12.6015C66.9879 12.6015 66.4025 12.6379 65.7926 12.7107C65.1828 12.7836 64.7315 12.8443 64.4388 12.8928V28.2622H60.0114V9.83351Z" />
    <path d="M84.5254 18.6837C84.5254 20.6261 84.9888 22.1557 85.9158 23.2726C86.8427 24.3652 88.1234 24.9116 89.7578 24.9116C90.4652 24.9116 91.0628 24.8873 91.5507 24.8387C92.0629 24.7659 92.4776 24.693 92.7948 24.6202V13.6941C92.4045 13.427 91.88 13.1842 91.2214 12.9657C90.5871 12.7229 89.9041 12.6015 89.1723 12.6015C87.5624 12.6015 86.3793 13.1478 85.6231 14.2404C84.8913 15.333 84.5254 16.8141 84.5254 18.6837ZM97.2222 27.6795C96.344 27.9466 95.2341 28.1894 93.8925 28.4079C92.5752 28.6264 91.1848 28.7357 89.7212 28.7357C88.2088 28.7357 86.8549 28.505 85.6597 28.0437C84.4644 27.5824 83.4399 26.9268 82.5861 26.077C81.7567 25.2029 81.1103 24.1589 80.6468 22.9448C80.2077 21.7066 79.9882 20.3226 79.9882 18.7929C79.9882 17.2875 80.1711 15.9279 80.537 14.7138C80.9273 13.4755 81.4884 12.4194 82.2202 11.5453C82.952 10.6712 83.8424 10.0035 84.8913 9.54214C85.9402 9.05654 87.1477 8.81373 88.5137 8.81373C89.4407 8.81373 90.2578 8.923 90.9652 9.14152C91.6726 9.36004 92.2825 9.60284 92.7948 9.86993V0.728407L97.2222 0V27.6795Z" />
  </SvgIcon>
);

export default SpendIconNoDot;
