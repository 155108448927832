import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { ScrollableContainer } from '../../../../ScrollableContainer';
import { Stack } from '../../../../Stack';
import { BigTitleModule } from './internal/BigTitleModule';
import { DualSentimentType } from './internal/H6Dual';
import {
  TitleBarHorizontalStack,
  TitleBarHorizontalStackProps,
} from './internal/TitleBarHorizontalStack/TitleBarHorizontalStack';

export interface ProgressBarSectionedTemplateProps {
  sections: TitleBarHorizontalStackProps[];
  topTitle?: string;
  value: string | number;
  valueSentiment: DualSentimentType;
  bottomTitle?: string;
  tableTitle?: string;
}

const ContainerStack = styled(Stack)({
  height: '100%',
});

const ScrollableListContainer = styled(ScrollableContainer)({
  flex: 1,
  '& ::-webkit-scrollbar': {
    display: 'none',
  },
  '& *': {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
});

export const ProgressBarSectionedTemplate: React.FC<ProgressBarSectionedTemplateProps> = ({
  sections,
  topTitle,
  value,
  valueSentiment,
  bottomTitle,
  tableTitle,
}) => {
  return (
    <ContainerStack spacing="medium">
      <BigTitleModule
        topTitle={topTitle}
        value={value}
        valueSentiment={valueSentiment}
        bottomTitle={bottomTitle}
      />
      <ScrollableListContainer>
        <Stack spacing={'large'}>
          {tableTitle && <Typography variant="heading.H9">{tableTitle}</Typography>}
          {sections.map((section, index) => (
            <TitleBarHorizontalStack key={index} title={section.title} rows={section.rows} />
          ))}
        </Stack>
      </ScrollableListContainer>
    </ContainerStack>
  );
};
