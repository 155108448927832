import { alpha, styled } from '@mui/material/styles';
import { FC } from 'react';

import { ButtonBase } from '../buttons';
import { MinusIcon } from '../icons';
import { Typography } from '../Typography';

export interface ToggleFilterProps {
  /**
   * The text label displayed in the filter.
   */
  children: string;
  /**
   * Callback fired when the main filter button is clicked.
   */
  onClick?: () => void;
  /**
   * Callback fired when the remove button (minus icon) is clicked.
   */
  onRemoveButtonClick?: () => void;
  /**
   * Whether the filter is selected.
   */
  selected?: boolean;
  /**
   * Whether the filter is removable.
   */
  removable?: boolean;
  /**
   * Indicates that this is a child (sub-option) selection.
   * When true, it applies the sub-selected styles.
   */
  child?: boolean;
}

const ToggleFilterRoot = styled('div')({
  position: 'relative',
});

const ToggleFilterButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'child',
})<{ selected?: boolean; child?: boolean }>(({ theme, selected, child }) => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0, // Prevent shrinking
  maxWidth: 220, // Too long label will truncate at this point
  borderRadius: theme.tokens.borderRadius['round'],
  padding: theme.spacing(theme.tokens.spacing['xsmall'], theme.tokens.spacing['large']),
  backgroundColor: theme.tokens.color['background.light'],
  color: theme.tokens.color['text.regular'],

  // Selected variant
  ...(selected && {
    backgroundColor: theme.tokens.color['background.positive'],
    color: theme.tokens.color['text.success'],

    // Child (sub-option) style
    ...(child && {
      marginLeft: -50,
      paddingLeft: 50,
      background: `linear-gradient(
        90deg,
        ${alpha(theme.tokens.color['background.positive'], 0)} 0%,
        ${alpha(theme.tokens.color['background.positive'], 0.5)} 35%
      )`,
      color: theme.tokens.color['text.success'],
    }),
  }),
}));

const RemoveButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  borderRadius: theme.tokens.borderRadius['round'],
  backgroundColor: theme.tokens.color['neutral.grey-darker'],
  color: theme.tokens.color['text.regular'],
  filter: `drop-shadow(0px 0px 4px ${alpha(theme.tokens.color['neutral.black'], 0.5)})`,
}));

const RemoveButtonWrapper = styled('div')({
  position: 'absolute',
  top: -8,
  left: -8,
});

const ToggleFilter: FC<ToggleFilterProps> = ({
  children,
  onClick,
  onRemoveButtonClick,
  selected,
  removable,
  child,
}) => (
  <ToggleFilterRoot role="presentation">
    <ToggleFilterButton
      selected={selected}
      child={child}
      onClick={onClick}
      disabled={removable}
      role="button"
      aria-pressed={!!selected}
    >
      <Typography variant="body.regular-condensed" lineClamp={1}>
        {children}
      </Typography>
    </ToggleFilterButton>
    {removable && (
      <RemoveButtonWrapper>
        <RemoveButton onClick={onRemoveButtonClick} aria-label="Remove filter">
          <MinusIcon size="icon.xsmall" color="text.regular" />
        </RemoveButton>
      </RemoveButtonWrapper>
    )}
  </ToggleFilterRoot>
);

export default ToggleFilter;
