import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { Stack } from '../../../../Stack';
import { Typography } from '../../../../Typography';
import { Legend, LegendProps, ValueComparisonBar, ValueComparisonBarProps } from '../../../commons';

export interface BenchmarkTemplateProps {
  /**
   * Main title displayed at the top.
   */
  title: string;
  /**
   * Optional subtitle displayed below the title.
   */
  subtitle?: string;
  /**
   * Visual sentiment color of the subtitle.
   * @default 'positive'
   */
  subtitleSentiment?: BenchmarkTemplateSubtitleSentiment;
  /**
   * Items to be displayed in the legend section.
   */
  legendItems?: LegendProps['items'];
  /**
   * Props to configure the value comparison bar.
   */
  barProps?: BenchmarkTemplateBarProps;
  /**
   * Items to be listed in the body section.
   */
  listItems?: BenchmarkTemplateListItem[];
  /**
   * Maximum number of lines to show for the title.
   * @default 1
   */
  titleLineClamp?: number;
  /**
   * Maximum number of lines to show for the subtitle.
   * @default 2
   */
  subtitleLineClamp?: number;
  /**
   * Width of the overall template container.
   * @default '100%'
   */
  width?: string | number;
}

export type BenchmarkTemplateSubtitleSentiment = 'positive' | 'negative';

export type BenchmarkTemplateBarProps = Omit<ValueComparisonBarProps, 'size' | 'trackColor'>;

export interface BenchmarkTemplateListItem {
  title: string;
  description: string;
}

const sentimentColorMap: Record<BenchmarkTemplateSubtitleSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

const Root = styled('div')<{ $width: string | number }>(({ theme, $width }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['xxlarge'],
  width: $width,
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['medium'],
}));

const List = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['xlarge'],
}));

const BenchmarkTemplate: FC<BenchmarkTemplateProps> = ({
  title,
  subtitle,
  subtitleSentiment = 'positive',
  legendItems,
  barProps,
  listItems,
  titleLineClamp = 1,
  subtitleLineClamp = 2,
  width = '100%',
}) => (
  <Root $width={width}>
    <Header>
      <Stack direction="column" spacing="medium">
        <Typography variant="heading.H7" lineClamp={titleLineClamp}>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="heading.H8"
            lineClamp={subtitleLineClamp}
            color={sentimentColorMap[subtitleSentiment]}
          >
            {subtitle}
          </Typography>
        )}
        {barProps && <ValueComparisonBar trackColor="neutral.grey" {...barProps} />}
        {!!legendItems?.length && <Legend items={legendItems} />}
      </Stack>
    </Header>
    <List>
      {listItems?.map((item, index) => {
        const { title, description } = item;
        return (
          <Stack key={index} direction="column" spacing="xsmall">
            <Typography variant="heading.H9" color="text.subtle" lineClamp={1}>
              {title}
            </Typography>
            <Typography variant="body.bold-condensed">{description}</Typography>
          </Stack>
        );
      })}
    </List>
  </Root>
);

export default BenchmarkTemplate;
