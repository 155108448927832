import { styled, useTheme } from '@mui/material';
import React, { SVGProps, useId } from 'react';

export interface AilyLogoProps extends SVGProps<SVGSVGElement> {
  loading?: boolean;
}

// These are auxiliary variables not intended to be changed
const size = 130;
const strokeWidth = 18;
const radius = size / 2 - strokeWidth / 2;
const circumference = 2 * Math.PI * radius;

const ProgressCircle = styled('circle', {
  shouldForwardProp: (prop) => prop !== 'loading',
})<AilyLogoProps>(({ loading }) => ({
  '@keyframes progressAnimation': {
    '0%, 25%': {
      strokeDashoffset: circumference * 0.95,
      transform: 'rotate(0deg)',
    },
    '50%, 75%': {
      strokeDashoffset: circumference * 0.25,
      transform: 'rotate(90deg)',
    },
    '100%': {
      strokeDashoffset: circumference * 0.95,
      transform: 'rotate(360deg)',
    },
  },
  animation: loading ? '1.4s ease-in-out infinite both progressAnimation' : undefined,
}));

const ProgressCircleWrapper = styled('g', {
  shouldForwardProp: (prop) => prop !== 'loading',
})<AilyLogoProps>(({ loading }) => ({
  '@keyframes progressWrapperAnimation': {
    '0%': {
      transform: 'rotateZ(0deg)',
    },
    '100%': {
      transform: 'rotateZ(360deg)',
    },
  },
  animation: loading ? '2s linear infinite progressWrapperAnimation' : undefined,
}));

export const AilyLogo: React.FC<AilyLogoProps> = ({ loading, ...rest }) => {
  const id = useId();
  const { palette } = useTheme();
  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      data-testid="AilyLogo"
      {...rest}
    >
      <defs>
        <linearGradient id={`${id}.gradient`} gradientTransform="rotate(90)">
          <stop stopColor={palette.success.main} />
          <stop offset="1" stopColor={palette.info.main} />
        </linearGradient>
      </defs>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={palette.grey['100']}
        strokeWidth={strokeWidth}
      />
      <g transform={`translate(${size / 2} ${size / 2}) rotate(-90)`}>
        <ProgressCircleWrapper loading={loading}>
          <ProgressCircle
            loading={loading}
            r={radius}
            stroke={`url(#${id}.gradient)`}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={circumference / 4}
            strokeLinecap="round"
          />
        </ProgressCircleWrapper>
      </g>
      <g>
        <path
          d="M52.785 77.922c2.165 0 4.675-.404 6.32-.78v-7.474c-1.789-.26-4.357-.52-6.407-.52-4.935 0-6.926 1.645-6.926 4.358.029 3.088 3.002 4.416 7.013 4.416zm.26-29.09c8.167 0 13.708 4.097 13.708 13.39v19.25c0 .52-.26.923-.721 1.125-3.435 1.068-8.37 1.992-13.247 1.992-7.648 0-14.719-2.77-14.719-11.025 0-7.186 6.003-10.563 14.46-10.563 2.164 0 4.617.26 6.608.52V62.28c0-4.675-1.703-6.667-6.609-6.667-3.29 0-6.32.866-8.456 2.107-.462.317-.98.26-1.327-.26l-2.627-4.011c-.317-.52-.317-.866 0-1.126 3.175-2.193 7.59-3.492 12.93-3.492zM77.431 44.993c-2.453 0-4.357-1.905-4.357-4.358 0-2.51 1.904-4.3 4.358-4.3 2.51 0 4.3 1.79 4.3 4.3 0 2.453-1.79 4.358-4.3 4.358zM90.563 83.81c-2.453 0-4.358-1.905-4.358-4.359 0-2.51 1.905-4.3 4.358-4.3 2.51 0 4.3 1.79 4.3 4.3 0 2.454-1.79 4.358-4.3 4.358zM74.546 83.78c-.607 0-.982-.317-.982-.98V50.533c0-.606.404-.924.982-.924h5.743c.606 0 .923.318.923.924v32.265c0 .664-.317.982-.923.982h-5.743z"
          fill={palette.success.main}
        />
      </g>
    </svg>
  );
};
