import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const MinusIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="29" height="28" viewBox="0 0 29 28" {...props}>
    <path d="M6.51721 15.1668V12.8335H22.8505V15.1668H6.51721Z" />
  </SvgIcon>
);

export default MinusIcon;
