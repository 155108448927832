import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const EbiIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 48 35" {...props}>
      <path d="M0.8573 19.0418C0.8573 17.3476 1.10061 15.8621 1.58723 14.5852C2.09819 13.3084 2.76729 12.2526 3.59455 11.4177C4.42181 10.5583 5.37072 9.91993 6.44129 9.50251C7.51186 9.06053 8.60677 8.83954 9.726 8.83954C12.3538 8.83954 14.3976 9.66211 15.8574 11.3072C17.3416 12.9524 18.0837 15.4078 18.0837 18.6735C18.0837 18.9191 18.0716 19.2015 18.0472 19.5207C18.0472 19.8153 18.0351 20.0854 18.0107 20.3309H5.41939C5.54104 21.8779 6.07633 23.081 7.02524 23.9404C7.99849 24.7753 9.39753 25.1927 11.2224 25.1927C12.2929 25.1927 13.2662 25.0945 14.1421 24.898C15.0423 24.7016 15.748 24.4929 16.2589 24.2719L16.8429 27.9182C16.5995 28.041 16.2589 28.176 15.8209 28.3234C15.4073 28.4461 14.9207 28.5566 14.3611 28.6549C13.8258 28.7776 13.2418 28.8758 12.6092 28.9495C11.9766 29.0232 11.3319 29.06 10.6749 29.06C8.99606 29.06 7.53619 28.8145 6.29531 28.3234C5.05442 27.8077 4.03251 27.1079 3.22958 26.224C2.42666 25.3155 1.83055 24.2596 1.44125 23.0565C1.05195 21.8288 0.8573 20.4906 0.8573 19.0418ZM13.6676 17.053C13.6676 16.4391 13.5825 15.8621 13.4122 15.3219C13.2418 14.7571 12.9864 14.2783 12.6457 13.8854C12.3294 13.468 11.928 13.1488 11.4413 12.9278C10.9791 12.6823 10.4194 12.5595 9.76249 12.5595C9.08122 12.5595 8.48511 12.6946 7.97415 12.9647C7.4632 13.2102 7.02524 13.5417 6.66027 13.9591C6.31964 14.3765 6.052 14.8553 5.85735 15.3955C5.6627 15.9357 5.52888 16.4882 5.45588 17.053H13.6676Z" />
      <path d="M34.3243 18.8945C34.3243 17.0038 33.9471 15.506 33.1929 14.4011C32.4629 13.2961 31.295 12.7437 29.6892 12.7437C28.9592 12.7437 28.2658 12.8542 27.6089 13.0752C26.9763 13.2961 26.4653 13.5417 26.076 13.8118V24.898C26.3923 24.9717 26.7938 25.0454 27.2804 25.119C27.7914 25.1681 28.3996 25.1927 29.1052 25.1927C30.7354 25.1927 32.0128 24.6402 32.9374 23.5353C33.862 22.4058 34.3243 20.8589 34.3243 18.8945ZM38.8498 18.9682C38.8498 20.5151 38.6187 21.9147 38.1564 23.167C37.7184 24.3947 37.0737 25.4505 36.2221 26.3345C35.3948 27.2184 34.3851 27.8937 33.1929 28.3602C32.0006 28.8267 30.6503 29.06 29.1417 29.06C27.6819 29.06 26.2828 28.9495 24.9446 28.7285C23.6307 28.5075 22.5358 28.262 21.6599 27.9919V0.736628L26.076 0V9.98132C26.587 9.71122 27.2074 9.46568 27.9373 9.24469C28.6916 9.0237 29.5189 8.9132 30.4191 8.9132C31.7573 8.9132 32.9496 9.15875 33.9958 9.64984C35.0664 10.1164 35.9544 10.7916 36.66 11.6756C37.3656 12.5595 37.9009 13.6276 38.2659 14.8799C38.6552 16.1076 38.8498 17.4704 38.8498 18.9682Z" />
      <path d="M46.7477 28.5812H42.3316V9.31835H46.7477V28.5812ZM47.2222 3.68314C47.2222 4.51799 46.9545 5.18095 46.4193 5.67204C45.884 6.16313 45.2514 6.40867 44.5214 6.40867C43.7672 6.40867 43.1224 6.16313 42.5871 5.67204C42.0518 5.18095 41.7842 4.51799 41.7842 3.68314C41.7842 2.82374 42.0518 2.1485 42.5871 1.65741C43.1224 1.16633 43.7672 0.920785 44.5214 0.920785C45.2514 0.920785 45.884 1.16633 46.4193 1.65741C46.9545 2.1485 47.2222 2.82374 47.2222 3.68314Z" />
    </SvgIcon>
  );
};

export default EbiIconNoDot;
