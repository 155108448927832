import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

export interface CenterProps {
  children: ReactNode;
  height?: string | number;
}

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height?: string | number }>(({ height = '100%' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height,
}));

const Center: React.FC<CenterProps> = ({ children, height }) => (
  <StyledDiv height={height}>{children}</StyledDiv>
);

export default Center;
