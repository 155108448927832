import { styled } from '@mui/material/styles';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import React from 'react';

import { TokenAnimationProvider } from '../../providers';
import AnimatedBlockquote from './AnimatedBlockquote';
import AnimatedListItem from './AnimatedListItem';
import AnimatedParagraph from './AnimatedParagraph';

export interface MarkdownRendererProps {
  children: string;
  options?: MarkdownToJSX.Options;
}

// Use StyledWrapper for simple style tweaks. For complex styling, create custom components.
const StyledWrapper = styled('div')(({ theme }) => ({
  em: { fontStyle: 'italic' },
  strong: { fontWeight: 'bold' },
  ul: {
    margin: 0,
    padding: theme.spacing(theme.tokens.spacing['xsmall'], 0),
    paddingInlineStart: theme.tokens.spacing['xlarge'],
  },
}));

// Default markdown element overrides.
// Only tags defined here are rendered; others are ignored.
const defaultOverrides: MarkdownToJSX.Overrides = {
  p: AnimatedParagraph,
  span: AnimatedParagraph,
  em: 'em',
  strong: 'strong',
  ul: 'ul',
  pre: 'pre',
  li: AnimatedListItem,
  blockquote: AnimatedBlockquote,
};

// MarkdownRenderer: Used only for the Chat component.
const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ children, options = {} }) => {
  const { overrides = {}, ...rest } = options;
  const mergedOverrides = { ...defaultOverrides, ...overrides };
  // Only render elements defined in overrides.
  const allowedElements = Object.keys(mergedOverrides);

  return (
    // Keying by children resets the token counter on content change.
    <TokenAnimationProvider key={children}>
      <StyledWrapper>
        <Markdown
          wrapper={React.Fragment}
          options={{
            createElement: (type, props, ...children) => {
              if (typeof type === 'string' && !allowedElements.includes(type)) {
                return <></>;
              }

              return React.createElement(type, props, ...children);
            },
            overrides: mergedOverrides,
            ...rest,
          }}
        >
          {children}
        </Markdown>
      </StyledWrapper>
    </TokenAnimationProvider>
  );
};

export default MarkdownRenderer;
