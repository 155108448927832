import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const SupplyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 116 35" {...props}>
    <circle cx="3" cy="26" r="3" />
    <path d="M14.6254 25.0415C15.788 25.0415 16.6356 24.9083 17.1685 24.6417C17.7013 24.351 17.9677 23.8664 17.9677 23.188C17.9677 22.558 17.6771 22.037 17.0958 21.6251C16.5388 21.2132 15.6063 20.765 14.2985 20.2804C13.4992 19.9896 12.7606 19.6867 12.0824 19.3718C11.4285 19.0325 10.8593 18.6449 10.375 18.2087C9.89058 17.7726 9.50307 17.2516 9.21244 16.6459C8.94603 16.0159 8.81282 15.2527 8.81282 14.3562C8.81282 12.6116 9.45463 11.2426 10.7383 10.2492C12.0219 9.23157 13.7657 8.72274 15.9696 8.72274C17.0837 8.72274 18.1493 8.83177 19.1665 9.04984C20.1837 9.24368 20.9466 9.43752 21.4553 9.63136L20.656 13.1931C20.1716 12.9751 19.554 12.7812 18.8032 12.6116C18.0525 12.4178 17.1806 12.3209 16.1876 12.3209C15.2915 12.3209 14.5649 12.4784 14.0078 12.7934C13.4508 13.0841 13.1723 13.5445 13.1723 14.1745C13.1723 14.4894 13.2207 14.7681 13.3176 15.0104C13.4387 15.2527 13.6324 15.4829 13.8989 15.7009C14.1653 15.8948 14.5165 16.1007 14.9524 16.3188C15.3883 16.5126 15.9212 16.7186 16.5509 16.9367C17.5923 17.3243 18.4763 17.712 19.2029 18.0997C19.9294 18.4631 20.5228 18.8872 20.983 19.3718C21.4674 19.8321 21.8185 20.3652 22.0365 20.9709C22.2545 21.5767 22.3635 22.3036 22.3635 23.1516C22.3635 24.9688 21.6853 26.3499 20.3291 27.2949C18.997 28.2156 17.0837 28.676 14.5891 28.676C12.918 28.676 11.5738 28.5306 10.5566 28.2399C9.5394 27.9733 8.82493 27.7553 8.41321 27.5857L9.17611 23.9148C9.83003 24.1814 10.605 24.4358 11.5012 24.6781C12.4215 24.9204 13.4629 25.0415 14.6254 25.0415Z" />
    <path d="M41.6915 27.622C40.8439 27.8401 39.7419 28.0581 38.3856 28.2762C37.0293 28.5185 35.5399 28.6397 33.9172 28.6397C32.3914 28.6397 31.1077 28.4216 30.0663 27.9855C29.0491 27.5493 28.2257 26.9436 27.596 26.1682C26.9905 25.3929 26.5545 24.4721 26.2881 23.406C26.0217 22.3157 25.8885 21.1284 25.8885 19.8442V9.19522H30.2843V19.1537C30.2843 21.189 30.5749 22.6428 31.1562 23.5151C31.7617 24.3873 32.8031 24.8235 34.2805 24.8235C34.8133 24.8235 35.3703 24.7992 35.9516 24.7508C36.5571 24.7023 37.0051 24.6417 37.2958 24.5691V9.19522H41.6915V27.622Z" />
    <path d="M59.5293 18.7902C59.5293 16.8276 59.0812 15.3011 58.1851 14.2108C57.3132 13.1205 55.8964 12.5753 53.9346 12.5753C53.5229 12.5753 53.087 12.5995 52.6268 12.648C52.1908 12.6722 51.7549 12.7449 51.319 12.866V23.7331C51.7065 23.9997 52.2151 24.2541 52.8448 24.4964C53.4987 24.7144 54.1889 24.8235 54.9155 24.8235C56.514 24.8235 57.6765 24.2783 58.4031 23.188C59.1539 22.0976 59.5293 20.6317 59.5293 18.7902ZM64.034 18.7175C64.034 20.1713 63.8403 21.504 63.4528 22.7155C63.0895 23.927 62.5567 24.9688 61.8543 25.8411C61.152 26.7134 60.268 27.3918 59.2023 27.8764C58.1609 28.361 56.9741 28.6033 55.6421 28.6033C54.746 28.6033 53.9225 28.4943 53.1717 28.2762C52.4209 28.0581 51.8033 27.8159 51.319 27.5493V34.9273H46.9232V9.81308C47.8193 9.57078 48.9213 9.3406 50.2291 9.12253C51.5369 8.90446 52.9174 8.79543 54.3706 8.79543C55.8722 8.79543 57.2163 9.02561 58.4031 9.48598C59.5898 9.94635 60.5949 10.6127 61.4184 11.4849C62.266 12.333 62.9079 13.3749 63.3438 14.6106C63.804 15.8221 64.034 17.1911 64.034 18.7175Z" />
    <path d="M80.8072 18.7902C80.8072 16.8276 80.3591 15.3011 79.463 14.2108C78.5911 13.1205 77.1743 12.5753 75.2125 12.5753C74.8008 12.5753 74.3649 12.5995 73.9047 12.648C73.4688 12.6722 73.0328 12.7449 72.5969 12.866V23.7331C72.9844 23.9997 73.493 24.2541 74.1227 24.4964C74.7766 24.7144 75.4668 24.8235 76.1934 24.8235C77.7919 24.8235 78.9544 24.2783 79.681 23.188C80.4318 22.0976 80.8072 20.6317 80.8072 18.7902ZM85.312 18.7175C85.312 20.1713 85.1182 21.504 84.7307 22.7155C84.3674 23.927 83.8346 24.9688 83.1322 25.8411C82.4299 26.7134 81.5459 27.3918 80.4802 27.8764C79.4388 28.361 78.252 28.6033 76.92 28.6033C76.0239 28.6033 75.2004 28.4943 74.4496 28.2762C73.6988 28.0581 73.0813 27.8159 72.5969 27.5493V34.9273H68.2011V9.81308C69.0972 9.57078 70.1992 9.3406 71.507 9.12253C72.8148 8.90446 74.1953 8.79543 75.6485 8.79543C77.1501 8.79543 78.4942 9.02561 79.681 9.48598C80.8677 9.94635 81.8728 10.6127 82.6963 11.4849C83.5439 12.333 84.1858 13.3749 84.6217 14.6106C85.0819 15.8221 85.312 17.1911 85.312 18.7175Z" />
    <path d="M96.0908 28.567C94.783 28.5427 93.6931 28.3974 92.8212 28.1308C91.9736 27.8643 91.2954 27.4887 90.7868 27.0042C90.2782 26.4953 89.9149 25.8775 89.6969 25.1506C89.5032 24.3994 89.4063 23.5514 89.4063 22.6064V0.726894L93.8021 0V21.7705C93.8021 22.3036 93.8384 22.7518 93.9111 23.1153C94.008 23.4787 94.1654 23.7937 94.3834 24.0602C94.6013 24.3025 94.892 24.4964 95.2553 24.6417C95.6428 24.7629 96.1272 24.8598 96.7084 24.9325L96.0908 28.567Z" />
    <path d="M115.413 9.19522C113.451 16.4157 111.175 22.9578 108.583 28.8214C108.099 29.9117 107.603 30.8446 107.094 31.6199C106.585 32.3953 106.016 33.0374 105.386 33.5462C104.757 34.055 104.054 34.4185 103.279 34.6366C102.504 34.8788 101.608 35 100.591 35C99.9129 35 99.2348 34.9273 98.5566 34.7819C97.9027 34.6366 97.4062 34.4791 97.0672 34.3094L97.8664 30.784C98.7383 31.1232 99.5981 31.2928 100.446 31.2928C101.584 31.2928 102.468 31.0142 103.098 30.4569C103.752 29.9238 104.321 29.0879 104.805 27.9491C103.425 25.2838 102.093 22.3763 100.809 19.2264C99.5496 16.0765 98.4234 12.7328 97.4304 9.19522H102.117C102.359 10.2129 102.65 11.3153 102.989 12.5026C103.352 13.6899 103.74 14.9013 104.151 16.1371C104.563 17.3486 104.999 18.5601 105.459 19.7715C105.919 20.983 106.379 22.1097 106.84 23.1516C107.615 20.9952 108.341 18.7054 109.019 16.2825C109.697 13.8352 110.315 11.4728 110.872 9.19522H115.413Z" />
  </SvgIcon>
);

export default SupplyIcon;
