import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <path d="M20.17 10.043a.5.5 0 0 0-.352.15l-6.35 6.455a.5.5 0 0 0 0 .704l6.35 6.455a.5.5 0 0 0 .707.006.5.5 0 0 0 .006-.707L14.527 17l6.004-6.105a.5.5 0 0 0-.006-.707.5.5 0 0 0-.355-.145z" />
  </SvgIcon>
);

export default ArrowLeftIcon;
