import { styled } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

export interface AgentFooterProps {
  /**
   * Content of the component.
   */
  children?: ReactNode;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(theme.tokens.spacing['xlarge'], 0),
}));

const AgentFooter: FC<AgentFooterProps> = ({ children }) => <Container>{children}</Container>;

export default AgentFooter;
