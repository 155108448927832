import { ColorTokenKey } from '@aily/ui-theme';
import { Stack, styled } from '@mui/material';
import React from 'react';

import { StarIcon } from '../../../../../../icons';
import { Typography } from '../../../../../../Typography';
import { ProgressBar, ProgressBarSentiment } from '../../../../../commons/utils/ProgressBar';

export interface TitleBarHorizontalRowProps {
  title: string;
  value: string;
  valueSentiment: ProgressBarSentiment;
  percentage: number;
  showStarIcon?: boolean;
  barSideValue?: string;
}

const TitleBarHorizontalRowRoot = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing['4'],
  flexDirection: 'row',
  alignItems: 'center',
  width: 278,
}));

const TitleValueStack = styled(Stack)<{ hasNumericValue?: boolean }>(() => ({
  flex: 1,
}));

const ProgressBarStack = styled(Stack)(() => ({
  width: 74,
}));

const IconAndValueContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['4'],
}));

const valueSentimentColorMap: Record<ProgressBarSentiment, ColorTokenKey> = {
  positive: 'text.success',
  negative: 'text.danger',
  neutral: 'text.regular',
};

export const TitleBarHorizontalRow: React.FC<TitleBarHorizontalRowProps> = ({
  title,
  value,
  percentage,
  valueSentiment,
  showStarIcon = false,
  barSideValue,
}) => {
  return (
    <TitleBarHorizontalRowRoot>
      <TitleValueStack>
        <Typography variant="body.regular-condensed" lineClamp={1}>
          {title}
        </Typography>
        <IconAndValueContainer>
          {showStarIcon && <StarIcon size="icon.xxsmall" color="neutral.grey-lighter" />}
          <Typography
            variant="body.regular-condensed"
            color={valueSentimentColorMap[valueSentiment]}
          >
            {value}
          </Typography>
        </IconAndValueContainer>
      </TitleValueStack>
      <ProgressBarStack>
        <ProgressBar percentage={percentage} sentiment={valueSentiment} />
      </ProgressBarStack>
      {barSideValue && (
        <Typography variant="body.regular-condensed" color="neutral.0">
          {barSideValue}
        </Typography>
      )}
    </TitleBarHorizontalRowRoot>
  );
};
