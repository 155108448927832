import { ColorTokenKey } from '@aily/ui-theme';
import { Box, styled } from '@mui/material';
import React from 'react';

export type ProgressBarSentiment = 'positive' | 'negative' | 'neutral';

export interface ProgressBarProps {
  percentage: number;
  sentiment?: ProgressBarSentiment;
}

const ProgressBarRoot = styled(Box)(() => ({
  width: '100%',
  height: 8,
}));

const sentimentColorMap: Record<ProgressBarSentiment, ColorTokenKey> = {
  positive: 'mint.500',
  negative: 'pink.500',
  neutral: 'aqua.500',
};

const ProgressBarFill = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'percentage' && prop !== 'sentiment',
})<ProgressBarProps>(({ theme, percentage, sentiment = 'positive' }) => ({
  width: `${percentage}%`,
  height: '100%',
  backgroundColor: theme.tokens.color[sentimentColorMap[sentiment]],
  opacity: 0.2,
  borderRadius: theme.tokens.borderRadius['4'],
  transition: 'width 0.6s ease',
}));

export const ProgressBar: React.FC<ProgressBarProps> = ({ percentage, sentiment = 'positive' }) => {
  return (
    <ProgressBarRoot>
      <ProgressBarFill percentage={percentage} sentiment={sentiment} />
    </ProgressBarRoot>
  );
};
