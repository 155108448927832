import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';

import RecommenderCardBackground from './RecommenderCardBackground';
import RecommenderCardCheckedMark from './RecommenderCardCheckedMark';
import RecommenderCardContent from './RecommenderCardContent';
import { RecommenderCardProvider } from './RecommenderCardContext';
import { useRecommenderCardGroupContext } from './RecommenderCardGroupContext';
import RecommenderCardIcon from './RecommenderCardIcon';
import RecommenderCardSubContent from './RecommenderCardSubContent';

export interface RecommenderCardProps {
  children: React.ReactNode;
  size?: RecommenderCardSize;
  hasCheckedMark?: boolean;
}

export type RecommenderCardSize = 'default' | 'large';

export interface RecommenderCardCommonProps {
  /**
   * Title displayed inside the card.
   */
  title: string;
  /**
   * Whether the card is marked as checked.
   */
  checked?: boolean;
  /**
   * Callback fired when the card is clicked.
   */
  onClick?: () => void;
  /**
   * The size of the card size.
   */
  size?: RecommenderCardSize;
}

interface RecommenderCardSizeProps {
  width: number;
  height: number;
}

export const recommenderCardSizeConfig: Record<RecommenderCardSize, RecommenderCardSizeProps> = {
  default: { width: 170, height: 170 },
  large: { width: 200, height: 200 },
};

export const RecommenderCardRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})<RecommenderCardSizeProps>(({ theme, width, height }) => ({
  position: 'relative',
  overflow: 'hidden',
  flexShrink: 0, // Prevent shrinking
  width,
  height,
  borderRadius: theme.tokens.borderRadius['medium'],
}));

// Enhance the component type to include subcomponents
interface RecommenderCardComponent extends React.FC<RecommenderCardProps> {
  Background: typeof RecommenderCardBackground;
  CheckedMark: typeof RecommenderCardCheckedMark;
  Content: typeof RecommenderCardContent;
  SubContent: typeof RecommenderCardSubContent;
  Icon: typeof RecommenderCardIcon;
}

const RecommenderCard: RecommenderCardComponent = ({ children, size, hasCheckedMark, ...rest }) => {
  const groupContext = useRecommenderCardGroupContext();
  const cardSize = size ?? groupContext?.size ?? 'default';
  const cardHasCheckedMark = !!hasCheckedMark;
  const { width, height } = recommenderCardSizeConfig[cardSize];
  const contextValue = useMemo(
    () => ({ size: cardSize, hasCheckedMark: cardHasCheckedMark }),
    [cardSize, cardHasCheckedMark],
  );

  return (
    <RecommenderCardProvider value={contextValue}>
      <RecommenderCardRoot width={width} height={height} data-testid="recommender-card" {...rest}>
        {children}
      </RecommenderCardRoot>
    </RecommenderCardProvider>
  );
};

RecommenderCard.Background = RecommenderCardBackground;
RecommenderCard.CheckedMark = RecommenderCardCheckedMark;
RecommenderCard.Content = RecommenderCardContent;
RecommenderCard.SubContent = RecommenderCardSubContent;
RecommenderCard.Icon = RecommenderCardIcon;

export default RecommenderCard;
