import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { Stack } from '../../../../Stack';
import { Typography } from '../../../../Typography';
import { Legend, LegendProps, ValueComparisonBar, ValueComparisonBarProps } from '../../../commons';

export interface AssessmentTemplateProps {
  /**
   * Items to be displayed in the legend section.
   */
  legendItems?: LegendProps['items'];
  /**
   * Items to be listed in the body section.
   */
  listItems?: AssessmentTemplateListItem[];
  /**
   * Maximum number of lines to show for each item's title.
   * @default 1
   */
  itemTitleLineClamp?: number;
  /**
   * Maximum number of lines to show for each item's description.
   * @default 2
   */
  itemDescriptionLineClamp?: number;
  /**
   * Width of the overall template container.
   * @default '100%'
   */
  width?: string | number;
}

export interface AssessmentTemplateListItem {
  title: string;
  description?: string;
  barProps?: AssessmentTemplateListItemBarProps;
}

export type AssessmentTemplateListItemBarProps = Omit<
  ValueComparisonBarProps,
  'size' | 'trackColor'
>;

const Root = styled('div')<{ $width: string | number }>(({ theme, $width }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['large'],
  width: $width,
}));

const List = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['medium'],
}));

const AssessmentTemplate: FC<AssessmentTemplateProps> = ({
  legendItems,
  listItems,
  itemTitleLineClamp = 1,
  itemDescriptionLineClamp = 2,
  width = '100%',
}) => (
  <Root $width={width}>
    {!!legendItems?.length && <Legend size="small" items={legendItems} />}
    <List>
      {listItems?.map((item, index) => {
        const { title, description, barProps } = item;
        return (
          <Stack key={index} direction="column" spacing="xsmall">
            <Stack direction="column">
              <Typography variant="body.small-condensed" lineClamp={itemTitleLineClamp}>
                {title}
              </Typography>
              {description && (
                <Typography
                  variant="body.xsmall-condensed"
                  color="text.subtle"
                  lineClamp={itemDescriptionLineClamp}
                >
                  {description}
                </Typography>
              )}
            </Stack>
            {barProps && (
              <ValueComparisonBar size="small" trackColor="neutral.grey-darker" {...barProps} />
            )}
          </Stack>
        );
      })}
    </List>
  </Root>
);

export default AssessmentTemplate;
