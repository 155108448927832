import { NormalizedCacheObject } from '@apollo/client/core';

/**
 * Finds all query keys in the Apollo cache that start with a given prefix
 * @param data - The normalized Apollo cache object
 * @param prefix - The prefix to search for in query keys
 * @returns Array of query keys that start with the given prefix
 */
export const findQueryKeysByPrefix = (data: NormalizedCacheObject, prefix: string) => {
  return Object.keys(data['ROOT_QUERY'] ?? {}).filter((key) => key.startsWith(prefix));
};

const siteConfigurationVersionStorageKeyMask = /aily\..*?\.site\.configuration/;
const cachePersistorStorageKeyMask = /aily\..*?\.site\.cache/;

/**
 * Removes unused cache entries from localStorage based on version patterns
 * @param configurationVersionKey - The current configuration version key to keep
 * @param cacheKey - The current cache key to keep
 * @description
 * Removes all localStorage entries that match either:
 * - The site configuration pattern but don't match the current version key
 * - The cache persistor pattern but don't match the current cache key
 */
export const removeUnusedCache = (configurationVersionKey: string, cacheKey: string) => {
  Object.keys(localStorage).forEach((key) => {
    if (siteConfigurationVersionStorageKeyMask.test(key) && configurationVersionKey !== key) {
      localStorage.removeItem(key);
    }
    if (cachePersistorStorageKeyMask.test(key) && cacheKey !== key) {
      localStorage.removeItem(key);
    }
  });
};
