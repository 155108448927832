import { ApolloCache, NormalizedCacheObject } from '@apollo/client/core';

/**
 * Creates a handler function that persists Apollo cache data to localStorage
 * @param cache - The Apollo cache instance to extract data from
 * @param key - The localStorage key to store the data under
 * @returns A function that when called will:
 * 1. Extract the current cache state
 * 2. Stringify the result
 * 3. Store it in localStorage under the specified key
 */
export const getPersistHandler = (cache: ApolloCache<NormalizedCacheObject>, key: string) => () => {
  const data = cache.extract();
  const stringifiedData = JSON.stringify(data);
  localStorage.setItem(key, stringifiedData);
};
