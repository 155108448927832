import { easeCubicInOut } from 'd3';
import React, { useRef } from 'react';

import { useMicroChartContext } from './MicroChartContext';
import { MicroChartSeriesLineStyle, MicroChartSeriesProps } from './MicroChartSeries';
import useMicroChartPathTween from './useMicroChartPathTween';

export const MicroChartLineSeries: React.FC<MicroChartSeriesProps> = ({
  data,
  color,
  lineWidth,
  lineOpacity,
  lineStyle,
  animationDuration,
  animationEasing = animationDuration ? easeCubicInOut : undefined,
  tweenOrigin,
}) => {
  const { lineGenerator } = useMicroChartContext();
  const lineRef = useRef<SVGPathElement>(null);
  const animated = !!animationDuration && !!animationEasing;

  const { initialData } = useMicroChartPathTween({
    ref: lineRef,
    data,
    generator: lineGenerator,
    origin: tweenOrigin,
    duration: animationDuration,
    easing: animationEasing,
    disable: !animated,
  });

  return (
    <path
      ref={lineRef}
      stroke={color}
      strokeWidth={lineWidth}
      strokeOpacity={lineOpacity}
      strokeDasharray={lineStyle === MicroChartSeriesLineStyle.Dashed ? 4 : 0}
      fill="none"
      d={lineGenerator(animated ? initialData : data) ?? ''}
      vectorEffect="non-scaling-stroke"
    />
  );
};
