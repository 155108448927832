import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const ClinopsIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 117 35" {...props}>
    <path d="M0 18.7975C0 17.3862 0.219 16.0722 0.657 14.8555C1.095 13.6145 1.7155 12.5438 2.5185 11.6435C3.34583 10.7432 4.3435 10.0375 5.5115 9.5265C6.6795 9.0155 7.9935 8.76 9.4535 8.76C11.2542 8.76 12.9575 9.0885 14.5635 9.7455L13.6145 13.359C13.1035 13.14 12.5195 12.9575 11.8625 12.8115C11.2298 12.6655 10.5485 12.5925 9.8185 12.5925C8.09083 12.5925 6.77683 13.14 5.8765 14.235C4.97617 15.3057 4.526 16.8265 4.526 18.7975C4.526 20.6955 4.95183 22.2042 5.8035 23.3235C6.65517 24.4185 8.09083 24.966 10.1105 24.966C10.8648 24.966 11.607 24.893 12.337 24.747C13.067 24.601 13.6997 24.4185 14.235 24.1995L14.8555 27.8495C14.3688 28.0928 13.6267 28.3118 12.629 28.5065C11.6557 28.7012 10.6458 28.7985 9.5995 28.7985C7.96917 28.7985 6.54567 28.5552 5.329 28.0685C4.13667 27.5575 3.139 26.864 2.336 25.988C1.55733 25.0877 0.973333 24.0292 0.584 22.8125C0.194666 21.5715 0 20.2332 0 18.7975Z" />
    <path d="M24.9178 28.689C23.6038 28.6647 22.5088 28.5187 21.6328 28.251C20.7811 27.9833 20.0998 27.6062 19.5888 27.1195C19.0778 26.6085 18.7128 25.988 18.4938 25.258C18.2991 24.5037 18.2018 23.652 18.2018 22.703V0.729999L22.6183 0V21.8635C22.6183 22.3988 22.6548 22.849 22.7278 23.214C22.8251 23.579 22.9833 23.8953 23.2023 24.163C23.4213 24.4063 23.7133 24.601 24.0783 24.747C24.4676 24.8687 24.9543 24.966 25.5383 25.039L24.9178 28.689Z" />
    <path d="M33.5637 28.324H29.1472V9.2345H33.5637V28.324ZM34.0382 3.65C34.0382 4.47733 33.7706 5.13433 33.2352 5.621C32.6999 6.10767 32.0672 6.351 31.3372 6.351C30.5829 6.351 29.9381 6.10767 29.4027 5.621C28.8674 5.13433 28.5997 4.47733 28.5997 3.65C28.5997 2.79833 28.8674 2.12917 29.4027 1.6425C29.9381 1.15583 30.5829 0.912499 31.3372 0.912499C32.0672 0.912499 32.6999 1.15583 33.2352 1.6425C33.7706 2.12917 34.0382 2.79833 34.0382 3.65Z" />
    <path d="M39.1982 9.855C40.0498 9.61167 41.157 9.3805 42.5197 9.1615C43.8823 8.9425 45.391 8.833 47.0456 8.833C48.603 8.833 49.9048 9.052 50.9511 9.49C51.9975 9.90367 52.8248 10.4998 53.4332 11.2785C54.0658 12.0328 54.5038 12.9575 54.7472 14.0525C55.0148 15.1232 55.1487 16.3033 55.1487 17.593V28.324H50.7321V18.2865C50.7321 17.2645 50.6592 16.4007 50.5132 15.695C50.3915 14.965 50.1725 14.381 49.8561 13.943C49.5641 13.4807 49.1505 13.1522 48.6152 12.9575C48.1041 12.7385 47.4715 12.629 46.7171 12.629C46.1575 12.629 45.5735 12.6655 44.9651 12.7385C44.3568 12.8115 43.9066 12.8723 43.6146 12.921V28.324H39.1982V9.855Z" />
    <path d="M77.8137 18.761C77.8137 20.2697 77.5947 21.6445 77.1567 22.8855C76.7187 24.1265 76.0982 25.185 75.2952 26.061C74.4922 26.937 73.5189 27.6183 72.3752 28.105C71.2559 28.5917 70.0149 28.835 68.6522 28.835C67.2896 28.835 66.0486 28.5917 64.9292 28.105C63.8099 27.6183 62.8487 26.937 62.0457 26.061C61.2427 25.185 60.6101 24.1265 60.1477 22.8855C59.7097 21.6445 59.4907 20.2697 59.4907 18.761C59.4907 17.2523 59.7097 15.8897 60.1477 14.673C60.6101 13.432 61.2427 12.3735 62.0457 11.4975C62.8731 10.6215 63.8464 9.95233 64.9657 9.49C66.0851 9.00333 67.3139 8.76 68.6522 8.76C69.9906 8.76 71.2194 9.00333 72.3387 9.49C73.4824 9.95233 74.4557 10.6215 75.2587 11.4975C76.0617 12.3735 76.6822 13.432 77.1202 14.673C77.5826 15.8897 77.8137 17.2523 77.8137 18.761ZM73.2877 18.761C73.2877 16.863 72.8741 15.3665 72.0467 14.2715C71.2437 13.1522 70.1122 12.5925 68.6522 12.5925C67.1922 12.5925 66.0486 13.1522 65.2212 14.2715C64.4182 15.3665 64.0167 16.863 64.0167 18.761C64.0167 20.6833 64.4182 22.2042 65.2212 23.3235C66.0486 24.4428 67.1922 25.0025 68.6522 25.0025C70.1122 25.0025 71.2437 24.4428 72.0467 23.3235C72.8741 22.2042 73.2877 20.6833 73.2877 18.761Z" />
    <path d="M95.0292 18.8705C95.0292 16.8995 94.579 15.3665 93.6787 14.2715C92.8027 13.1765 91.3792 12.629 89.4082 12.629C88.9945 12.629 88.5565 12.6533 88.0942 12.702C87.6562 12.7263 87.2182 12.7993 86.7802 12.921V23.8345C87.1695 24.1022 87.6805 24.3577 88.3132 24.601C88.9702 24.82 89.6637 24.9295 90.3937 24.9295C91.9997 24.9295 93.1677 24.382 93.8977 23.287C94.652 22.192 95.0292 20.7198 95.0292 18.8705ZM99.5552 18.7975C99.5552 20.2575 99.3605 21.5958 98.9712 22.8125C98.6062 24.0292 98.0708 25.0755 97.3652 25.9515C96.6595 26.8275 95.7713 27.5088 94.7007 27.9955C93.6543 28.4822 92.462 28.7255 91.1237 28.7255C90.2234 28.7255 89.396 28.616 88.6417 28.397C87.8873 28.178 87.2668 27.9347 86.7802 27.667V35.0765H82.3637V9.855C83.264 9.61167 84.3712 9.3805 85.6852 9.1615C86.9992 8.9425 88.3862 8.833 89.8462 8.833C91.3548 8.833 92.7054 9.06417 93.8977 9.5265C95.09 9.98883 96.0998 10.658 96.9272 11.534C97.7788 12.3857 98.4237 13.432 98.8617 14.673C99.324 15.8897 99.5552 17.2645 99.5552 18.7975Z" />
    <path d="M108.888 25.1485C110.056 25.1485 110.908 25.0147 111.443 24.747C111.979 24.455 112.246 23.9683 112.246 23.287C112.246 22.6543 111.954 22.1312 111.37 21.7175C110.811 21.3038 109.874 20.8537 108.56 20.367C107.757 20.075 107.015 19.7708 106.333 19.4545C105.676 19.1138 105.105 18.7245 104.618 18.2865C104.131 17.8485 103.742 17.3253 103.45 16.717C103.182 16.0843 103.048 15.3178 103.048 14.4175C103.048 12.6655 103.693 11.2907 104.983 10.293C106.273 9.271 108.025 8.76 110.239 8.76C111.358 8.76 112.429 8.8695 113.451 9.0885C114.473 9.28317 115.239 9.47783 115.75 9.6725L114.947 13.2495C114.461 13.0305 113.84 12.8358 113.086 12.6655C112.332 12.4708 111.456 12.3735 110.458 12.3735C109.558 12.3735 108.828 12.5317 108.268 12.848C107.708 13.14 107.428 13.6023 107.428 14.235C107.428 14.5513 107.477 14.8312 107.574 15.0745C107.696 15.3178 107.891 15.549 108.158 15.768C108.426 15.9627 108.779 16.1695 109.217 16.3885C109.655 16.5832 110.19 16.79 110.823 17.009C111.869 17.3983 112.757 17.7877 113.487 18.177C114.217 18.542 114.814 18.9678 115.276 19.4545C115.763 19.9168 116.115 20.4522 116.334 21.0605C116.553 21.6688 116.663 22.3988 116.663 23.2505C116.663 25.0755 115.982 26.4625 114.619 27.4115C113.281 28.3362 111.358 28.7985 108.852 28.7985C107.173 28.7985 105.822 28.6525 104.8 28.3605C103.778 28.0928 103.061 27.8738 102.647 27.7035L103.413 24.017C104.07 24.2847 104.849 24.5402 105.749 24.7835C106.674 25.0268 107.72 25.1485 108.888 25.1485Z" />
  </SvgIcon>
);

export default ClinopsIconNoDot;
