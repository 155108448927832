import { Fade } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { FC, useId } from 'react';

import { MaybeButtonBase } from '../../buttons';
import { ArrowDownIcon, ArrowUpIcon, IconName, icons } from '../../icons';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';

export interface CardRecommendationListItemProps {
  /**
   * The item's title.
   */
  title: string;
  /**
   * Icon for the item.
   */
  iconName: IconName;
  /**
   * Optional description. If provided, the item is expandable.
   */
  description?: string;
  /**
   * Indicates if the item is expanded.
   */
  isExpanded?: boolean;
  /**
   * Callback when the item is toggled.
   */
  onToggle?: () => void;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: theme.tokens.spacing['medium'],
  minHeight: 34,
}));

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: 28,
});

const CardRecommendationListItem: FC<CardRecommendationListItemProps> = ({
  title,
  iconName,
  description,
  isExpanded,
  onToggle,
}) => {
  const id = useId();
  const headerId = `card-recommendation-list-item__header-${id}`;
  const panelId = `card-recommendation-list-item__panel-${id}`;

  const IconComponent = icons[iconName];
  // Enable expanding only if there's a description
  const isExpandable = Boolean(description);
  const ExpandIconComponent = isExpanded ? ArrowUpIcon : ArrowDownIcon;

  return (
    <Root>
      <IconContainer>
        <IconComponent size="icon.xsmall" color="text.regular" />
      </IconContainer>
      <Stack direction="column" spacing="xsmall" flex={1}>
        <MaybeButtonBase
          id={headerId}
          onClick={isExpandable ? onToggle : undefined}
          disableHoverStyle
          aria-expanded={isExpandable ? isExpanded : undefined}
          aria-controls={isExpandable ? panelId : undefined}
        >
          <Typography variant="body.regular-condensed" lineClamp={2} sx={{ flex: 1 }}>
            {title}
          </Typography>
        </MaybeButtonBase>
        {isExpandable && (
          <Collapse in={isExpanded} timeout={300}>
            <Fade in={isExpanded} timeout={300}>
              <div id={panelId} role="region" aria-labelledby={headerId}>
                <Typography variant="body.small-condensed" sx={{ whiteSpace: 'pre-wrap' }}>
                  {description}
                </Typography>
              </div>
            </Fade>
          </Collapse>
        )}
      </Stack>
      {isExpandable && <ExpandIconComponent size="icon.medium-large" color="text.subtle" />}
    </Root>
  );
};

export default CardRecommendationListItem;
