import React from 'react';

import { MicroChartAreaSeries } from './MicroChartAreaSeries';
import { MicroChartLineSeries } from './MicroChartLineSeries';
import { MicroChartPathTweenOrigin } from './useMicroChartPathTween';

export interface MicroChartSeriesProps {
  type: MicroChartSeriesType;
  data: MicroChartSeriesDataPoint[];
  lineWidth?: number;
  lineOpacity?: number;
  lineStyle?: MicroChartSeriesLineStyle;
  color?: string;
  fillOpacity?: number;
  animationDuration?: number;
  animationEasing?: (normalizedTime: number) => number;
  tweenOrigin?: MicroChartPathTweenOrigin;
  gradientHeight?: string;
}

export type MicroChartSeriesDataPoint = number | null;

export enum MicroChartSeriesType {
  Area,
  Line,
}

export enum MicroChartSeriesLineStyle {
  Dashed,
  Solid,
}

export const MicroChartSeries: React.FC<MicroChartSeriesProps> = (props) => {
  const { type } = props;
  switch (type) {
    case MicroChartSeriesType.Area:
      return <MicroChartAreaSeries {...props} />;
    case MicroChartSeriesType.Line:
      return <MicroChartLineSeries {...props} />;
    default:
      return null;
  }
};
