import { introspectionResult } from '@aily/graphql-sdk/schema';
import { initSaaSApolloClient } from '@aily/saas-graphql-client';

import envConfig from '@/config/env';

initSaaSApolloClient(
  envConfig.API_URL,
  envConfig.API_URL,
  introspectionResult.possibleTypes,
  envConfig.ENV,
  envConfig.TENANT,
);
