import { styled } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

import { Typography } from '../../../../components';
import { fadeIn } from '../../../../styles';
import { withAnimatedTokens } from '../../hocs';
import { useTokenAnimation } from '../../providers';

const AnimatedTypography = withAnimatedTokens(Typography);

const StyledLi = styled('li')({
  opacity: 0,
  animationName: fadeIn,
  animationFillMode: 'forwards',
});

const AnimatedListItem: FC<{ children: ReactNode }> = ({ children }) => {
  const { duration, delayPerToken, getAndIncrement } = useTokenAnimation();
  const tokenIndex = getAndIncrement(1);
  return (
    <StyledLi
      style={{
        animationDelay: `${tokenIndex * delayPerToken}ms`,
        animationDuration: `${duration}ms`,
      }}
    >
      <AnimatedTypography variant="body.regular-condensed" sx={{ fontWeight: 400 }}>
        {children}
      </AnimatedTypography>
    </StyledLi>
  );
};

export default AnimatedListItem;
