import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const FinIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 35" {...props}>
      <path d="M10.7675 4.15205C10.242 4.08768 9.61144 4.02331 8.80571 4.02331C7.68469 4.02331 6.80889 4.34517 6.21335 4.9889C5.61781 5.63263 5.30252 6.53385 5.30252 7.69256V8.59379H10.3121C10.7325 8.59379 10.9777 8.7869 10.9777 9.20533V12.0699C10.9777 12.4562 10.7675 12.6815 10.3121 12.6815H5.33756V28.3885C5.33756 28.7747 5.12736 29 4.67195 29H0.958577C0.538195 29 0.292969 28.8069 0.292969 28.3885V7.69256C0.292969 5.21421 1.02864 3.31521 2.49998 1.99556C3.97131 0.675916 5.828 0 8.07004 0C9.15602 0 10.0318 0.0643729 10.6974 0.160932C11.1178 0.193119 11.293 0.386238 11.293 0.804662V3.54051C11.293 3.95893 11.1178 4.15205 10.7675 4.15205Z" />
      <path d="M16.4647 29H13.1519C12.7838 29 12.569 28.8045 12.569 28.3809V8.7C12.569 8.30899 12.7531 8.14607 13.1519 8.14607H16.4647C16.8328 8.14607 16.9862 8.34157 16.9862 8.7V28.4135C16.9862 28.8045 16.8022 29 16.4647 29ZM16.5875 0.749438C17.0476 1.2382 17.293 1.8573 17.293 2.63933C17.293 3.38876 17.0476 4.00787 16.5875 4.52921C16.1273 5.05056 15.5445 5.31124 14.8083 5.31124C14.1028 5.31124 13.4893 5.05056 13.0292 4.52921C12.5384 4.00787 12.293 3.38876 12.293 2.63933C12.293 1.88989 12.5384 1.27079 13.0292 0.749438C13.52 0.260674 14.1028 0 14.8083 0C15.5445 0 16.1273 0.260674 16.5875 0.749438Z" />
      <path d="M25.4063 28.9665H21.9182C21.5233 28.9665 21.293 28.7652 21.293 28.3293V9.34756C21.293 8.97866 21.4575 8.7439 21.7537 8.60976C24.1888 7.53658 26.8871 7 29.8487 7C32.8103 7 35.1138 7.7378 36.7921 9.24695C38.4703 10.7561 39.293 13.1037 39.293 16.2896V28.3628C39.293 28.7652 39.0955 29 38.7336 29H35.1796C34.7847 29 34.5544 28.7988 34.5544 28.3628V16.3567C34.5544 14.5122 34.1595 13.2043 33.3698 12.4329C32.58 11.6616 31.3953 11.2927 29.8158 11.2927C28.4008 11.2927 27.1175 11.4604 25.9986 11.7622V28.3628C26.0315 28.7652 25.8341 28.9665 25.4063 28.9665Z" />
    </SvgIcon>
  );
};

export default FinIconNoDot;
