import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const HrIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 34 35" {...props}>
      <path d="M0.512939 28.7681V0.741444L4.99868 0V9.60171C5.49298 9.42871 6.06142 9.28042 6.70401 9.15684C7.37131 9.03327 8.02625 8.97148 8.66883 8.97148C10.2259 8.97148 11.511 9.19392 12.5243 9.63878C13.5624 10.0589 14.3903 10.6644 15.0082 11.4553C15.6261 12.2215 16.0586 13.1483 16.3057 14.2357C16.5776 15.3232 16.7135 16.5342 16.7135 17.8688V28.7681H12.2278V18.5732C12.2278 17.5352 12.1536 16.6578 12.0053 15.9411C11.8818 15.1996 11.6593 14.6065 11.338 14.1616C11.0415 13.692 10.6337 13.3584 10.1147 13.1606C9.59564 12.9382 8.95305 12.827 8.18689 12.827C7.59374 12.827 6.98823 12.8888 6.37035 13.0124C5.75248 13.1359 5.29526 13.2471 4.99868 13.346V28.7681H0.512939Z" />
      <path d="M33.1377 13.4202C32.7669 13.2966 32.2479 13.173 31.5806 13.0494C30.9381 12.9011 30.1843 12.827 29.3192 12.827C28.8249 12.827 28.2936 12.8764 27.7251 12.9753C27.1814 13.0741 26.7983 13.1606 26.5759 13.2348V28.7681H22.0901V10.3061C22.9552 9.98479 24.0303 9.68821 25.3154 9.41635C26.6253 9.11977 28.0711 8.97148 29.6529 8.97148C29.9495 8.97148 30.2955 8.9962 30.6909 9.04563C31.0863 9.07034 31.4818 9.11977 31.8772 9.19392C32.2727 9.24335 32.6557 9.31749 33.0265 9.41635C33.3972 9.49049 33.6938 9.56464 33.9162 9.63878L33.1377 13.4202Z" />
    </SvgIcon>
  );
};

export default HrIconNoDot;
