import { EventTrackingConfig, getPathDetails, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import { UserSetting } from '@aily/graphql-sdk/schema';
import { Stack } from '@mui/material';
import { MarkdownToJSX } from 'markdown-to-jsx';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useMetadata } from '../../hooks';
import { FooterMetaBar } from '../FooterMetaBar';
import { FooterToolbar, FooterToolbarProps } from '../FooterToolbar';
import { InfoButton } from '../InfoButton';
import { LastUpdateDate } from '../LastUpdateDate';
import { TableDataViewAbstractType } from '../TableDataView';
import { UserSettingsBar } from '../UserSettings';
import { FooterToolbarDialog } from './FooterToolbarDialog';

export type DataViewAbstractType = {
  lastUpdateDate?: string | null;
  metadata?: T.MetaData[] | null;
  availableUserSettings?: T.UserSetting[] | null;
};

export interface DataViewFooterToolbarProps extends Omit<FooterToolbarProps, 'children'> {
  dataView?: DataViewAbstractType;
  onInfoButtonClick?: (path?: string) => void;
  onUserSettingChange?: (setting: UserSetting) => void;
  onUserSettingChangeCommitted?: (setting: UserSetting) => void;
  onInfoContentRender?: () => React.ReactElement;
  markdownOptions?: MarkdownToJSX.Options;
  renderBeforeContent?: (dataView?: TableDataViewAbstractType) => React.ReactNode;
}

type ModalProps = {
  content?: string;
  onOk?: () => void;
  onInfoContentRender?: () => React.ReactElement;
};

const DataViewFooterToolbar: React.FC<DataViewFooterToolbarProps> = ({
  dataView,
  onInfoButtonClick,
  onUserSettingChange,
  onUserSettingChangeCommitted,
  onInfoContentRender,
  markdownOptions,
  renderBeforeContent,
  ...rest
}) => {
  const location = useLocation();
  const metadata = useMetadata(dataView?.metadata);
  const infoMessage = metadata.getItem('infoMessage');
  const [modalProps, setModalProps] = useState<ModalProps>();

  const handleInfoButtonClick = useCallback(() => {
    if (infoMessage?.key && infoMessage?.value) {
      const { value } = infoMessage;
      setModalProps({ content: value, onOk: () => setModalProps(undefined) });
    }

    if (onInfoContentRender && !infoMessage?.value) {
      setModalProps({
        content: undefined,
        onOk: () => setModalProps(undefined),
        onInfoContentRender,
      });
    }

    onInfoButtonClick?.(location.pathname);
  }, [infoMessage, onInfoButtonClick, onInfoContentRender, location.pathname]);

  return (
    <>
      <FooterToolbar {...rest}>
        <Stack direction="row" spacing={2} flexGrow={1}>
          <FooterMetaBar>
            {renderBeforeContent?.(dataView)}
            {dataView?.availableUserSettings?.some(({ options }) => !!options.length) && (
              <UserSettingsBar
                userSettings={dataView?.availableUserSettings}
                onSettingChange={onUserSettingChange}
                onSettingChangeCommitted={onUserSettingChangeCommitted}
              />
            )}
          </FooterMetaBar>
        </Stack>
        <Stack direction="row" spacing={2}>
          {!!dataView?.lastUpdateDate && <LastUpdateDate>{dataView.lastUpdateDate}</LastUpdateDate>}
          {!!infoMessage?.key && <InfoButton onClick={handleInfoButtonClick} />}
        </Stack>
      </FooterToolbar>
      {modalProps && <FooterToolbarDialog markdownOptions={markdownOptions} {...modalProps} />}
    </>
  );
};

const trackingConfig: EventTrackingConfig<DataViewFooterToolbarProps> = {
  onInfoButtonClick: {
    eventName: 'priority.clicked',
    getEventProps: (path) => {
      const { focusArea, focusAreaPath, pageName, moduleName } = getPathDetails(path ?? '');
      // Only Tracking for gtm modules
      if (moduleName !== 'gtm') return undefined;
      return {
        component: 'priority',
        component_value: 'info',
        name: pageName,
        intent: 'click',
        item_type: 'button',
        event_version: '2.0.0',
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
};

const TrackedDataViewFooterToolbar = withEventTracking(DataViewFooterToolbar, trackingConfig);

export { DataViewFooterToolbar, TrackedDataViewFooterToolbar };
