import { styled, useTheme } from '@mui/material/styles';
import { Transition } from 'framer-motion';
import { FC } from 'react';

import { ButtonBase } from '../../../buttons';
import { CircularProgressBar } from '../../../charts';
import { PlayIcon } from '../../../icons';
import ArrowLeftIcon from './ArrowLeftIcon';
import ArrowRightIcon from './ArrowRightIcon';
import PauseIcon from './PauseIcon';

export interface FooterControlsProps {
  /**
   * Whether media is currently playing.
   */
  isPlaying?: boolean;
  /**
   * Current playback progress (0–1).
   */
  playbackProgress?: number;

  /**
   * Optional transition for progress animation.
   */
  progressTransition?: Transition;

  /**
   * Whether the "Previous" button is disabled.
   */
  prevButtonDisabled?: boolean;
  /**
   * Whether the "Next" button is disabled.
   */
  nextButtonDisabled?: boolean;
  /**
   * Called when the "Play/Pause" button is clicked.
   */
  onPlayButtonClick?: () => void;
  /**
   * Called when the "Previous" button is clicked.
   */
  onPrevButtonClick?: () => void;
  /**
   * Called when the "Next" button is clicked.
   */
  onNextButtonClick?: () => void;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['medium'],
}));

const PlayButtonWrapper = styled('div')({
  position: 'relative',
});

const PlayButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 44,
  height: 44,
  borderRadius: 44,
  border: '2px solid rgba(255, 255, 255, 0.2)',
  backgroundColor: theme.tokens.color['background.light'],
  color: theme.tokens.color['text.regular'],
}));

const ProgressWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
});

const NavigationButton = styled(ButtonBase)<{ $disabled?: boolean }>(({ theme, $disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  height: 34,
  borderRadius: 34,
  backgroundColor: theme.tokens.color['background.light'],
  color: theme.tokens.color[$disabled ? 'text.disabled' : 'text.regular'],
}));

const FooterControls: FC<FooterControlsProps> = ({
  isPlaying,
  playbackProgress,
  progressTransition,
  prevButtonDisabled,
  nextButtonDisabled,
  onPlayButtonClick,
  onPrevButtonClick,
  onNextButtonClick,
}) => {
  const theme = useTheme();
  return (
    <Root>
      <NavigationButton disabled={prevButtonDisabled} onClick={onPrevButtonClick}>
        <ArrowLeftIcon />
      </NavigationButton>
      <PlayButtonWrapper>
        <PlayButton onClick={onPlayButtonClick}>
          {isPlaying ? <PauseIcon /> : <PlayIcon size="icon.xxsmall" />}
        </PlayButton>
        {!!playbackProgress && (
          <ProgressWrapper>
            <CircularProgressBar
              value={playbackProgress}
              transition={progressTransition}
              size={44}
              strokeWidth={2}
              progressColor={theme.tokens.color['gradient.mint.vertical-inverse']}
            />
          </ProgressWrapper>
        )}
      </PlayButtonWrapper>
      <NavigationButton disabled={nextButtonDisabled} onClick={onNextButtonClick}>
        <ArrowRightIcon />
      </NavigationButton>
    </Root>
  );
};

export default FooterControls;
