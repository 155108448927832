import { styled } from '@mui/material/styles';
import React from 'react';

import { pulse } from '../../../../styles';

const PulsingDotRoot = styled('div')(({ theme }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: theme.tokens.color['text.regular'],
  animation: `${pulse} 1.5s infinite ease-in-out`,
}));

const PulsingDot: React.FC = () => <PulsingDotRoot />;

export default PulsingDot;
