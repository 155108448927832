import React from 'react';

import AiIcon from './AiIcon';
import AlertIcon from './AlertIcon';
import ArrowDownIcon from './ArrowDownIcon';
import ArrowLeftIcon from './ArrowLeftIcon';
import ArrowRightIcon from './ArrowRightIcon';
import ArrowUpAltIcon from './ArrowUpAltIcon';
import ArrowUpIcon from './ArrowUpIcon';
import BrainIcon from './BrainIcon';
import CheckIcon from './CheckIcon';
import ChevronDownIcon from './ChevronDownIcon';
import ChevronRightIcon from './ChevronRightIcon';
import ChevronUpIcon from './ChevronUpIcon';
import CloseIcon from './CloseIcon';
import CommunicationIcon from './CommunicationIcon';
import CorrelatorIcon from './CorrelatorIcon';
import CustomerIcon from './CustomerIcon';
import DateProIcon from './DateProIcon';
import DistributionIcon from './DistributionIcon';
import EditIcon from './EditIcon';
import ExclamationCircleIcon from './ExclamationCircleIcon';
import FlashIcon from './FlashIcon';
import InfoIcon from './InfoIcon';
import LocationIcon from './LocationIcon';
import LockOpenIcon from './LockOpenIcon';
import ManufacturingIcon from './ManufacturingIcon';
import MinusCircleIcon from './MinusCircleIcon';
import MinusIcon from './MinusIcon';
import NextIcon from './NextIcon';
import OpenInNewIcon from './OpenInNewIcon';
import PauseIcon from './PauseIcon';
import PersonOutIcon from './PersonOutIcon';
import PinIcon from './PinIcon';
import PlaiIcon from './PlaiIcon';
import PlayIcon from './PlayIcon';
import PlusCircleIcon from './PlusCircleIcon';
import PlusIcon from './PlusIcon';
import PrevIcon from './PrevIcon';
import ProductionIcon from './ProductionIcon';
import RawMaterialsIcon from './RawMaterialsIcon';
import ResetIcon from './ResetIcon';
import SadFaceIcon from './SadFaceIcon';
import SearchIcon from './SearchIcon';
import SendIcon from './SendIcon';
import ShareIcon from './ShareIcon';
import StarIcon from './StarIcon';
import { SvgIconProps } from './SvgIcon';
import TrendArrowDownThickIcon from './TrendArrowDownThickIcon';
import TrendArrowUpThickIcon from './TrendArrowUpThickIcon';
import TrendDownIcon from './TrendDownIcon';
import TrendUpIcon from './TrendUpIcon';
import WifiOffIcon from './WifiOffIcon';

export const icons = {
  ai: AiIcon,
  alert: AlertIcon,
  arrowDown: ArrowDownIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowUpAlt: ArrowUpAltIcon,
  arrowUp: ArrowUpIcon,
  brain: BrainIcon,
  check: CheckIcon,
  chevronDown: ChevronDownIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  close: CloseIcon,
  communication: CommunicationIcon,
  correlator: CorrelatorIcon,
  customer: CustomerIcon,
  datePro: DateProIcon,
  distribution: DistributionIcon,
  edit: EditIcon,
  exclamationCircle: ExclamationCircleIcon,
  flash: FlashIcon,
  info: InfoIcon,
  locationIcon: LocationIcon,
  lockOpen: LockOpenIcon,
  manufacturing: ManufacturingIcon,
  minusCircle: MinusCircleIcon,
  minus: MinusIcon,
  next: NextIcon,
  openInNew: OpenInNewIcon,
  pause: PauseIcon,
  peopleOut: PersonOutIcon,
  pin: PinIcon,
  plai: PlaiIcon,
  play: PlayIcon,
  plus: PlusIcon,
  plusCircle: PlusCircleIcon,
  prev: PrevIcon,
  production: ProductionIcon,
  rawMaterials: RawMaterialsIcon,
  reset: ResetIcon,
  sadFace: SadFaceIcon,
  search: SearchIcon,
  send: SendIcon,
  share: ShareIcon,
  star: StarIcon,
  trendArrowDownThick: TrendArrowDownThickIcon,
  trendArrowUpThick: TrendArrowUpThickIcon,
  trendDown: TrendDownIcon,
  trendUp: TrendUpIcon,
  wifiOff: WifiOffIcon,
} satisfies Record<string, React.ComponentType<SvgIconProps>>;

export type IconName = keyof typeof icons;
