import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PauseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M6.13538 20H10.4687V4H6.13538V20ZM14.802 4V20H19.1354V4H14.802Z" />
  </SvgIcon>
);

export default PauseIcon;
