import { styled } from '@mui/material/styles';
import { AnimatePresence, motion, Transition, Variants } from 'framer-motion';
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import { Button, Stack } from '../../../../components';
import { scrollbarStyles } from '../../../../utils';
import { InputBox } from '../InputBox';
import { MarkdownRenderer } from '../MarkdownRenderer';
import { PulsingDot } from '../PulsingDot';

/**
 * ChatProps defines the properties for the Chat component.
 */
export interface ChatProps {
  /**
   * The initial bot message to display.
   */
  introMessage: string;
  /**
   * The response message from the bot after a user sends a message.
   */
  responseMessage?: string;
  /**
   * The message shown for result refinement (e.g. suggestions to edit).
   */
  refinementMessage?: string;
  /**
   * Callback when the Send button is clicked.
   */
  onSendButtonClick?: () => void;
  /**
   * Callback when the Edit button is clicked.
   */
  onEditButtonClick?: () => void;
  /**
   * Callback when the Save Result button is clicked.
   */
  onSaveButtonClick?: () => void;
  /**
   * The current mode of the chat ("standby", "loading", or "refinement").
   */
  mode?: ChatMode;
  /**
   * The current value of the user input.
   */
  inputValue?: string;
  /**
   * The original input value captured when sending the message.
   */
  defaultInputValue?: string;
  /**
   * Placeholder text for the input field.
   */
  inputPlaceholder?: string;
  /**
   * Callback when the input value changes.
   */
  onInputChange?: (value: string) => void;
}

export type ChatMode = 'standby' | 'loading' | 'refinement';

const ChatRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['medium'],
  height: '100%',
}));

const ChatContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  ...scrollbarStyles(theme.tokens.color['text.subtle'], theme.tokens.color['background.regular']),
}));

const RefinementBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.tokens.borderRadius['medium'],
  padding: theme.spacing(theme.tokens.spacing['small'], theme.tokens.spacing['medium']),
  backgroundColor: theme.tokens.color['background.light'],
  backdropFilter: 'blur(50px)',
}));

const PulsingDotBox = styled('div')(({ theme }) => ({
  padding: theme.tokens.spacing['xsmall'],
}));

const ChatFooter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

// Define a common motion transition and variants.
const transition: Transition = { duration: 0.15 };
const motionVariants: Variants = {
  initial: { opacity: 0, transition },
  animate: { opacity: 1, transition },
  exit: { opacity: 0, transition },
};

const MotionWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <motion.div variants={motionVariants} initial="initial" animate="animate" exit="exit">
    {children}
  </motion.div>
);

const Chat: FC<ChatProps> = ({
  introMessage,
  responseMessage,
  refinementMessage,
  onSendButtonClick,
  onEditButtonClick,
  onSaveButtonClick,
  mode = 'standby',
  inputValue,
  defaultInputValue,
  inputPlaceholder,
  onInputChange,
}) => {
  // Determine the message to render.
  const message = responseMessage || introMessage;

  // Memoize the rendered markdown to avoid reanimation on input changes.
  const renderedMarkdown = useMemo(() => <MarkdownRenderer>{message}</MarkdownRenderer>, [message]);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  // Focus the input and move the cursor to the end when returning to edit mode.
  useEffect(() => {
    if (mode === 'standby' && inputRef.current) {
      const len = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(len, len);
    }
  }, [mode]);

  return (
    <ChatRoot>
      <ChatContent>
        {mode === 'loading' ? (
          <PulsingDotBox role="status">
            <PulsingDot />
          </PulsingDotBox>
        ) : (
          renderedMarkdown
        )}
      </ChatContent>
      <ChatFooter>
        <AnimatePresence mode="wait">
          {(mode === 'standby' || mode === 'loading') && (
            <MotionWrapper key="input">
              <InputBox
                value={inputValue}
                defaultValue={defaultInputValue}
                placeholder={inputPlaceholder}
                onChange={onInputChange}
                onSendButtonClick={onSendButtonClick}
                disabled={mode === 'loading'}
                inputRef={inputRef}
              />
            </MotionWrapper>
          )}
          {mode === 'refinement' && (
            <MotionWrapper key="refinement">
              <Stack direction="column" spacing="xlarge">
                <RefinementBox>{refinementMessage}</RefinementBox>
                <Stack direction="row" spacing="medium" justifyContent="center">
                  <Button variant="secondary" onClick={onEditButtonClick}>
                    Edit
                  </Button>
                  <Button variant="primary" onClick={onSaveButtonClick}>
                    Save Result
                  </Button>
                </Stack>
              </Stack>
            </MotionWrapper>
          )}
        </AnimatePresence>
      </ChatFooter>
    </ChatRoot>
  );
};

export default Chat;
