import './services/authService';
import './services/monitoringService';

import { useAnalyticsIdentifyUser, useAnalyticsTrackPage } from '@aily/analytics-service';
import { useAuth, useAutoSignIn } from '@aily/auth-service';
import { ApplyLastVisitedState } from '@aily/last-visited-state';
import { useSentryRouteTags } from '@aily/monitoring-service';
import { useCacheInvalidation } from '@aily/saas-graphql-client';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AppRouter from '@/components/AppRouter';
import LaunchScreen from '@/components/LaunchScreen';
import ModalRoot from '@/components/ModalRoot';
import PageLayout from '@/components/PageLayout';
import { useAppStartedDispatcher } from '@/hooks/useAppStartedDispatcher';
import { useCaptureAuthError } from '@/hooks/useCaptureAuthError';
import { useModuleChangedDispatcher } from '@/hooks/useModuleChangedDispatcher';
import { useInterceptions } from '@/providers/InterceptionsProvider';
import { getAppIsInitialized } from '@/store/app/slices';

const refreshAppIfCacheWasInvalidated = (version: string | null) => {
  if (version !== null) {
    window.location.reload();
  }
};

function App() {
  const { isAuthenticated, error } = useAuth();
  const { wasSubscriptionCalled } = useInterceptions();
  const appIsInitialized = useSelector(getAppIsInitialized) && wasSubscriptionCalled;
  const { pathname } = useLocation();

  useAutoSignIn();
  useAnalyticsIdentifyUser();
  useAnalyticsTrackPage();
  useCaptureAuthError();
  useAppStartedDispatcher();
  useModuleChangedDispatcher();
  useSentryRouteTags(pathname);
  useCacheInvalidation({
    onSiteConfigurationVersionChange: refreshAppIfCacheWasInvalidated,
    onUserRolesChange: refreshAppIfCacheWasInvalidated,
    skip: !isAuthenticated,
  });

  return (
    <>
      <ModalRoot />
      <AnimatePresence>
        {!appIsInitialized && (
          <LaunchScreen
            error={error}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      {appIsInitialized && (
        <PageLayout>
          <AppRouter />
          <ApplyLastVisitedState />
        </PageLayout>
      )}
    </>
  );
}

export default App;
