import { styled } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import { FC, Fragment } from 'react';

import { ToggleFilter } from '../ToggleFilter';
import { FilterRowOption, FilterRowParentOption, FilterRowSelection } from './FilterRow';
import { getChildValue, getParentValue, getVisibleSubOptions, sortSubOptions } from './utils';

export interface ParentOptionProps {
  option: FilterRowParentOption;
  currentValue: FilterRowSelection;
  editMode: boolean;
  visibleSubOptionsOnEdit: boolean;
  onParentToggle: (option: FilterRowParentOption) => void;
  onChildToggle: (option: FilterRowOption, parent: FilterRowParentOption) => void;
  onRemove?: (option: FilterRowParentOption) => void;
}

const MotionWrapper = styled(motion.div)({});

const motionProps: MotionProps = {
  layout: true,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3, ease: 'easeInOut' },
};

const ParentOption: FC<ParentOptionProps> = ({
  option,
  currentValue,
  editMode,
  visibleSubOptionsOnEdit,
  onParentToggle,
  onChildToggle,
  onRemove,
}) => {
  const { value, label, options } = option;
  const isParentSelected = getParentValue(currentValue) === value;

  // Sort and filter sub-options.
  const sortedSubOptions = sortSubOptions(options, currentValue);

  const visibleSubOptions = getVisibleSubOptions(
    sortedSubOptions,
    editMode,
    currentValue,
    visibleSubOptionsOnEdit,
  );

  return (
    <Fragment key={value}>
      {/* Parent Toggle */}
      <MotionWrapper
        {...motionProps}
        style={isParentSelected ? { zIndex: 3 } : {}}
        role="presentation"
      >
        <ToggleFilter
          selected={isParentSelected}
          removable={editMode}
          onClick={() => onParentToggle(option)}
          onRemoveButtonClick={() => onRemove?.(option)}
        >
          {label}
        </ToggleFilter>
      </MotionWrapper>
      {/* Child Toggles */}
      {isParentSelected &&
        visibleSubOptions.map((subOption) => {
          const { value: subValue, label: subLabel } = subOption;
          const isChildSelected = getChildValue(currentValue) === subValue;
          return (
            <MotionWrapper
              key={subValue}
              {...motionProps}
              style={isChildSelected ? { zIndex: 2 } : {}}
              role="presentation"
            >
              <ToggleFilter
                child
                selected={isChildSelected}
                onClick={() => onChildToggle(subOption, option)}
                onRemoveButtonClick={() => onRemove?.(option)}
              >
                {subLabel}
              </ToggleFilter>
            </MotionWrapper>
          );
        })}
    </Fragment>
  );
};

export default ParentOption;
