import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const PfIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 34 35" {...props}>
    <path d="M13.1491 18.8293C13.1491 16.8626 12.6922 15.333 11.7783 14.2404C10.8891 13.1478 9.44417 12.6015 7.4435 12.6015C7.02361 12.6015 6.57901 12.6257 6.10972 12.6743C5.66513 12.6986 5.22054 12.7714 4.77594 12.8928V23.7825C5.17114 24.0496 5.68983 24.3045 6.33202 24.5473C6.99891 24.7659 7.70285 24.8751 8.44383 24.8751C10.074 24.8751 11.2596 24.3288 12.0006 23.2362C12.7663 22.1436 13.1491 20.6746 13.1491 18.8293ZM17.7432 18.7565C17.7432 20.2133 17.5456 21.5487 17.1504 22.7627C16.7799 23.9768 16.2366 25.0208 15.5203 25.8949C14.804 26.769 13.9024 27.4488 12.8157 27.9344C11.7536 28.4201 10.5433 28.6629 9.18482 28.6629C8.27093 28.6629 7.43115 28.5536 6.66546 28.3351C5.89977 28.1165 5.26994 27.8737 4.77594 27.6067V35H0.292969V9.83351C1.20685 9.5907 2.33068 9.36004 3.66446 9.14152C4.99824 8.923 6.40612 8.81374 7.88809 8.81374C9.41947 8.81374 10.7903 9.0444 12.0006 9.50572C13.2109 9.96705 14.2359 10.6348 15.0757 11.5088C15.9402 12.3587 16.5947 13.4027 17.0393 14.641C17.5086 15.855 17.7432 17.2269 17.7432 18.7565Z" />
    <path d="M28.7729 0C29.8103 0 30.7242 0.0971214 31.5146 0.291364C32.305 0.461325 32.8978 0.619146 33.293 0.764827L32.4408 4.40687C32.0209 4.21263 31.5269 4.0548 30.9589 3.9334C30.4155 3.812 29.8227 3.7513 29.1805 3.7513C28.4642 3.7513 27.8591 3.86056 27.3651 4.07908C26.8711 4.27333 26.4759 4.55255 26.1795 4.91675C25.8831 5.28096 25.6731 5.73014 25.5496 6.26431C25.4261 6.77419 25.3644 7.33264 25.3644 7.93965V9.21436H32.4038V12.8928H25.3644V28.2622H20.8814V7.86681C20.8814 5.4145 21.536 3.49636 22.845 2.11238C24.1541 0.704127 26.1301 0 28.7729 0Z" />
  </SvgIcon>
);

export default PfIconNoDot;
