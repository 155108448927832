import { ColorTokenKey } from '@aily/ui-theme';

import { SvgIconProps } from '../icons/SvgIcon';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as dotIcons from './dot';
import * as icons from './no-dot';

export interface ModuleIconProps extends SvgIconProps {
  moduleCode: ModuleCode;
  color?: ModuleIconColor;
  showDot?: boolean;
}

export type ModuleCode =
  | 'pro'
  | 'qa'
  | 'rnd'
  | 'spend'
  | 'sup'
  | 'supply'
  | 'cmc'
  | 'crm'
  | 'ebi'
  | 'fin'
  | 'gra'
  | 'gtm'
  | 'hr'
  | 'mns'
  | 'pf'
  | 'ppl'
  | 'clinops'
  | 'demo'
  | 'unknown';

export type ModuleIconSize = 'default' | 'small';

export type ModuleIconColor = Extract<ColorTokenKey, 'text.regular' | 'brand.aqua'>;

const ModuleIcon: React.FC<ModuleIconProps> = ({
  moduleCode,
  color = 'text.regular',
  showDot = false,
  ...rest
}) => {
  if (moduleCode === 'unknown' || moduleCode === 'demo') {
    return null;
  }

  const Icon = showDot ? dotIcons[moduleCode] : icons[moduleCode];

  return <Icon color={color} {...rest} />;
};

export default ModuleIcon;
