import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { useRecommenderCardContext } from './RecommenderCardContext';

export interface RecommenderCardSubContentProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const RecommenderCardSubContentRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['xxsmall'],
  minHeight: theme.tokens.sizing['icon.xxsmall'],
}));

const RecommenderCardSubContent: React.FC<RecommenderCardSubContentProps> = ({
  children,
  ...rest
}) => {
  const context = useRecommenderCardContext();
  const cardHasCheckedMark = context?.hasCheckedMark;
  const cardSize = context?.size;

  const getMaxWidth = () => {
    if (!cardHasCheckedMark) {
      return '100%';
    }
    // The subcontent cannot overlap with the checked mark
    return cardSize === 'large' ? '88%' : '92%';
  };

  return (
    <RecommenderCardSubContentRoot {...rest} sx={{ maxWidth: getMaxWidth() }}>
      {children}
    </RecommenderCardSubContentRoot>
  );
};

export default RecommenderCardSubContent;
