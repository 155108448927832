import { DotNavigation, Typography } from '@aily/ui-components';
import { Box, IconButton, Modal, styled } from '@mui/material';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React, { useCallback } from 'react';

import useCarouselState from '../../../../components/Carousel/useCarouselState';
import { Close } from '../../../../icons';
import { colors } from '../../../../theme/default/colors';

const ModalContentBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  gap: theme.spacing(2),
  width: '50%',
  padding: theme.spacing(5),
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
}));

const Header = styled(Box)(({ justifyContent }: { justifyContent: string }) => ({
  display: 'flex',
  justifyContent: justifyContent,
  alignItems: 'center',
}));

const Container = styled(Box)(() => ({
  position: 'relative',
  margin: '0 auto',
  width: '100%',
}));

const transition = 'all 400ms cubic-bezier(0.645, 0.045, 0.355, 1)';

const StyledSlider = styled(Slider)(() => ({
  position: 'relative',
  outline: 'none',
  width: '100%',
  overflow: 'hidden',
  '& .carousel__slider-tray--horizontal': {
    transition,
    transitionProperty: 'transform',
    willChange: 'transform',
  },
}));

const StyledSlide = styled(Slide)(({ theme }) => ({
  position: 'relative',
  float: 'left',
  opacity: 1,
  display: 'flex',
  width: '100%',
  transition,
  transitionProperty: 'opacity, transform',
  willChange: 'transform',
  marginBottom: theme.spacing(2),
  '& .carousel__inner-slide': {
    transition,
    transitionProperty: 'left',
    willChange: 'left',
    width: '100%',
  },
  '&.carousel__slide--hidden': {
    opacity: 0,
  },
}));

interface AgentModalCarouselProps {
  openModal: boolean;
  onCloseModal: () => void;
  title?: string;
  slides?: React.ReactNode[];
}

export const AgentModalCarousel: React.FC<AgentModalCarouselProps> = ({
  openModal,
  onCloseModal,
  title,
  slides,
}) => (
  <Modal open={openModal} onClose={onCloseModal}>
    <ModalContentBox>
      <Header justifyContent={title ? 'space-between' : 'flex-end'}>
        {title && <Typography variant="heading.H5">{title}</Typography>}
        <IconButton sx={{ color: colors.neutrals.grey2 }} onClick={onCloseModal}>
          <Close />
        </IconButton>
      </Header>
      <CarouselProvider
        dragEnabled={true}
        touchEnabled={true}
        naturalSlideWidth={0}
        naturalSlideHeight={0}
        totalSlides={slides?.length ?? 0}
        visibleSlides={1}
      >
        <CarouselContent slides={slides} />
      </CarouselProvider>
    </ModalContentBox>
  </Modal>
);

const CarouselContent: React.FC<{ slides?: React.ReactNode[] }> = ({ slides }) => {
  const { currentPage, totalPages, visibleSlides, carouselContext } = useCarouselState();

  const handleItemClick = useCallback(
    (dotIndex: number) => {
      carouselContext.setStoreState({ currentSlide: dotIndex * visibleSlides });
    },
    [carouselContext, visibleSlides],
  );

  return (
    <Container>
      <StyledSlider>
        {slides?.map((slide, index) => (
          <StyledSlide key={index} index={index}>
            {slide}
          </StyledSlide>
        ))}
      </StyledSlider>
      <DotNavigation count={totalPages} onIndexChanged={handleItemClick} index={currentPage} />
    </Container>
  );
};
