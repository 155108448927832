import * as T from '@aily/graphql-sdk/schema';
import { ChevronDown, ChevronUp, NestedList, SearchBar } from '@aily/saas-core';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  PopoverProps,
  Skeleton,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import { DimensionFilter, FilterOption, isTreeFilterOptionResult } from './types';
import { resolveFilterOptionSublabel } from './utils';

const iconSize = 28;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main} !important`,
  borderRadius: '10px',
  marginBottom: theme.spacing(1.2),
  '& .MuiListItemButton-root': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '& .MuiListItemButton-root > svg': {
    color: theme.palette.text.secondary,
  },
  '& .MuiListItemButton-root .MuiTypography-body': {
    fontWeight: 600,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  '& .MuiSvgIcon-root': {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: iconSize,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.25),
  color: theme.palette.text.secondary,
}));

const StyledPopover = styled(
  React.forwardRef((props: PopoverProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{ paper: { ref } }}
      {...props}
    />
  )),
)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: 375,
    maxHeight: 574,
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  '.divider': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const GLOCAL_PULSE_PATH = 'glocal';
const CMO_PULSE_PATH = 'cmo';

const pulsePaths = [GLOCAL_PULSE_PATH, CMO_PULSE_PATH];

export interface PrioritySelectorProps {
  filterOptions: FilterOption[];
  currentItem?: FilterOption;
  onPriorityFilterSelect?: (priorityFilterOption: FilterOption) => void;
  dimensionFilterOptions: FilterOption[];
  currentDimensionItems?: FilterOption[];
  onDimensionFilterSelect?: (dimensionFilterOption: FilterOption) => void;
  loading?: boolean;
  userPrioritiesFilterOptions?: T.FilterOptionResult[];
  style?: CSSProperties;
  dimensionFilter?: DimensionFilter;
  showMultiDimensionFilter?: boolean;
}

export const PrioritySelector: React.FC<PrioritySelectorProps> = ({
  filterOptions,
  currentItem,
  onPriorityFilterSelect,
  dimensionFilterOptions,
  currentDimensionItems,
  onDimensionFilterSelect,
  loading,
  userPrioritiesFilterOptions,
  dimensionFilter,
  showMultiDimensionFilter = false,
}) => {
  const [tabIndex, setTabIndex] = useState(userPrioritiesFilterOptions?.length ? 0 : 1);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [search, setSearch] = useState('');

  const { pathname } = useLocation();

  const showGtmDimensionFilter = !!pathname.includes('/gtm/priorities');
  const dimensionLabel = currentDimensionItems?.map((item) => item.label).join(' \u2022 ');
  const popoverRef = useRef<HTMLDivElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<T.TreeFilterOptionResult[]>([]);

  if (popoverRef.current && isSafari) {
    popoverRef.current.style.transform = 'translateZ(1)';
    popoverRef.current.style.transform = 'translateZ(0)';
  }

  const handleTabChange = useCallback((event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  }, []);

  const handleClick = useCallback((event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
    setSearch('');
  }, []);

  const handlePriorityFilterSelect = useCallback(
    (filterOption: FilterOption) => {
      handleClose();
      onPriorityFilterSelect?.(filterOption);
    },
    [onPriorityFilterSelect, handleClose],
  );

  const applyUniqueFilters = useCallback(
    (prevSelectedFilters: any[], currentDimensionItems: any[]) => {
      // Set to track optionGroupCodes that should be removed
      const removedOptionGroupCodes = new Set<string>();

      //Get top level items ad default
      const defaultIds = dimensionFilter?.options?.map(({ id }) => id);

      // Filter out default dimension filters and collect their optionGroupCodes
      const filteredNewItems = currentDimensionItems.filter((item) => {
        const isDefaultFilter = defaultIds?.includes(item.id);

        if (isDefaultFilter) {
          removedOptionGroupCodes.add(item.optionGroupCode);
        }

        return !isDefaultFilter;
      });

      // Remove any previously selected filters that have the same optionGroupCode as removed filters
      const updatedFilters = prevSelectedFilters.filter(
        (filter: any) => !removedOptionGroupCodes.has(filter.optionGroupCode),
      );

      // Update or add new filter items to the updatedFilters
      filteredNewItems.forEach((newItem) => {
        const existingItemIndex = updatedFilters.findIndex(
          (filter) => filter.optionGroupCode === newItem.optionGroupCode,
        );

        if (existingItemIndex !== -1) {
          // Replace existing item with the new one
          updatedFilters[existingItemIndex] = newItem;
        } else {
          // Add the new item
          updatedFilters.push(newItem);
        }
      });

      return updatedFilters;
    },
    [dimensionFilter?.options],
  );

  useEffect(() => {
    if (currentDimensionItems && Array.isArray(currentDimensionItems)) {
      setSelectedFilter((prevSelectedFilters) =>
        applyUniqueFilters(prevSelectedFilters, currentDimensionItems),
      );
    }
  }, [currentDimensionItems, applyUniqueFilters]);

  const handleDimensionFilterSelect = useCallback(
    (filterOption: FilterOption) => {
      onDimensionFilterSelect?.(filterOption);
      setSelectedFilter((prevSelectedFilters: any) => [...prevSelectedFilters, filterOption]);
    },
    [onDimensionFilterSelect],
  );

  useEffect(() => {
    if (pulsePaths.some((path) => pathname.includes(path))) {
      setTabIndex(3);
    } else setTabIndex(1);
  }, [pathname]);

  /**
   * Remove item from selection and set default one with the same optionGroupCode.
   * @param item - The filter item value.
   */
  const handleSelectedFilterRemove = useCallback(
    (item?: T.TreeFilterOptionResult) => {
      if (selectedFilter && item) {
        const defaultValues = dimensionFilter?.options ?? [];

        if ('optionGroupCode' in item) {
          const groupToCheck = item.optionGroupCode;
          const defaultItem = defaultValues?.find(
            (defaultItem) =>
              'optionGroupCode' in defaultItem && defaultItem.optionGroupCode === groupToCheck,
          );

          setSelectedFilter((prevSelectedFilters: any[]) =>
            prevSelectedFilters.filter((filter) => filter.id !== selectedFilter[0].id),
          );

          if (defaultItem) {
            onDimensionFilterSelect?.(defaultItem);
          }
        }
      }
    },
    [selectedFilter, dimensionFilter, onDimensionFilterSelect],
  );

  const open = !!anchorEl;

  const currentTabIndex = tabIndex === 3 && !showMultiDimensionFilter ? 0 : tabIndex;

  return (
    <div data-testid="PrioritySelector">
      <Stack direction="column" spacing={0} sx={{ height: 50 }}>
        {loading ? (
          <Title variant="h4">
            <Skeleton variant="rectangular" width={250} />
          </Title>
        ) : (
          <>
            <Title variant="h4" onClick={handleClick} data-testid="PriorityName">
              {currentItem?.label ?? 'N/A'}
              {open ? <ChevronUp /> : <ChevronDown />}
            </Title>
            {!!currentItem?.additionalLabels?.length && (
              <SubTitle variant="small">{currentItem?.additionalLabels[0]}</SubTitle>
            )}
            {!!currentDimensionItems?.length && showMultiDimensionFilter && (
              <SubTitle variant="small">{dimensionLabel}</SubTitle>
            )}
            {currentDimensionItems && currentDimensionItems[0]?.label && showGtmDimensionFilter && (
              <SubTitle variant="small">{currentDimensionItems[0]?.label}</SubTitle>
            )}
          </>
        )}
      </Stack>
      <StyledPopover ref={popoverRef} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box sx={{ mb: 2 }}>
          <SearchBar
            value={search}
            onChange={setSearch}
            sx={{ background: 'rgba(255, 255, 255, 0.1)' }}
          />
        </Box>
        <Tabs value={currentTabIndex} onChange={handleTabChange}>
          {showMultiDimensionFilter && <Tab value={3} label="Filter" data-testid="TabButton" />}
          {!!userPrioritiesFilterOptions?.length && (
            <Tab value={0} label="My priorities" data-testid="TabButton" />
          )}
          <Tab value={1} label="Browse" data-testid="TabButton" />
          {showGtmDimensionFilter && <Tab value={2} label="Filter" data-testid="TabButton" />}
        </Tabs>
        {!!userPrioritiesFilterOptions?.length && (
          <div role="tabpanel" hidden={currentTabIndex !== 0}>
            {currentTabIndex === 0 && (
              <NestedList<FilterOption>
                containerMaxHeight={414}
                itemHeight={46}
                items={userPrioritiesFilterOptions ?? []}
                currentItem={currentItem}
                itemKey={({ id }) => id}
                itemLabel={({ label, value }) => label ?? String(value)}
                itemSubLabel={resolveFilterOptionSublabel}
                itemChildren={(filterOption) =>
                  'children' in filterOption && filterOption.children ? filterOption.children : []
                }
                selectable={({ value }) => !!value}
                onSelect={handlePriorityFilterSelect}
                search={search}
              />
            )}
          </div>
        )}
        <div role="tabpanel" hidden={currentTabIndex !== 1}>
          {currentTabIndex === 1 && (
            <NestedList<FilterOption>
              containerMaxHeight={414}
              itemHeight={46}
              items={filterOptions}
              currentItem={currentItem}
              itemKey={({ id }) => id}
              itemLabel={({ label, value }) => label ?? String(value)}
              itemSubLabel={resolveFilterOptionSublabel}
              itemChildren={(filterOption) =>
                'children' in filterOption && filterOption.children ? filterOption.children : []
              }
              selectable={({ value }) => !!value}
              onSelect={handlePriorityFilterSelect}
              search={search}
            />
          )}
        </div>
        <div role="tabpanel" hidden={currentTabIndex !== 2}>
          {currentTabIndex === 2 && (
            <NestedList<FilterOption>
              containerMaxHeight={414}
              itemHeight={46}
              items={dimensionFilterOptions ?? []}
              currentItem={currentDimensionItems?.[0]}
              itemKey={({ id }) => id}
              itemLabel={({ label, value }) => label ?? String(value)}
              itemSubLabel={resolveFilterOptionSublabel}
              itemChildren={(dimensionFilterOptions) =>
                'children' in dimensionFilterOptions && dimensionFilterOptions.children
                  ? dimensionFilterOptions.children
                  : []
              }
              selectable={({ value }) => !!value}
              onSelect={handleDimensionFilterSelect}
              search={search}
            />
          )}
        </div>
        <div role="tabpanel" hidden={currentTabIndex !== 3 || !showMultiDimensionFilter}>
          {currentTabIndex === 3 && (
            <>
              {dimensionFilterOptions?.map((option, index) => {
                if (!isTreeFilterOptionResult(option)) return null;

                const defaultItemId = option.id;
                const selectedItem = selectedFilter?.find(
                  (filter) =>
                    filter.optionGroupCode === option.optionGroupCode &&
                    filter.id !== defaultItemId,
                );
                const optionItems = [option];

                return (
                  <>
                    {selectedItem ? (
                      <StyledListItem key={option.id} disablePadding data-testid="ListItem">
                        <ListItemButton
                          onClick={() => handleSelectedFilterRemove(selectedItem)}
                          data-testid="ListItemButton"
                        >
                          <ListItemText
                            disableTypography
                            primary={<Typography variant="body">{selectedItem.label}</Typography>}
                            data-testid="ListItemText"
                          />
                          <CloseIcon />
                        </ListItemButton>
                      </StyledListItem>
                    ) : (
                      <React.Fragment key={option.id}>
                        <NestedList<FilterOption>
                          containerMaxHeight={414}
                          itemHeight={46}
                          items={optionItems}
                          currentItem={currentDimensionItems?.find(() => option.optionGroupCode)}
                          itemKey={({ id }) => id}
                          itemLabel={({ label, value }) => label ?? String(value)}
                          itemSubLabel={(item) => resolveFilterOptionSublabel(item, search)}
                          itemChildren={(dimensionFilterOptions) =>
                            'children' in dimensionFilterOptions && dimensionFilterOptions.children
                              ? dimensionFilterOptions.children
                              : []
                          }
                          selectable={({ value, id }) => !!value && id !== defaultItemId}
                          onSelect={handleDimensionFilterSelect}
                          search={search}
                        />
                        {index < dimensionFilterOptions.length - 1 && (
                          <div className="divider"></div>
                        )}
                      </React.Fragment>
                    )}
                  </>
                );
              })}
            </>
          )}
        </div>
      </StyledPopover>
    </div>
  );
};

export default PrioritySelector;
