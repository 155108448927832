import { Variant } from '../types';
import { useFeatureFlagsVariant } from './useFeatureFlagsVariant';

/**
 * Determines if a feature flag is enabled for a given key.
 *
 * @param {string} key - The feature flag key.
 * @param {string | Variant} [fallback] - Optional fallback value if the variant is not found.
 * @returns {boolean} `true` if the feature flag is enabled, `false` otherwise.
 */
export function useFeatureFlagsIsEnabled(key: string, fallback?: string | Variant): boolean {
  const variant = useFeatureFlagsVariant(key, fallback);
  return !!variant?.payload?.enabled;
}
