import { EventTrackingConfig, getPathDetails, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import { MicroLineChart } from '@aily/ui-components';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  IconButton,
  Stack,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { find, kebabCase, map } from 'lodash-es';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useHandleLink } from '../../hooks';
import { useStoriesAdapter } from '../../providers';
import { lineClamp } from '../../theme/utils';
import { mapSentimentToColor, normalizeAbsolutePath } from '../../utils';
import { getIconComponent } from '../../utils/icons';
import { KpiCard, MicroChartCard, PulseMicroChartCard, SimpleCard } from '../Card';
import CardGridSkeleton from '../CardGridSkeleton/CardGridSkeleton';
import { DataViewFooterToolbar } from '../DataViewFooterToolbar';

export type PriorityCardType =
  | T.MicroChartCardResult
  | T.KpiCardResult
  | T.SimpleCardResult
  | T.PulseMicroChartCardResult;

export interface GridDataViewProps {
  dataView?: T.TableDataView | T.CardsDataView;
  loading?: boolean;
  onCellRender?: (
    row: T.TableRowResult,
    column: T.TableColumnResult,
    columnIndex: number,
  ) => React.ReactNode;
  onPriorityEdited?: () => void;
  onPriorityClicked?: (card: T.Card) => void;
  onEditButtonClick?: () => void;
  customHandleLink?: (link: T.Link) => void;
}

const EditIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4507 1.25902C15.2941 0.102464 13.419 0.102462 12.2624 1.25902L2.71472 10.8067C2.48431 11.0372 2.31757 11.3234 2.23079 11.6375L0.676644 17.2627C0.286823 18.6736 1.58414 19.9709 2.99509 19.5811L8.62027 18.027C8.93436 17.9402 9.22059 17.7734 9.45101 17.543L18.9987 7.9953C20.1553 6.83874 20.1553 4.9636 18.9987 3.80705L16.4507 1.25902ZM13.4047 2.40127C13.9304 1.87556 14.7827 1.87556 15.3084 2.40127L17.8565 4.9493C18.3822 5.475 18.3822 6.32734 17.8565 6.85305L8.6178 16.0917L4.16602 11.6399L13.4047 2.40127ZM3.46776 13.2262L2.23369 17.6928C2.17801 17.8944 2.36334 18.0797 2.5649 18.024L7.03156 16.79L3.46776 13.2262Z"
      fill="currentColor"
    />
  </svg>
);

export const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  marginTop: 0,
  height: 154,
  cursor: 'pointer',
  [theme.breakpoints.up('xl')]: {
    height: 170,
  },
  [theme.breakpoints.up('xxl')]: {
    height: 220,
  },
  '& .MuiCardActionArea-root': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  '& .MuiCardContent-root': {
    width: '100%',
  },
}));

export const StyledCardMedia = styled(CardMedia)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '50%',
  '& > svg': {
    overflow: 'visible',
    width: '100%',
    height: '100%',
  },
});

const getCardComponent = (card: T.Card, handleCardClick: (card: T.Card) => () => void) => {
  const dataAttribs = {
    'data-testid': 'card',
    'data-x-card-type': kebabCase(card.cardType),
  };

  if (T.isMicroChartCardResult(card)) {
    return <MicroChartCard card={card} onCardClick={handleCardClick(card)} {...dataAttribs} />;
  }

  if (T.isKpiCardResult(card)) {
    return <KpiCard card={card} onCardClick={handleCardClick(card)} {...dataAttribs} />;
  }

  if (T.isSimpleCardResult(card)) {
    return <SimpleCard card={card} onCardClick={handleCardClick(card)} {...dataAttribs} />;
  }

  if (T.isPulseMicroChartCardResult(card)) {
    return <PulseMicroChartCard card={card} onCardClick={handleCardClick(card)} {...dataAttribs} />;
  }

  return undefined;
};

const GridDataView: React.FC<GridDataViewProps> = ({
  dataView,
  loading,
  onCellRender,
  onPriorityEdited,
  onPriorityClicked,
  onEditButtonClick,
  customHandleLink,
}) => {
  const handleLink = useHandleLink();
  const navigate = useNavigate();
  const { redirect } = useStoriesAdapter();
  const trackPrioritiesEditEvent = useCallback(() => {
    onPriorityEdited?.();
  }, [onPriorityEdited]);

  const trackPriorityClickEvent = useCallback(
    (card: T.Card) => {
      onPriorityClicked?.(card);
    },
    [onPriorityClicked],
  );

  const hasLegacyLink = (
    card: PriorityCardType,
  ): card is PriorityCardType & { legacyLink: T.LegacyLink } => {
    return 'legacyLink' in card && card.legacyLink !== undefined;
  };

  // This is a workaround for RND-5944 to handle passing the title of the card from v3 to v1 my priorities, will be removed when adjusted on other layers
  const updatePriorityCard = (priorityCard: PriorityCardType & { legacyLink: T.LegacyLink }) => {
    if (!priorityCard || !priorityCard.legacyLink || !priorityCard.title) {
      return priorityCard;
    }

    if (!priorityCard.legacyLink.filters) {
      priorityCard.legacyLink.filters = [];
    }

    const newFilter = {
      filterValue: priorityCard.title.value,
    };

    priorityCard.legacyLink.filters.push(newFilter as unknown as T.LegacyLinkFilter);

    return priorityCard;
  };

  const handleEditButtonClick = useCallback(() => {
    trackPrioritiesEditEvent();

    onEditButtonClick?.();

    if (dataView?.editLink?.absolutePath && !onEditButtonClick) {
      navigate(normalizeAbsolutePath(dataView.editLink.absolutePath));
    }
  }, [trackPrioritiesEditEvent, navigate, dataView, onEditButtonClick]);

  const handleCardClick = useCallback(
    (card: T.Card) => () => {
      trackPriorityClickEvent(card);

      const priorityCard = card as PriorityCardType;

      if (hasLegacyLink(priorityCard) && redirect) {
        const updatedCard = updatePriorityCard(priorityCard);
        redirect(updatedCard.legacyLink);

        return;
      }

      if (priorityCard.linkResult && customHandleLink) {
        return customHandleLink(priorityCard.linkResult);
      }
      if (priorityCard.linkResult) {
        handleLink(priorityCard.linkResult);
      }
    },
    [trackPriorityClickEvent, handleLink, customHandleLink],
  );

  const handleCellRender = useCallback(
    (row: T.TableRowResult, column: T.TableColumnResult, columnIndex: number) => {
      const cellContent = find(row.cells, { columnDataKey: column.dataKey })?.cellContent;
      const nextColumn = (dataView as T.TableDataView)?.columns?.[columnIndex + 1];

      const nextCellContent = nextColumn
        ? find(row.cells, { columnDataKey: nextColumn.dataKey })?.cellContent
        : undefined;

      if (onCellRender) {
        const element = onCellRender(row, column, columnIndex);
        if (element) {
          return element;
        }
      }

      if (!cellContent) {
        return <React.Fragment key={columnIndex} />;
      }

      if (T.isTextLinkResult(cellContent) && columnIndex === 0) {
        return (
          <CardContent key={columnIndex}>
            <Typography variant="h9" data-testid="Title" sx={{ ...lineClamp(1) }}>
              {cellContent.value}
            </Typography>
          </CardContent>
        );
      }

      if (T.isTextResult(cellContent)) {
        return (
          <CardContent key={columnIndex} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body" data-testid="Text">
              {cellContent.value}
            </Typography>
            {T.isVarianceResult(nextCellContent) && (
              <Typography
                variant="body"
                color={mapSentimentToColor(nextCellContent.sentiment ?? T.Sentiment.Neutral)}
                data-testid="Variance"
              >
                {nextCellContent.actual}
              </Typography>
            )}
          </CardContent>
        );
      }

      if (T.isMicroChartResult(cellContent)) {
        return (
          <StyledCardMedia key={columnIndex}>
            <MicroLineChart
              data={(cellContent.seriesData ?? []) as number[]}
              trendlineData={(cellContent.trendlineData ?? []) as number[]}
              color={mapSentimentToColor(cellContent.sentiment ?? T.Sentiment.Neutral)}
              lineWidth={2}
              trendlineWidth={0.5}
              trendlineOpacity={0.5}
              fillOpacity={1}
              offsetTop={0}
              offsetBottom={0.3}
              animated
              tweenOrigin="min"
            />
          </StyledCardMedia>
        );
      }

      if (T.isKpiStatusOverview(cellContent)) {
        return (
          <CardContent key={columnIndex}>
            <Stack direction="column" spacing={1.5}>
              {cellContent.items?.slice(0, 3)?.map((item, index) => {
                const IconComponent = getIconComponent(item.icon);
                return (
                  <Stack key={index} direction="row" alignItems="center" spacing={1}>
                    <IconComponent
                      style={{ color: mapSentimentToColor(item.sentiment), fontSize: 18 }}
                    />
                    <Typography variant="small">{item.actual}</Typography>
                    {!!item.target && (
                      <Typography variant="small" color="text.secondary" sx={{ ml: 0.5 }}>
                        / {item.target}
                      </Typography>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </CardContent>
        );
      }

      return <React.Fragment key={columnIndex} />;
    },
    [dataView, onCellRender],
  );

  const renderDataView = useCallback(
    (dataView?: T.CardsDataView | T.TableDataView) => {
      if (!dataView) return;
      // Temporary: Use isFinHomePageCardsDataView with old view due to business decision
      if (
        T.isCardsDataView(dataView) ||
        T.isFinHomePageCardsDataView(dataView) ||
        T.isGtmHomepageCardsDataView(dataView)
      ) {
        return map(dataView.cards, (card, index) => {
          if (!card) return;

          const cardComponent = getCardComponent(card, handleCardClick);

          return (
            <Grid
              key={index}
              size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              sx={{ '& > .MuiCard-root': { width: '100%' } }}
              component={motion.div}
              custom={index}
              animate="visible"
              initial={{ opacity: 0 }}
              variants={{
                visible: (index: number) => ({
                  opacity: 1,
                  transition: { delay: index * 0.02, duration: 0.1 },
                }),
              }}
            >
              {cardComponent}
            </Grid>
          );
        });
      }
      if (T.isTableDataView(dataView)) {
        return ((dataView?.rows ?? []) as T.TableRowResult[])?.map((row, index) => {
          const link = T.isTextLinkResult(row.cells?.[0]?.cellContent)
            ? row.cells?.[0]?.cellContent?.linkResult
            : undefined;
          return (
            <Grid
              key={index}
              size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              component={motion.div}
              custom={index}
              animate="visible"
              initial={{ opacity: 0 }}
              variants={{
                visible: (index: number) => ({
                  opacity: 1,
                  transition: { delay: index * 0.02, duration: 0.1 },
                }),
              }}
            >
              <StyledCard>
                <CardActionArea component="div" onClick={link ? () => handleLink(link) : undefined}>
                  {dataView?.columns?.map((column, columnIndex) =>
                    handleCellRender(row, column, columnIndex),
                  )}
                </CardActionArea>
              </StyledCard>
            </Grid>
          );
        });
      }
    },
    [handleCardClick, handleCellRender, handleLink],
  );

  if (loading) {
    return <CardGridSkeleton numberOfItems={3} CardComponent={StyledCard} />;
  }

  return (
    <Box>
      <Toolbar sx={{ zIndex: 1 }}>
        {!!dataView?.title && (
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h9">{dataView.title}</Typography>
            {!!dataView.editLink?.absolutePath && (
              <IconButton
                onClick={handleEditButtonClick}
                data-testid="EditButton"
                sx={{
                  ml: 1,
                  color: (theme) => theme.palette.text.secondary,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
              >
                {EditIcon}
              </IconButton>
            )}
          </Box>
        )}
        {!!dataView?.annotation && (
          <Typography variant="small" color="text.secondary" data-testid="Annotation">
            {dataView.annotation}
          </Typography>
        )}
      </Toolbar>
      <Grid container rowSpacing={3.75} columnSpacing={2}>
        {renderDataView(dataView)}
      </Grid>
      {!!dataView && <DataViewFooterToolbar dataView={dataView} />}
    </Box>
  );
};

const trackingConfig: EventTrackingConfig<GridDataViewProps> = {
  onPriorityEdited: {
    eventName: 'priorities.edited',
    getEventProps: () => {
      return {
        component: 'priority',
        intent: 'edit',
        item_type: 'button',
        event_version: '2.0.0',
      };
    },
  },
  onPriorityClicked: {
    eventName: 'priority.clicked',
    getEventProps: (card) => {
      const link = (card as { linkResult?: T.Link })?.linkResult;
      const { focusArea, focusAreaPath } = getPathDetails(link?.absolutePath ?? '');

      return {
        name: card?.title.value ?? '',
        component: 'priority',
        component_id: card?.id.split('_')[0],
        intent: 'click',
        item_type: 'card',
        event_version: '2.0.0',
        url: link?.absolutePath?.toString(),
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
};

export default withEventTracking(GridDataView, trackingConfig);
