import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const PfIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 42 35" {...props}>
    <path d="M5.41318 26.1252C5.41318 26.8752 5.15646 27.5099 4.67154 28.0003C4.18662 28.4907 3.55908 28.7215 2.78891 28.7215C2.01875 28.7215 1.39121 28.4907 0.906291 28.0003C0.421373 27.5099 0.193176 26.8752 0.193176 26.1252C0.193176 25.3463 0.421373 24.7116 0.906291 24.2212C1.39121 23.7308 1.99023 23.5 2.78891 23.5C3.53055 23.5 4.1581 23.7308 4.67154 24.2212C5.15646 24.7116 5.41318 25.3463 5.41318 26.1252Z" />
    <path d="M21.0493 18.8293C21.0493 16.8626 20.5924 15.333 19.6785 14.2404C18.7893 13.1478 17.3444 12.6015 15.3437 12.6015C14.9238 12.6015 14.4792 12.6257 14.0099 12.6743C13.5653 12.6986 13.1207 12.7714 12.6762 12.8928V23.7825C13.0713 24.0496 13.59 24.3045 14.2322 24.5473C14.8991 24.7659 15.6031 24.8751 16.344 24.8751C17.9742 24.8751 19.1598 24.3288 19.9008 23.2362C20.6665 22.1436 21.0493 20.6746 21.0493 18.8293ZM25.6434 18.7565C25.6434 20.2133 25.4458 21.5487 25.0506 22.7627C24.6802 23.9768 24.1368 25.0208 23.4205 25.8949C22.7042 26.769 21.8026 27.4488 20.7159 27.9344C19.6538 28.4201 18.4435 28.6629 17.085 28.6629C16.1711 28.6629 15.3314 28.5536 14.5657 28.3351C13.8 28.1165 13.1701 27.8737 12.6762 27.6067V35H8.19318V9.83351C9.10706 9.5907 10.2309 9.36004 11.5647 9.14152C12.8984 8.923 14.3063 8.81374 15.7883 8.81374C17.3197 8.81374 18.6905 9.0444 19.9008 9.50572C21.1111 9.96705 22.1361 10.6348 22.9759 11.5088C23.8404 12.3587 24.4949 13.4027 24.9395 14.641C25.4088 15.855 25.6434 17.2269 25.6434 18.7565Z" />
    <path d="M36.6732 0C37.7105 0 38.6244 0.0971214 39.4148 0.291364C40.2052 0.461325 40.798 0.619146 41.1932 0.764827L40.341 4.40687C39.9211 4.21263 39.4272 4.0548 38.8591 3.9334C38.3157 3.812 37.7229 3.7513 37.0807 3.7513C36.3644 3.7513 35.7593 3.86056 35.2653 4.07908C34.7713 4.27333 34.3761 4.55255 34.0797 4.91675C33.7833 5.28096 33.5734 5.73014 33.4499 6.26431C33.3264 6.77419 33.2646 7.33264 33.2646 7.93965V9.21436H40.304V12.8928H33.2646V28.2622H28.7816V7.86681C28.7816 5.4145 29.4362 3.49636 30.7453 2.11238C32.0543 0.704127 34.0303 0 36.6732 0Z" />
  </SvgIcon>
);

export default PfIcon;
