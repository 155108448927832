import { styled } from '@mui/material';
import { FC, useMemo, useRef, useState } from 'react';

import { ButtonBase } from '../buttons';
import { CheckIcon, EditIcon, PlusIcon } from '../icons';
import { Stack } from '../Stack';
import ParentOption from './ParentOption';
import {
  getParentValue,
  sortParents,
  updateChildSelection,
  updateParentSelection,
  validateSelection,
} from './utils';

export interface FilterRowProps {
  /**
   * The current selection in the filter.
   */
  value?: FilterRowSelection;
  /**
   * The initial selection if the component is uncontrolled.
   */
  defaultValue?: FilterRowSelection;
  /**
   * An array of available parent options (each may include sub-options).
   */
  options?: FilterRowParentOption[];
  /**
   * Callback fired when the selection changes
   */
  onChange?: (value: FilterRowSelection) => void;
  /**
   * Callback fired when a parent option is removed.
   */
  onRemove?: (option: FilterRowParentOption) => void;
  /**
   * Callback fired when the add button is clicked.
   */
  onAddButtonClick?: () => void;
  /**
   * When true, sub-options remain visible in edit mode.
   */
  visibleSubOptionsOnEdit?: boolean;
}

/**
 * FilterRowSelection represents the current selection in the filter.
 *
 * It can be:
 * - []: No option is selected (e.g. when no options are available).
 * - [parent]: A parent option is selected.
 * - [parent, child]: A parent option is selected along with one of its child options.
 */
export type FilterRowSelection = [] | [string] | [string, string];

export interface FilterRowParentOption extends FilterRowOption {
  options?: FilterRowOption[];
}

export interface FilterRowOption {
  value: string;
  label: string;
  isDefault?: boolean;
}

const FilterRowRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.tokens.spacing['medium'],
  // This negates the top padding, which is added so that Remove buttons are not clipped.
  marginTop: -parseInt(theme.tokens.spacing['medium']),
  padding: theme.spacing(theme.tokens.spacing['medium'], theme.tokens.spacing['xsmall']),
  overflow: 'auto',
  scrollbarWidth: 'none',
}));

const IconButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: theme.tokens.borderRadius['round'],
  backgroundColor: theme.tokens.color['background.light'],
  color: theme.tokens.color['text.regular'],
  ...(active && {
    backgroundColor: theme.tokens.color['background.positive'],
    color: theme.tokens.color['text.success'],
  }),
}));

/**
 * FilterRow renders a horizontal list of selectable filter options.
 *
 * This component supports a selection model represented by a tuple:
 * - []: No option is selected (e.g. when no options are available).
 * - [parent]: A parent option is selected.
 * - [parent, child]: A parent option is selected along with one of its child options.
 */
const FilterRow: FC<FilterRowProps> = ({
  value,
  defaultValue = [],
  options,
  onChange,
  onRemove,
  onAddButtonClick,
  visibleSubOptionsOnEdit = false,
}) => {
  const [localValue, setLocalValue] = useState(() => validateSelection(defaultValue, options));
  const currentValue = value ?? localValue;
  const [editMode, setEditMode] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  // Handle parent toggle.
  const handleParentToggle = (option: FilterRowParentOption) => {
    if (editMode) return; // No parent selection in Edit mode.
    if (getParentValue(currentValue) === option.value) return; // Parent already selected.
    const newValue = updateParentSelection(currentValue, option);
    setLocalValue(newValue);
    onChange?.(newValue);
    // Scroll container to the beginning.
    containerRef.current?.scrollTo({ left: 0, behavior: 'smooth' });
  };

  // Handle child toggle.
  const handleChildToggle = (child: FilterRowOption, parent: FilterRowParentOption) => {
    const newValue = updateChildSelection(currentValue, child, parent);
    setLocalValue(newValue);
    onChange?.(newValue);
  };

  // Toggle edit mode.
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  // Sort parent options with the selected parent first.
  const sortedParents = useMemo(() => sortParents(options, currentValue), [options, currentValue]);

  return (
    <FilterRowRoot ref={containerRef} role="group" aria-label="Filter selection">
      {/* Header Icons */}
      <Stack direction="row" alignItems="center" spacing="medium">
        <IconButton onClick={onAddButtonClick} aria-label="Add filter">
          <PlusIcon size="icon.small" />
        </IconButton>
        <IconButton active={editMode} onClick={toggleEditMode} aria-label="Toggle edit mode">
          {editMode ? <CheckIcon size="icon.small" /> : <EditIcon size="icon.xsmall" />}
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" spacing="medium">
        {/* Render Parent Options */}
        {sortedParents.map((option) => (
          <ParentOption
            key={option.value}
            option={option}
            currentValue={currentValue}
            editMode={editMode}
            visibleSubOptionsOnEdit={visibleSubOptionsOnEdit}
            onParentToggle={handleParentToggle}
            onChildToggle={handleChildToggle}
            onRemove={onRemove}
          />
        ))}
      </Stack>
    </FilterRowRoot>
  );
};

export default FilterRow;
