import * as T from '@aily/graphql-sdk/schema';

import { Option } from '../../SliderSelect';

export const mapSliderFilterOptionToAbstractType = (
  sliderFilterOption: T.FilterOptionResult,
): Option => ({
  value: sliderFilterOption.value ?? -1,
  labels: [sliderFilterOption.label, sliderFilterOption.additionalLabels?.[0]].filter(
    Boolean,
  ) as string[],
  isForecasted: sliderFilterOption.filterIcon === T.FilterIcon.ForecastIcon,
});
