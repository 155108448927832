import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const SupIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 60 35" {...props}>
    <circle cx="3" cy="26" r="3" />
    <path d="M14.2678 25.1914C15.4045 25.1914 16.2334 25.0592 16.7544 24.7947C17.2754 24.5062 17.536 24.0254 17.536 23.3523C17.536 22.7272 17.2518 22.2104 16.6834 21.8017C16.1387 21.393 15.2269 20.9482 13.948 20.4674C13.1665 20.1789 12.4442 19.8784 11.7811 19.5659C11.1417 19.2293 10.5851 18.8447 10.1115 18.4119C9.63781 17.9792 9.25889 17.4623 8.9747 16.8613C8.71419 16.2362 8.58394 15.479 8.58394 14.5895C8.58394 12.8585 9.21153 11.5002 10.4667 10.5146C11.7219 9.50485 13.427 9 15.5821 9C16.6715 9 17.7136 9.10818 18.7082 9.32455C19.7029 9.51687 20.4489 9.7092 20.9462 9.90152L20.1647 13.4355C19.6911 13.2191 19.0872 13.0268 18.353 12.8585C17.6188 12.6662 16.7663 12.57 15.7953 12.57C14.919 12.57 14.2086 12.7263 13.6639 13.0388C13.1192 13.3273 12.8468 13.7841 12.8468 14.4092C12.8468 14.7217 12.8942 14.9982 12.9889 15.2386C13.1073 15.479 13.2968 15.7074 13.5573 15.9237C13.8178 16.116 14.1612 16.3204 14.5875 16.5368C15.0138 16.7291 15.5348 16.9334 16.1505 17.1498C17.1689 17.5344 18.0333 17.9191 18.7438 18.3037C19.4542 18.6644 20.0345 19.0851 20.4844 19.5659C20.9581 20.0227 21.3015 20.5515 21.5146 21.1526C21.7278 21.7536 21.8343 22.4748 21.8343 23.3162C21.8343 25.1193 21.1712 26.4896 19.845 27.4272C18.5425 28.3407 16.6715 28.7975 14.2322 28.7975C12.5981 28.7975 11.2838 28.6533 10.2891 28.3648C9.29442 28.1003 8.59578 27.884 8.19318 27.7157L8.93918 24.0735C9.57861 24.338 10.3364 24.5904 11.2127 24.8308C12.1126 25.0712 13.131 25.1914 14.2678 25.1914Z" />
    <path d="M39.0399 27.7517C38.211 27.9681 37.1335 28.1845 35.8073 28.4008C34.481 28.6412 33.0246 28.7614 31.4378 28.7614C29.9458 28.7614 28.6906 28.5451 27.6723 28.1123C26.6776 27.6796 25.8724 27.0786 25.2567 26.3093C24.6646 25.54 24.2383 24.6264 23.9778 23.5687C23.7173 22.4868 23.5871 21.3088 23.5871 20.0347V9.46879H27.8854V19.3495C27.8854 21.3689 28.1696 22.8114 28.738 23.6768C29.3301 24.5423 30.3484 24.975 31.7931 24.975C32.3141 24.975 32.8588 24.951 33.4272 24.9029C34.0192 24.8548 34.4574 24.7947 34.7415 24.7226V9.46879H39.0399V27.7517Z" />
    <path d="M54.7882 18.9889C54.7882 17.0416 54.3501 15.527 53.4738 14.4452C52.6213 13.3634 51.2358 12.8225 49.3175 12.8225C48.9149 12.8225 48.4887 12.8465 48.0387 12.8946C47.6124 12.9186 47.1861 12.9908 46.7598 13.111V23.8932C47.1387 24.1577 47.6361 24.4101 48.2518 24.6505C48.8913 24.8669 49.5662 24.975 50.2767 24.975C51.8397 24.975 52.9765 24.4341 53.687 23.3523C54.4211 22.2705 54.7882 20.816 54.7882 18.9889ZM59.1932 18.9168C59.1932 20.3592 59.0037 21.6815 58.6248 22.8835C58.2696 24.0855 57.7485 25.1193 57.0617 25.9847C56.3749 26.8502 55.5105 27.5233 54.4685 28.0042C53.4501 28.485 52.2897 28.7254 50.9872 28.7254C50.1109 28.7254 49.3057 28.6172 48.5715 28.4008C47.8374 28.1845 47.2335 27.9441 46.7598 27.6796V35H42.4614V10.0818C43.3377 9.84142 44.4152 9.61304 45.6941 9.39667C46.973 9.1803 48.3229 9.07212 49.7438 9.07212C51.2121 9.07212 52.5265 9.30051 53.687 9.75728C54.8474 10.2141 55.8302 10.8752 56.6355 11.7406C57.4643 12.5821 58.0919 13.6158 58.5182 14.8419C58.9682 16.0439 59.1932 17.4022 59.1932 18.9168Z" />
  </SvgIcon>
);

export default SupIcon;
