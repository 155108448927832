import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const SupIconNoDot: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 52 35" {...props}>
    <path d="M6.29675 25.1914C7.43351 25.1914 8.2624 25.0592 8.78342 24.7947C9.30444 24.5062 9.56495 24.0254 9.56495 23.3523C9.56495 22.7272 9.28076 22.2104 8.71237 21.8017C8.16767 21.393 7.25589 20.9482 5.97704 20.4674C5.19551 20.1789 4.47319 19.8784 3.81008 19.5659C3.17065 19.2293 2.61411 18.8447 2.14046 18.4119C1.66681 17.9792 1.28788 17.4623 1.00369 16.8613C0.743185 16.2362 0.612931 15.479 0.612931 14.5895C0.612931 12.8585 1.24052 11.5002 2.4957 10.5146C3.75087 9.50485 5.45602 9 7.61113 9C8.70053 9 9.74257 9.10818 10.7372 9.32455C11.7319 9.51687 12.4779 9.7092 12.9752 9.90152L12.1937 13.4355C11.7201 13.2191 11.1162 13.0268 10.382 12.8585C9.64784 12.6662 8.79526 12.57 7.82428 12.57C6.94802 12.57 6.23754 12.7263 5.69284 13.0388C5.14815 13.3273 4.8758 13.7841 4.8758 14.4092C4.8758 14.7217 4.92316 14.9982 5.01789 15.2386C5.1363 15.479 5.32576 15.7074 5.58627 15.9237C5.84678 16.116 6.19018 16.3204 6.61646 16.5368C7.04275 16.7291 7.56377 16.9334 8.17951 17.1498C9.19787 17.5344 10.0623 17.9191 10.7728 18.3037C11.4832 18.6644 12.0635 19.0851 12.5134 19.5659C12.9871 20.0227 13.3305 20.5515 13.5436 21.1526C13.7568 21.7536 13.8633 22.4748 13.8633 23.3162C13.8633 25.1193 13.2002 26.4896 11.874 27.4272C10.5715 28.3407 8.70053 28.7975 6.26123 28.7975C4.62713 28.7975 3.31274 28.6533 2.31808 28.3648C1.32341 28.1003 0.624772 27.884 0.222168 27.7157L0.968169 24.0735C1.6076 24.338 2.36544 24.5904 3.2417 24.8308C4.14163 25.0712 5.15999 25.1914 6.29675 25.1914Z" />
    <path d="M31.0689 27.7517C30.24 27.9681 29.1625 28.1845 27.8363 28.4008C26.51 28.6412 25.0536 28.7614 23.4668 28.7614C21.9748 28.7614 20.7196 28.5451 19.7013 28.1123C18.7066 27.6796 17.9014 27.0786 17.2857 26.3093C16.6936 25.54 16.2673 24.6264 16.0068 23.5687C15.7463 22.4868 15.616 21.3088 15.616 20.0347V9.46879H19.9144V19.3495C19.9144 21.3689 20.1986 22.8114 20.767 23.6768C21.3591 24.5423 22.3774 24.975 23.8221 24.975C24.3431 24.975 24.8878 24.951 25.4562 24.9029C26.0482 24.8548 26.4863 24.7947 26.7705 24.7226V9.46879H31.0689V27.7517Z" />
    <path d="M46.8172 18.9889C46.8172 17.0416 46.3791 15.527 45.5028 14.4452C44.6502 13.3634 43.2648 12.8225 41.3465 12.8225C40.9439 12.8225 40.5176 12.8465 40.0677 12.8946C39.6414 12.9186 39.2151 12.9908 38.7888 13.111V23.8932C39.1677 24.1577 39.6651 24.4101 40.2808 24.6505C40.9202 24.8669 41.5952 24.975 42.3057 24.975C43.8687 24.975 45.0055 24.4341 45.716 23.3523C46.4501 22.2705 46.8172 20.816 46.8172 18.9889ZM51.2222 18.9168C51.2222 20.3592 51.0327 21.6815 50.6538 22.8835C50.2985 24.0855 49.7775 25.1193 49.0907 25.9847C48.4039 26.8502 47.5395 27.5233 46.4975 28.0042C45.4791 28.485 44.3187 28.7254 43.0162 28.7254C42.1399 28.7254 41.3347 28.6172 40.6005 28.4008C39.8664 28.1845 39.2625 27.9441 38.7888 27.6796V35H34.4904V10.0818C35.3667 9.84142 36.4442 9.61304 37.7231 9.39667C39.002 9.1803 40.3519 9.07212 41.7728 9.07212C43.2411 9.07212 44.5555 9.30051 45.716 9.75728C46.8764 10.2141 47.8592 10.8752 48.6644 11.7406C49.4933 12.5821 50.1209 13.6158 50.5472 14.8419C50.9972 16.0439 51.2222 17.4022 51.2222 18.9168Z" />
  </SvgIcon>
);

export default SupIconNoDot;
