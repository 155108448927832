import { ColorTokenKey } from '@aily/ui-theme';
import { styled, useTheme } from '@mui/material';
import { motion, Transition } from 'framer-motion';
import { FC } from 'react';

import FilledCircleSymbol, { FilledCircleSymbolProps } from './FilledCircleSymbol';
import OutlinedCircleSymbol from './OutlinedCircleSymbol';
import { useValueComparisonBar } from './useValueComparisonBar';
import VerticalBarSymbol, { VerticalBarSymbolProps } from './VerticalBarSymbol';

export interface ValueComparisonBarProps {
  /**
   * Primary value shown as an outlined circle.
   */
  primaryValue: number;
  /**
   * Secondary value shown as a filled circle.
   */
  secondaryValue: number;
  /**
   * Reference value shown as a vertical bar.
   */
  baseValue: number;
  /**
   * Size of the bar and symbols.
   * @default 'default'
   */
  size?: ValueComparisonBarSize;
  /**
   * Minimum value for clamping and normalization.
   * @default 0
   */
  min?: number;
  /**
   * Maximum value for clamping and normalization.
   * @default 100
   */
  max?: number;
  /**
   * If true, disables all animations.
   * @default false
   */
  disableAnimation?: boolean;
  /**
   * Background color of the base track.
   */
  trackColor?: ValueComparisonBarTrackColor;
}

export type ValueComparisonBarSize = 'default' | 'small';

export type ValueComparisonBarTrackColor = Extract<
  ColorTokenKey,
  'neutral.grey' | 'neutral.grey-darker'
>;

const circleSymbolPropsMap: Record<ValueComparisonBarSize, FilledCircleSymbolProps> = {
  default: { size: 18, strokeWidth: 3 },
  small: { size: 12, strokeWidth: 2 },
};

const barSymbolPropsMap: Record<ValueComparisonBarSize, VerticalBarSymbolProps> = {
  default: { size: 22, strokeWidth: 4 },
  small: { size: 18, strokeWidth: 2 },
};

const trackHeightMap: Record<ValueComparisonBarSize, number> = {
  default: 12,
  small: 8,
};

const Track = styled('div')<{
  $size: ValueComparisonBarSize;
  $color: ValueComparisonBarTrackColor;
}>(({ theme, $size, $color }) => ({
  position: 'relative',
  height: trackHeightMap[$size],
  borderRadius: theme.tokens.borderRadius['round'],
  backgroundColor: theme.tokens.color[$color],
}));

const shouldForwardProp = (prop: string) => !prop.startsWith('$');

const MotionTrack = styled(motion.div, { shouldForwardProp })<{ $color: ColorTokenKey }>(
  ({ theme, $color }) => ({
    position: 'absolute',
    top: 0,
    height: '100%',
    backgroundColor: theme.tokens.color[$color],
    zIndex: 1,
  }),
);

const MotionMarker = styled(motion.div, { shouldForwardProp })<{
  $position: number;
  $zIndex: number;
}>(({ $position, $zIndex }) => ({
  position: 'absolute',
  top: '50%',
  left: `${$position}%`,
  zIndex: $zIndex,
  transform: 'translate(-50%, -50%)',
  svg: {
    display: 'block',
  },
}));

const ValueComparisonBar: FC<ValueComparisonBarProps> = ({
  primaryValue,
  secondaryValue,
  baseValue,
  size = 'default',
  min = 0,
  max = 100,
  disableAnimation = false,
  trackColor = 'neutral.grey',
}) => {
  const theme = useTheme();

  const transition: Transition = disableAnimation
    ? { duration: 0 }
    : { duration: 0.3, ease: 'circInOut' };

  const {
    primary,
    secondary,
    color,
    split,
    bothLeft,
    primaryControls,
    secondaryControls,
    trackControlsLeft,
    trackControlsRight,
  } = useValueComparisonBar({
    primaryValue,
    secondaryValue,
    baseValue,
    min,
    max,
  });

  return (
    <Track $size={size} $color={trackColor} role="group" aria-label="Value comparison chart">
      {split ? (
        <>
          <MotionTrack
            $color="brand.pink"
            style={{ right: '50%', width: 0 }}
            animate={trackControlsLeft}
            transition={transition}
            aria-hidden="true"
          />
          <MotionTrack
            $color="brand.green"
            style={{ left: '50%', width: 0 }}
            animate={trackControlsRight}
            transition={transition}
            aria-hidden="true"
          />
        </>
      ) : (
        <MotionTrack
          $color={color}
          style={bothLeft ? { right: '50%', width: 0 } : { left: '50%', width: 0 }}
          animate={bothLeft ? trackControlsLeft : trackControlsRight}
          transition={transition}
          aria-hidden="true"
        />
      )}

      <MotionMarker $position={50} $zIndex={3} aria-hidden="true">
        <VerticalBarSymbol {...barSymbolPropsMap[size]} />
      </MotionMarker>

      <MotionMarker
        animate={secondaryControls}
        initial={{ left: '50%' }}
        transition={transition}
        $position={secondary}
        $zIndex={4}
        role="img"
        aria-label="Secondary value marker"
      >
        <FilledCircleSymbol {...circleSymbolPropsMap[size]} />
      </MotionMarker>

      <MotionMarker
        animate={primaryControls}
        initial={{ left: '50%' }}
        transition={transition}
        $position={primary}
        $zIndex={5}
        role="img"
        aria-label="Primary value marker"
      >
        <OutlinedCircleSymbol fill={theme.tokens.color[color]} {...circleSymbolPropsMap[size]} />
      </MotionMarker>
    </Track>
  );
};

export default ValueComparisonBar;
