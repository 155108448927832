import { StoryDecision } from '@aily/graphql-sdk/schema';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { useAilyAgent } from '../../providers';
import {
  AgentJsonData,
  Popup as PopupType,
  Screen,
  ScreenType,
  SliderValueType,
} from '../../types';
import { AgentDecisionLeversPopup } from '../AgentDecisionLeversPopup/AgentDecisionLeversPopup';
import AgentDefault from '../AgentDefault/AgentDefault';
import { AgentElementRenderer } from '../AgentElementRenderer/AgentElementRenderer';
import { DecisionLevers } from '../AgentScreenItems';
import { PopupProps } from '../Popup';
import { PopupButton, PopupButtonProps } from '../PopupButton';

export interface AgentSlideshowSlideSectionBuilderProps {
  screen: Screen;
  titleRef?: React.Ref<HTMLElement>;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  StackProps?: StackProps;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onSetExtractedLeversScreens: (screens: Screen[]) => void;
  switchValue: { value: number };
  onSetSwitchValue: ({ value }: { value: number }) => void;
  sliderValues: SliderValueType;
  onSetSliderValues: (sliderValues: SliderValueType) => void;
  optimizationScope: number;
  onSetOptimizationScope: (value: number) => void;
  onDecide?: () => void;
}

// Popup component that includes the PopupContent
export const ScreenPopupComponent: React.FC<
  PopupProps & {
    screens: Screen[];
    baseAudioURL: string;
    agentJsonData: AgentJsonData;
    decision: StoryDecision | null;
    onAgentClose?: () => void;
    onBackdropClose?: (forceRefetch: boolean) => void;
    onDecide?: () => void;
  }
> = ({
  screens,
  title,
  open,
  onClose,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
  onDecide,
}) => {
  const { exit } = useAilyAgent();

  const handleClose = useCallback(() => {
    exit();
    onClose?.();
  }, [onClose, exit]);

  if (!screens) return null;

  return (
    <AgentDecisionLeversPopup
      title={title}
      screens={screens}
      open={open}
      onClose={handleClose}
      baseAudioURL={baseAudioURL}
      agentJsonData={agentJsonData}
      decision={decision}
      textElementStyle={{ paddingBottom: screens[0]?.screen_type === ScreenType.Default ? 15 : 5 }}
      onAgentClose={onAgentClose}
      onBackdropClose={onBackdropClose}
      onDecide={onDecide}
    />
  );
};

const ScreenPopupButtonComponent: React.FC<
  Omit<PopupButtonProps, 'children'> & { popup: PopupType }
> = ({ popup, ...rest }) => {
  if (!popup || !popup.button_title) return null;
  const { button_logo, button_title } = popup;
  return (
    <PopupButton prefix={button_logo} {...rest}>
      {button_title}
    </PopupButton>
  );
};

// Slideshow section builder
export const AgentSlideshowSlideSectionBuilder: React.FC<
  AgentSlideshowSlideSectionBuilderProps
> = ({
  screen,
  titleRef,
  onAfterScreen,
  StackProps,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
  onSetExtractedLeversScreens,
  switchValue,
  onSetSwitchValue,
  sliderValues,
  onSetSliderValues,
  optimizationScope,
  onSetOptimizationScope,
  onDecide,
}) => {
  const renderPopupComponent = useCallback(
    (props: PopupProps) => {
      return (
        <>
          {screen.popup && (
            <ScreenPopupComponent
              {...props}
              title={''}
              screens={screen.popup.screens}
              baseAudioURL={baseAudioURL}
              agentJsonData={agentJsonData}
              decision={decision}
              onAgentClose={onAgentClose}
              onBackdropClose={onBackdropClose}
              onDecide={onDecide}
            />
          )}
        </>
      );
    },
    [screen, baseAudioURL],
  );
  const renderTypeAgnosticScreens = useMemo(() => {
    return (
      <Box width="100%" data-testid="agnostic-screen-content">
        {screen.content?.map(({ elements: row }, rowIndex) => (
          <Stack
            flexDirection="row"
            gap="20px"
            flex={1}
            alignItems="center"
            key={`row-${rowIndex}`}
            flexWrap="wrap"
            marginBottom="10px"
          >
            {row?.map((col, colIndex) => (
              <Box
                key={`col-${rowIndex}-${colIndex}`}
                boxSizing="border-box"
                padding="2px"
                flexDirection="row"
                gap="10px"
                flex="1 1 50%"
              >
                <Stack flexDirection="row" gap="10px" alignItems="center">
                  {col.map((elements, elementsIndex) => (
                    <Stack
                      data-testid="agnostic-screen-content-col"
                      width="100%"
                      key={`elements-${elementsIndex}`}
                    >
                      {elements.map((element, elementKey) => {
                        return (
                          <AgentElementRenderer
                            key={elementKey}
                            element={element}
                            screen={screen}
                            titleRef={titleRef}
                            agentJsonData={agentJsonData}
                            decision={decision}
                            chartHeight={240}
                            chartWidth={290}
                            onAgentClose={onAgentClose}
                            onBackdropClose={onBackdropClose}
                            onDecide={onDecide}
                          />
                        );
                      })}
                    </Stack>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        ))}
      </Box>
    );
  }, [screen, titleRef, agentJsonData, decision]);

  const renderScreen = useMemo(() => {
    if (!isNil(decision)) {
      return <>{renderTypeAgnosticScreens}</>;
    }

    if (screen.screen_type === ScreenType.Intro) {
      return <>{renderTypeAgnosticScreens}</>;
    } else if (screen.screen_type === ScreenType.Levers) {
      return (
        <DecisionLevers
          data-testid="decision-levers-screen"
          screen={screen}
          baseAudioURL={baseAudioURL}
          agentJsonData={agentJsonData}
          decision={decision}
          onAgentClose={onAgentClose}
          onBackdropClose={onBackdropClose}
          onSetExtractedLeversScreens={onSetExtractedLeversScreens}
          switchValue={switchValue}
          onSetSwitchValue={onSetSwitchValue}
          sliderValues={sliderValues}
          onSetSliderValues={onSetSliderValues}
          optimizationScope={optimizationScope}
          onSetOptimizationScope={onSetOptimizationScope}
        />
      );
    } else if (screen.screen_type === ScreenType.Default) {
      return <AgentDefault screen={screen} />;
    } else {
      return <>{renderTypeAgnosticScreens}</>;
    }
  }, [screen]);

  const hasLeversScreens = useMemo(
    () => agentJsonData.screens.some((screen) => screen.screen_type === ScreenType.Levers),
    [agentJsonData.screens],
  );

  // checking if it's title screen
  const isTitleScreen = useMemo(() => {
    if (!agentJsonData.screens?.length) return false;

    const introScreenIndex = agentJsonData.screens.findIndex(
      (s) => s.screen_type === ScreenType.Intro,
    );

    if (introScreenIndex === -1) return false;

    const subsequentScreens = agentJsonData.screens.slice(introScreenIndex + 1);
    const defaultScreenIndex = subsequentScreens.findIndex(
      (s) => s.screen_type === ScreenType.Default,
    );

    if (defaultScreenIndex === -1) return false;

    const titleScreen = subsequentScreens[defaultScreenIndex];
    return screen.screen_id === titleScreen.screen_id;
  }, [screen, agentJsonData.screens]);

  const shouldShowButtons = useMemo(() => {
    // Early returns
    if (!screen.popup || !screen.popup.button_title) return false;

    // When levers screens exist and decision is made
    if (hasLeversScreens && decision) {
      return screen.screen_type !== ScreenType.Levers;
    }

    // When no levers screens
    if (!hasLeversScreens) {
      return isTitleScreen || screen.screen_type !== ScreenType.Levers;
    }

    return false;
  }, [hasLeversScreens, decision, isTitleScreen, screen]);

  return (
    <Box
      data-testid="agent-slideshow-slide-section-builder"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="342px"
      flex={1}
      minWidth={0}
      {...StackProps}
    >
      <Stack
        direction="column"
        height={520}
        flex={1}
        alignItems="flex-start"
        paddingTop="24px"
        paddingBottom="24px"
        width="342px"
        sx={{
          background:
            screen.screen_type === ScreenType.Levers ? 'transparent' : 'rgba(64, 64, 64, 0.35)',
          border: screen.screen_type === ScreenType.Levers ? '1px solid #595959' : 'none',
          borderRadius: '12px',
        }}
        {...(screen.screen_type === ScreenType.Levers && { alignItems: 'center' })}
      >
        <Typography ref={titleRef} variant="h9" paddingRight="24px" paddingLeft="24px">
          {screen.screen_title || 'Decision Levers'}
        </Typography>
        <Box
          data-testid="screen-content"
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          marginTop="20px"
          paddingRight="24px"
          paddingLeft="24px"
          alignItems="flex-start"
          display="flex"
          flex={1}
          width="100%"
        >
          {renderScreen}
        </Box>
        {shouldShowButtons && screen.popup && (
          <Stack
            width="100%"
            justifyContent="center"
            alignItems="center"
            data-testid="popup-button-component"
          >
            <ScreenPopupButtonComponent
              popup={screen.popup}
              PopupComponent={(props) => renderPopupComponent(props)}
              sx={{
                mt: 1,
              }}
            />
          </Stack>
        )}
      </Stack>
      {onAfterScreen?.(screen)}
    </Box>
  );
};
