import { keyframes, styled } from '@mui/material/styles';
import React, { cloneElement, FC, isValidElement, ReactNode } from 'react';

import { useTokenAnimation } from '../providers';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const AnimatedToken = styled('span')({
  opacity: 0,
  animationName: fadeIn,
  animationFillMode: 'forwards',
});

/**
 * Splits the given text into individual tokens and wraps them in animated spans.
 *
 * @param {ReactNode} children - The text or element(s) to process.
 * @param {number} duration - The duration for the animation in milliseconds.
 * @param {number} delayPerToken - The delay increment per token in milliseconds.
 * @param {(by: number) => number} getAndIncrement - Function to retrieve and increment the token index.
 * @returns {ReactNode} The processed children with animation applied to each token.
 */
const processTokens = (
  children: ReactNode,
  duration: number,
  delayPerToken: number,
  getAndIncrement: (by: number) => number,
): ReactNode => {
  if (typeof children === 'string') {
    // Split by whitespace, preserving spaces as separate tokens.
    const parts = children.split(/(\s+)/);
    return parts.map((part) => {
      if (!part) return null;
      if (/^\s+$/.test(part)) {
        // Render whitespace in its own span.
        return <span key={`ws-${getAndIncrement(1)}`}>{part}</span>;
      }

      const tokenIndex = getAndIncrement(1);
      return (
        <AnimatedToken
          key={`token-${tokenIndex}`}
          style={{
            animationDelay: `${tokenIndex * delayPerToken}ms`,
            animationDuration: `${duration}ms`,
          }}
        >
          {part}
        </AnimatedToken>
      );
    });
  } else if (isValidElement(children)) {
    if (children.props.children) {
      const newChildren = processTokens(
        children.props.children,
        duration,
        delayPerToken,
        getAndIncrement,
      );

      return cloneElement(children, { ...children.props, children: newChildren });
    }

    return children;
  } else if (Array.isArray(children)) {
    return children.map((child) => processTokens(child, duration, delayPerToken, getAndIncrement));
  }

  return children;
};

/**
 * Higher-order component that wraps a given component to render its children as animated tokens.
 * Uses the token animation settings provided by the useTokenAnimation hook.
 *
 * @template P
 * @param {React.ComponentType<P>} WrappedComponent - The component to wrap.
 * @returns {FC<P & { children?: ReactNode }>} A new component that renders animated tokens.
 */
export function withAnimatedTokens<P>(
  WrappedComponent: React.ComponentType<P>,
): FC<P & { children?: ReactNode }> {
  return function AnimatedComponent(props: P & { children?: ReactNode }) {
    const { children } = props;
    const { duration, delayPerToken, getAndIncrement } = useTokenAnimation();
    const animatedChildren = processTokens(children, duration, delayPerToken, getAndIncrement);
    return <WrappedComponent {...props}>{animatedChildren}</WrappedComponent>;
  };
}
