import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ArrowUpAltIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M11.0008 8.7999L8.10078 11.6999C7.91745 11.8832 7.68411 11.9749 7.40078 11.9749C7.11745 11.9749 6.88411 11.8832 6.70078 11.6999C6.51745 11.5166 6.42578 11.2832 6.42578 10.9999C6.42578 10.7166 6.51745 10.4832 6.70078 10.2999L11.3008 5.6999C11.5008 5.4999 11.7341 5.3999 12.0008 5.3999C12.2674 5.3999 12.5008 5.4999 12.7008 5.6999L17.3008 10.2999C17.4841 10.4832 17.5758 10.7166 17.5758 10.9999C17.5758 11.2832 17.4841 11.5166 17.3008 11.6999C17.1174 11.8832 16.8841 11.9749 16.6008 11.9749C16.3174 11.9749 16.0841 11.8832 15.9008 11.6999L13.0008 8.7999V16.9999C13.0008 17.2832 12.9049 17.5207 12.7133 17.7124C12.5216 17.9041 12.2841 17.9999 12.0008 17.9999C11.7174 17.9999 11.4799 17.9041 11.2883 17.7124C11.0966 17.5207 11.0008 17.2832 11.0008 16.9999V8.7999Z" />
  </SvgIcon>
);

export default ArrowUpAltIcon;
