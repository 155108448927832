import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const CrmIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 63 35" {...props}>
      <path d="M5.41321 26.4244C5.41321 27.1742 5.15649 27.8087 4.67157 28.299C4.18665 28.7893 3.55911 29.02 2.78895 29.02C2.01878 29.02 1.39124 28.7893 0.906321 28.299C0.421403 27.8087 0.193207 27.1742 0.193207 26.4244C0.193207 25.6457 0.421403 25.0113 0.906321 24.521C1.39124 24.0307 1.99026 23.8 2.78895 23.8C3.53058 23.8 4.15813 24.0307 4.67157 24.521C5.15649 25.0113 5.41321 25.6457 5.41321 26.4244Z" />
      <path d="M8.01321 19.0682C8.01321 17.6596 8.2332 16.3481 8.67316 15.1338C9.11312 13.8952 9.7364 12.8266 10.543 11.9279C11.374 11.0293 12.3762 10.325 13.5494 9.81501C14.7226 9.305 16.0425 9.04999 17.5091 9.04999C19.3178 9.04999 21.0288 9.37786 22.642 10.0336L21.6887 13.6402C21.1754 13.4216 20.5888 13.2394 19.9289 13.0937C19.2934 12.948 18.609 12.8751 17.8757 12.8751C16.1403 12.8751 14.8204 13.4216 13.916 14.5145C13.0117 15.5831 12.5595 17.101 12.5595 19.0682C12.5595 20.9626 12.9872 22.4683 13.8427 23.5855C14.6982 24.6784 16.1403 25.2248 18.169 25.2248C18.9267 25.2248 19.6722 25.152 20.4055 25.0063C21.1388 24.8606 21.7743 24.6784 22.312 24.4598L22.9353 28.1028C22.4464 28.3457 21.7009 28.5643 20.6988 28.7585C19.7211 28.9528 18.7068 29.05 17.6557 29.05C16.0181 29.05 14.5882 28.8071 13.3661 28.3214C12.1684 27.8114 11.1663 27.1192 10.3597 26.2449C9.57753 25.3463 8.99091 24.2898 8.59983 23.0755C8.20875 21.8369 8.01321 20.5011 8.01321 19.0682Z" />
      <path d="M34.5798 13.4944C34.2132 13.373 33.6999 13.2516 33.04 13.1301C32.4045 12.9844 31.659 12.9116 30.8035 12.9116C30.3146 12.9116 29.7891 12.9601 29.227 13.0573C28.6892 13.1544 28.3104 13.2394 28.0904 13.3123V28.5764H23.6541V10.4343C24.5096 10.1186 25.5728 9.82716 26.8438 9.56001C28.1393 9.26857 29.5692 9.12285 31.1335 9.12285C31.4268 9.12285 31.769 9.14713 32.16 9.19571C32.5511 9.21999 32.9422 9.26857 33.3333 9.34143C33.7244 9.39 34.1032 9.46286 34.4699 9.56001C34.8365 9.63286 35.1298 9.70572 35.3498 9.77858L34.5798 13.4944Z" />
      <path d="M46.6512 18.5582C46.6512 16.5667 46.3945 15.1338 45.8812 14.2595C45.3924 13.3609 44.4636 12.9116 43.0948 12.9116C42.606 12.9116 42.0682 12.948 41.4816 13.0208C40.895 13.0937 40.455 13.1544 40.1617 13.203V28.5764H35.7254V10.1429C36.5809 9.90002 37.6931 9.66929 39.0618 9.45072C40.455 9.23214 41.9216 9.12285 43.4615 9.12285C44.7813 9.12285 45.8568 9.29285 46.6878 9.63286C47.5433 9.97288 48.2522 10.4222 48.8143 10.9808C49.0832 10.7865 49.4254 10.58 49.8409 10.3615C50.2564 10.1429 50.7208 9.94859 51.2341 9.77858C51.7474 9.58429 52.2851 9.42643 52.8473 9.305C53.4339 9.18356 54.0206 9.12285 54.6072 9.12285C56.0982 9.12285 57.3203 9.34143 58.2735 9.77858C59.2512 10.1915 60.0089 10.7865 60.5467 11.5636C61.1089 12.3165 61.4877 13.2394 61.6833 14.3323C61.9032 15.4009 62.0132 16.5788 62.0132 17.866V28.5764H57.5769V18.5582C57.5769 16.5667 57.3325 15.1338 56.8437 14.2595C56.3548 13.3609 55.4138 12.9116 54.0206 12.9116C53.3117 12.9116 52.6396 13.033 52.0041 13.2759C51.3686 13.4944 50.8919 13.713 50.5742 13.9316C50.7697 14.5388 50.9042 15.1823 50.9775 15.8624C51.0508 16.5424 51.0875 17.271 51.0875 18.0482V28.5764H46.6512V18.5582Z" />
    </SvgIcon>
  );
};

export default CrmIcon;
