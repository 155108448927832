import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const CmcIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 64 35" {...props}>
      <path d="M48.7058 19.0682C48.7058 17.6596 48.9244 16.3481 49.3615 15.1338C49.7987 13.8952 50.418 12.8266 51.2195 11.9279C52.0452 11.0293 53.041 10.325 54.2067 9.81501C55.3725 9.305 56.6839 9.04999 58.1411 9.04999C59.9383 9.04999 61.6384 9.37786 63.2413 10.0336L62.2941 13.6402C61.7841 13.4216 61.2012 13.2394 60.5455 13.0937C59.9141 12.948 59.234 12.8751 58.5054 12.8751C56.7811 12.8751 55.4696 13.4216 54.571 14.5145C53.6724 15.5831 53.2231 17.101 53.2231 19.0682C53.2231 20.9626 53.6481 22.4683 54.4982 23.5855C55.3482 24.6784 56.7811 25.2249 58.7969 25.2249C59.5498 25.2249 60.2905 25.152 61.0191 25.0063C61.7477 24.8606 62.3791 24.6784 62.9135 24.4598L63.5328 28.1028C63.047 28.3457 62.3063 28.5643 61.3105 28.7586C60.3391 28.9528 59.3312 29.05 58.2869 29.05C56.6597 29.05 55.2389 28.8071 54.0246 28.3214C52.8345 27.8114 51.8388 27.1192 51.0373 26.2449C50.2601 25.3463 49.6773 24.2898 49.2887 23.0755C48.9001 21.8369 48.7058 20.5011 48.7058 19.0682Z" />
      <path d="M29.0981 18.5582C29.0981 16.5667 28.8431 15.1338 28.333 14.2595C27.8473 13.3609 26.9244 12.9116 25.5644 12.9116C25.0786 12.9116 24.5443 12.948 23.9615 13.0209C23.3786 13.0937 22.9414 13.1544 22.65 13.203V28.5764H18.242V10.1429C19.092 9.90002 20.197 9.6693 21.5571 9.45072C22.9414 9.23214 24.3986 9.12285 25.9287 9.12285C27.2401 9.12285 28.3087 9.29286 29.1345 9.63287C29.9845 9.97288 30.6888 10.4222 31.2474 10.9808C31.5146 10.7865 31.8546 10.58 32.2675 10.3615C32.6803 10.1429 33.1418 9.9486 33.6518 9.77859C34.1618 9.5843 34.6961 9.42644 35.2547 9.305C35.8376 9.18357 36.4205 9.12285 37.0033 9.12285C38.4848 9.12285 39.6992 9.34143 40.6463 9.77859C41.6178 10.1915 42.3707 10.7865 42.905 11.5637C43.4636 12.3165 43.84 13.2394 44.0343 14.3323C44.2529 15.4009 44.3622 16.5788 44.3622 17.866V28.5764H39.9542V18.5582C39.9542 16.5667 39.7113 15.1338 39.2256 14.2595C38.7398 13.3609 37.8048 12.9116 36.4205 12.9116C35.7162 12.9116 35.0483 13.033 34.4168 13.2759C33.7854 13.4944 33.3118 13.713 32.9961 13.9316C33.1904 14.5388 33.3239 15.1824 33.3968 15.8624C33.4697 16.5424 33.5061 17.271 33.5061 18.0482V28.5764H29.0981V18.5582Z" />
      <path d="M0.00219727 19.0682C0.00219727 17.6596 0.220777 16.3481 0.657935 15.1338C1.09509 13.8952 1.7144 12.8266 2.51586 11.9279C3.3416 11.0293 4.33735 10.325 5.50311 9.81501C6.66886 9.305 7.98034 9.04999 9.43753 9.04999C11.2347 9.04999 12.9348 9.37786 14.5377 10.0336L13.5905 13.6402C13.0805 13.4216 12.4976 13.2394 11.8419 13.0937C11.2105 12.948 10.5304 12.8751 9.80183 12.8751C8.07749 12.8751 6.76601 13.4216 5.86741 14.5145C4.9688 15.5831 4.5195 17.101 4.5195 19.0682C4.5195 20.9626 4.94452 22.4683 5.79455 23.5855C6.64458 24.6784 8.07749 25.2249 10.0933 25.2249C10.8462 25.2249 11.5869 25.152 12.3155 25.0063C13.0441 24.8606 13.6755 24.6784 14.2098 24.4598L14.8292 28.1028C14.3434 28.3457 13.6027 28.5643 12.6069 28.7586C11.6355 28.9528 10.6276 29.05 9.58325 29.05C7.95605 29.05 6.53529 28.8071 5.32096 28.3214C4.13092 27.8114 3.13517 27.1192 2.33371 26.2449C1.55654 25.3463 0.973661 24.2898 0.585075 23.0755C0.19649 21.8369 0.00219727 20.5011 0.00219727 19.0682Z" />
    </SvgIcon>
  );
};

export default CmcIconNoDot;
