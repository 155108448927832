import { captureException } from '@aily/monitoring-service';
import { ApolloCache, NormalizedCacheObject } from '@apollo/client/core';

/**
 * Creates a handler function that restores Apollo cache data from localStorage
 * @param cache - The Apollo cache instance to restore data into
 * @param key - The localStorage key to read from
 * @returns A function that when called will:
 * 1. Read cached data from localStorage
 * 2. If valid data exists, parse it and restore it to the Apollo cache
 * 3. If no data exists or data is 'undefined', do nothing
 */
export const getRestoreHandler = (cache: ApolloCache<NormalizedCacheObject>, key: string) => () => {
  const data = localStorage.getItem(key);
  if (data && data !== 'undefined') {
    try {
      cache.restore(JSON.parse(data ?? '{}'));
    } catch (error) {
      captureException(error as Error);
    }
  }
};
