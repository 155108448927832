import * as T from '@aily/graphql-sdk/schema';
import { PageMenu, PageMenuItemProps } from '@aily/saas-core';
import { colors } from '@aily/saas-core/theme/default/colors';
import { ModuleCode, ModuleSelect } from '@aily/ui-components';
import { Popover, PopoverProps, styled } from '@mui/material';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';

interface Props extends Omit<PageMenuItemProps, 'onSelect'> {
  modules: T.Module[];
  currentModule?: T.Module;
  parentExpanded?: boolean;
  onSelect?: (module: T.Module) => void;
}

const StyledPopover = styled(({ children, ...rest }: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    disableScrollLock
    {...rest}
  >
    {children}
  </Popover>
))(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: 394,
    maxHeight: 540,
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    borderRadius: 18,
    background: colors.neutrals.black,
    boxShadow: theme.shadows[20],
    padding: theme.spacing(3),
  },
}));

const isProModule = (moduleCode: T.ModuleCode) => {
  return moduleCode === T.ModuleCode.Pro;
};

const moduleIconCodes: Record<T.ModuleCode, ModuleCode> = {
  [T.ModuleCode.Cmc]: 'cmc',
  [T.ModuleCode.Ebi]: 'ebi',
  [T.ModuleCode.Financial]: 'fin',
  [T.ModuleCode.Gra]: 'gra',
  [T.ModuleCode.Gtm]: 'gtm',
  [T.ModuleCode.Mns]: 'mns',
  [T.ModuleCode.Ppl]: 'ppl',
  [T.ModuleCode.Pro]: 'pro',
  [T.ModuleCode.Qa]: 'qa',
  [T.ModuleCode.Rnd]: 'rnd',
  [T.ModuleCode.Spend]: 'spend',
  [T.ModuleCode.Supply]: 'supply',
  [T.ModuleCode.UnusedMns]: 'mns',
  [T.ModuleCode.Unknown]: 'unknown',
  [T.ModuleCode.Demo]: 'demo',
};

const ModuleSelectPageMenuItem: React.FC<Props> = ({
  modules,
  currentModule,
  parentExpanded,
  onSelect,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
    event.currentTarget.blur();
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleModuleClick = useCallback(
    (module: T.Module) => () => {
      setAnchorEl(undefined);
      onSelect?.(module);
    },
    [onSelect],
  );

  const open = !!anchorEl;

  // Close the popover when the parent closes
  useEffect(() => {
    if (!parentExpanded) {
      setAnchorEl(undefined);
    }
  }, [parentExpanded]);

  // Do not display for less than 2 modules
  if (modules.length < 2) {
    return null;
  }

  return (
    <>
      <PageMenu.Item onClick={handleClick} {...rest} />
      <StyledPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <ModuleSelect>
          {modules.map((module) => (
            <ModuleSelect.Item
              key={module.id}
              onClick={handleModuleClick(module)}
              showCorrelatorIcon={isProModule(module.moduleCode)}
              data-testid={'test-id-module-' + module.name}
              moduleCode={moduleIconCodes[module.moduleCode]}
            />
          ))}
        </ModuleSelect>
      </StyledPopover>
    </>
  );
};

export default ModuleSelectPageMenuItem;
