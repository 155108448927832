import {
  GetModulesDocument,
  GetModulesQuery,
  GetModulesQueryResult,
  GetModulesQueryVariables,
} from '@aily/graphql-sdk/core';
import { Module } from '@aily/graphql-sdk/schema';
import { client } from '@aily/saas-graphql-client';
import { call, put, takeLatest } from 'redux-saga/effects';

import { updateLocalUserSettings } from '@/core/services/localUserSettings';

import {
  fetchModulesFailure,
  fetchModulesRequest,
  fetchModulesSuccess,
  moduleChanged,
} from './slices';

// Watchers
function* onFetchModulesRequest() {
  yield takeLatest(fetchModulesRequest, handleFetchModulesRequest);
}

function* onModuleChanged() {
  yield takeLatest(moduleChanged, handleModuleChanged);
}

// Workers
function* handleFetchModulesRequest() {
  const query = client.query<GetModulesQuery, GetModulesQueryVariables>;
  try {
    const { data, error } = (yield call(query, {
      query: GetModulesDocument,
      fetchPolicy: 'cache-first',
    })) as GetModulesQueryResult;

    if (error) {
      yield put(fetchModulesFailure(error));
    } else if (!data?.modules) {
      yield put(fetchModulesFailure(new Error('Unable to fetch modules')));
    } else {
      yield put(fetchModulesSuccess(data.modules as Module[]));
    }
  } catch (e) {
    yield put(fetchModulesFailure(e as Error));
  }
}

function* handleModuleChanged({ payload: { id: moduleId } }: ReturnType<typeof moduleChanged>) {
  // Set the default module ID to the last module visited
  yield call(updateLocalUserSettings, { defaultModuleId: moduleId });
}

export default [onFetchModulesRequest, onModuleChanged];
