import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const GtmIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 71 35" {...props}>
      <path d="M5.34244 26.1244C5.34244 26.8743 5.08572 27.5087 4.6008 27.999C4.11588 28.4893 3.48834 28.72 2.71818 28.72C1.94801 28.72 1.32047 28.4893 0.835551 27.999C0.350633 27.5087 0.122437 26.8743 0.122437 26.1244C0.122437 25.3457 0.350633 24.7113 0.835551 24.221C1.32047 23.7307 1.91949 23.5 2.71818 23.5C3.45981 23.5 4.08736 23.7307 4.6008 24.221C5.08572 24.7113 5.34244 25.3457 5.34244 26.1244Z" />
      <path d="M12.5666 18.4833C12.5666 20.3145 12.9609 21.6582 13.7493 22.5144C14.5617 23.3468 15.6011 23.7629 16.8674 23.7629C17.5603 23.7629 18.2055 23.6678 18.8028 23.4776C19.424 23.2873 19.9258 23.0614 20.3081 22.7998V13.1323C19.9975 13.061 19.6152 13.0015 19.1612 12.954C18.7072 12.8826 18.1338 12.847 17.4409 12.847C15.8639 12.847 14.6573 13.3702 13.821 14.4166C12.9847 15.4392 12.5666 16.7948 12.5666 18.4833ZM24.6447 26.4028C24.6447 29.3755 23.8801 31.5516 22.351 32.931C20.8457 34.3103 18.528 35 15.398 35C14.2511 35 13.1281 34.9049 12.029 34.7146C10.9538 34.5244 9.97418 34.2746 9.09012 33.9655L9.8786 30.2911C10.6193 30.6003 11.4556 30.85 12.3874 31.0403C13.3431 31.2305 14.3706 31.3257 15.4697 31.3257C17.2139 31.3257 18.4563 30.9689 19.197 30.2555C19.9377 29.542 20.3081 28.4837 20.3081 27.0806V26.3671C19.878 26.5811 19.3045 26.7952 18.5877 27.0092C17.8948 27.2232 17.0944 27.3303 16.1865 27.3303C14.9918 27.3303 13.8927 27.14 12.8892 26.7595C11.9095 26.379 11.0613 25.8201 10.3445 25.0829C9.65162 24.3456 9.10207 23.43 8.69588 22.336C8.31359 21.2183 8.12244 19.934 8.12244 18.4833C8.12244 17.1277 8.32553 15.8792 8.73172 14.7376C9.1618 13.5961 9.77108 12.621 10.5596 11.8124C11.3719 11.0038 12.3516 10.3736 13.4985 9.92175C14.6453 9.46989 15.9475 9.24396 17.405 9.24396C18.8147 9.24396 20.1528 9.35098 21.4191 9.56502C22.6855 9.77906 23.7607 10.005 24.6447 10.2428V26.4028Z" />
      <path d="M29.5884 4.71346L33.9251 4V9.63637H40.5913V13.2394H33.9251V20.8377C33.9251 22.336 34.164 23.4062 34.6419 24.0483C35.1197 24.6904 35.9321 25.0115 37.079 25.0115C37.8675 25.0115 38.5604 24.9283 39.1577 24.7618C39.7789 24.5953 40.2688 24.4407 40.6272 24.298L41.344 27.7227C40.8422 27.9367 40.1851 28.1507 39.3728 28.3648C38.5604 28.6026 37.6046 28.7215 36.5056 28.7215C35.1675 28.7215 34.0445 28.5432 33.1366 28.1864C32.2525 27.8297 31.5477 27.3184 31.022 26.6525C30.4964 25.9628 30.126 25.1423 29.911 24.191C29.6959 23.216 29.5884 22.1101 29.5884 20.8734V4.71346Z" />
      <path d="M55.1054 18.4833C55.1054 16.5332 54.8546 15.13 54.3528 14.2739C53.8749 13.3939 52.967 12.954 51.6289 12.954C51.1511 12.954 50.6254 12.9896 50.052 13.061C49.4785 13.1323 49.0485 13.1918 48.7617 13.2394V28.2934H44.4251V10.2428C45.2614 10.005 46.3485 9.77906 47.6865 9.56502C49.0485 9.35098 50.4821 9.24396 51.9874 9.24396C53.2776 9.24396 54.3289 9.41043 55.1413 9.74338C55.9775 10.0763 56.6705 10.5163 57.22 11.0633C57.4828 10.873 57.8173 10.6709 58.2235 10.4568C58.6297 10.2428 59.0837 10.0526 59.5854 9.88608C60.0872 9.69582 60.6129 9.54123 61.1624 9.42232C61.7359 9.30341 62.3093 9.24396 62.8827 9.24396C64.3402 9.24396 65.5349 9.458 66.4667 9.88608C67.4225 10.2904 68.1632 10.873 68.6888 11.6341C69.2384 12.3713 69.6087 13.275 69.7999 14.3452C70.0149 15.3916 70.1224 16.5451 70.1224 17.8055V28.2934H65.7858V18.4833C65.7858 16.5332 65.5468 15.13 65.069 14.2739C64.5911 13.3939 63.6712 12.954 62.3093 12.954C61.6164 12.954 60.9593 13.0729 60.3381 13.3107C59.7169 13.5247 59.2509 13.7388 58.9403 13.9528C59.1315 14.5474 59.2629 15.1776 59.3346 15.8435C59.4062 16.5094 59.4421 17.2229 59.4421 17.9839V28.2934H55.1054V18.4833Z" />
    </SvgIcon>
  );
};

export default GtmIcon;
