import { ApolloCache, NormalizedCacheObject } from '@apollo/client/core';

import { findQueryKeysByPrefix } from './utils';

/**
 * Creates a handler function that removes page content configuration from the Apollo cache
 * @param cache - The Apollo cache instance to modify
 * @returns A function that when called will:
 * 1. Extract the current cache state
 * 2. Find all query keys prefixed with 'pageContent'
 * 3. Evict each matching query from the ROOT_QUERY
 */
export const getRemoveConfigurationHandler = (cache: ApolloCache<NormalizedCacheObject>) => () => {
  const data = cache.extract();

  // Evict the modules query from the cache
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'modules' });

  // Evict all pageContent queries from
  const queryKeys = findQueryKeysByPrefix(data, 'pageContent');
  queryKeys.forEach((key) => {
    cache.evict({ id: 'ROOT_QUERY', fieldName: key });
  });

  cache.gc();
};
