import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const HrIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 42 35" {...props}>
      <path d="M8.41321 28.7681V0.741444L12.899 0V9.60171C13.3932 9.42871 13.9617 9.28042 14.6043 9.15684C15.2716 9.03327 15.9265 8.97148 16.5691 8.97148C18.1261 8.97148 19.4113 9.19392 20.4246 9.63878C21.4626 10.0589 22.2906 10.6644 22.9085 11.4553C23.5263 12.2215 23.9588 13.1483 24.206 14.2357C24.4778 15.3232 24.6138 16.5342 24.6138 17.8688V28.7681H20.128V18.5732C20.128 17.5352 20.0539 16.6578 19.9056 15.9411C19.782 15.1996 19.5596 14.6065 19.2383 14.1616C18.9417 13.692 18.5339 13.3584 18.0149 13.1606C17.4959 12.9382 16.8533 12.827 16.0872 12.827C15.494 12.827 14.8885 12.8888 14.2706 13.0124C13.6528 13.1359 13.1955 13.2471 12.899 13.346V28.7681H8.41321Z" />
      <path d="M41.0379 13.4202C40.6672 13.2966 40.1482 13.173 39.4809 13.0494C38.8383 12.9011 38.0845 12.827 37.2195 12.827C36.7252 12.827 36.1938 12.8764 35.6254 12.9753C35.0817 13.0741 34.6986 13.1606 34.4762 13.2348V28.7681H29.9904V10.3061C30.8554 9.98479 31.9305 9.68821 33.2157 9.41635C34.5256 9.11977 35.9714 8.97148 37.5532 8.97148C37.8497 8.97148 38.1957 8.9962 38.5912 9.04563C38.9866 9.07034 39.382 9.11977 39.7775 9.19392C40.1729 9.24335 40.556 9.31749 40.9267 9.41635C41.2974 9.49049 41.594 9.56464 41.8165 9.63878L41.0379 13.4202Z" />
      <path d="M5.63321 26.1264C5.63321 26.8925 5.37221 27.5162 4.85021 27.9977C4.34996 28.4792 3.74096 28.72 3.02321 28.72C2.28371 28.72 1.66383 28.4792 1.16358 27.9977C0.663333 27.5162 0.413208 26.8925 0.413208 26.1264C0.413208 25.3604 0.663333 24.7366 1.16358 24.2551C1.66383 23.7517 2.28371 23.5 3.02321 23.5C3.74096 23.5 4.34996 23.7517 4.85021 24.2551C5.37221 24.7366 5.63321 25.3604 5.63321 26.1264Z" />
    </SvgIcon>
  );
};

export default HrIcon;
