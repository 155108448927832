import { alpha } from '@mui/material/styles';

/**
 * Generates custom scrollbar styles.
 *
 * @param {string} thumbColor - The color of the scrollbar thumb.
 * @param {string} trackColor - The color of the scrollbar track.
 * @returns {object} - An object containing CSS styles for custom scrollbars.
 */
export const scrollbarStyles = (thumbColor: string, trackColor: string): object => ({
  scrollbarWidth: 'thin',
  scrollbarColor: `${thumbColor} ${trackColor}`,

  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: trackColor,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: thumbColor,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: alpha(thumbColor, 0.8),
  },
});
