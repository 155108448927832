import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ArrowRightIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <path d="M13.475 10.188a.5.5 0 0 0-.006.707l6.004 6.106-6.004 6.105a.5.5 0 0 0 .006.707.5.5 0 0 0 .707-.006l6.35-6.455a.5.5 0 0 0 0-.703l-6.35-6.455a.5.5 0 0 0-.707-.006z" />
  </SvgIcon>
);

export default ArrowRightIcon;
