import { ApolloCache, NormalizedCacheObject } from '@apollo/client/core';

/**
 * Creates a handler function that purges Apollo cache data from both localStorage and memory
 * @param cache - The Apollo cache instance to reset
 * @param key - The localStorage key to remove
 * @returns A function that when called will:
 * 1. Remove the cached data from localStorage
 * 2. Reset the Apollo cache to its initial state
 */
export const getPurgeHandler = (cache: ApolloCache<NormalizedCacheObject>, key: string) => () => {
  localStorage.removeItem(key);
  cache.reset();
};
