import * as T from '@aily/graphql-sdk/schema';
import React, { useCallback, useMemo } from 'react';

import { findFilterOption } from '../../utils';
import { Option, SliderSelect, SliderSelectProps } from '../SliderSelect';
import { mapSliderFilterOptionToAbstractType } from './utils';

export interface SliderSelectFilterProps extends Omit<SliderSelectProps, 'options' | 'onChange'> {
  /**
   * An array of `FilterOptionResult` objects that will be used to populate the options in the select slider
   */
  filterOptions?: readonly T.FilterOptionResult[];
  /**
   * An optional callback function that will be called when a new option is selected in the slider, passing in the selected FilterOptionResult object as an argument
   * @param filterOption
   */
  onChange?: (filterOption: T.FilterOptionResult) => void;
}

const SliderSelectFilter: React.FC<SliderSelectFilterProps> = ({
  filterOptions,
  onChange,
  ...rest
}) => {
  /**
   * This function takes in the new selected value, and uses the `findFilterOption` utility function to find the corresponding `FilterOptionResult` object.
   * It then calls the `onChange` callback function (if it is defined) with that `FilterOptionResult` object as an argument.
   */
  const handleChange = useCallback(
    (newValue: number) => {
      const filterOption = findFilterOption(
        filterOptions ?? [],
        ({ value }) => value === newValue,
      ) as T.FilterOptionResult;

      onChange?.(filterOption);
    },
    [onChange, filterOptions],
  );

  const options = useMemo<Option[]>(
    () =>
      filterOptions?.map((filterOption) => mapSliderFilterOptionToAbstractType(filterOption)) ?? [],
    [filterOptions],
  );

  // Don't show filter with empty options
  if (!filterOptions?.length) {
    return null;
  }

  return <SliderSelect options={options} onChange={handleChange} {...rest} />;
};

export default SliderSelectFilter;
