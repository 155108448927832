import { MicroChartSeriesDataPoint } from '../MicroChart';

/**
 * This function checks if only one numeric data point is provided, if yes, it returns
 * an array with two identical values, so a line can be drawn
 *
 * @param data
 */
export function duplicateSingleNumericDataPoint(
  data: MicroChartSeriesDataPoint[],
): MicroChartSeriesDataPoint[] {
  if (data.length === 1 && typeof data[0] === 'number') {
    return [data[0], data[0]];
  }

  return data;
}
