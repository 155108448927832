import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const CrmIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 55 35" {...props}>
      <path d="M0.292969 19.0682C0.292969 17.6596 0.51295 16.3481 0.952913 15.1338C1.39288 13.8952 2.01616 12.8266 2.82276 11.9279C3.6538 11.0293 4.65593 10.325 5.82917 9.81501C7.0024 9.305 8.32229 9.04999 9.78883 9.04999C11.5976 9.04999 13.3085 9.37786 14.9217 10.0336L13.9685 13.6402C13.4552 13.4216 12.8686 13.2394 12.2086 13.0937C11.5731 12.948 10.8887 12.8751 10.1555 12.8751C8.42006 12.8751 7.10017 13.4216 6.1958 14.5145C5.29144 15.5831 4.83925 17.101 4.83925 19.0682C4.83925 20.9626 5.26699 22.4683 6.12248 23.5855C6.97796 24.6784 8.42006 25.2248 10.4488 25.2248C11.2065 25.2248 11.952 25.152 12.6853 25.0063C13.4185 24.8606 14.054 24.6784 14.5918 24.4598L15.215 28.1028C14.7262 28.3457 13.9807 28.5643 12.9786 28.7585C12.0009 28.9528 10.9865 29.05 9.93549 29.05C8.29785 29.05 6.86797 28.8071 5.64585 28.3214C4.44817 27.8114 3.44604 27.1192 2.63944 26.2449C1.85728 25.3463 1.27066 24.2898 0.879586 23.0755C0.488508 21.8369 0.292969 20.5011 0.292969 19.0682Z" />
      <path d="M26.8596 13.4944C26.493 13.373 25.9797 13.2516 25.3197 13.1301C24.6842 12.9844 23.9387 12.9116 23.0832 12.9116C22.5944 12.9116 22.0689 12.9601 21.5067 13.0573C20.969 13.1544 20.5901 13.2394 20.3701 13.3123V28.5764H15.9339V10.4343C16.7893 10.1186 17.8526 9.82716 19.1236 9.56001C20.419 9.26857 21.8489 9.12285 23.4132 9.12285C23.7065 9.12285 24.0487 9.14713 24.4398 9.19571C24.8309 9.21999 25.222 9.26857 25.613 9.34143C26.0041 9.39 26.383 9.46286 26.7496 9.56001C27.1162 9.63286 27.4096 9.70572 27.6295 9.77858L26.8596 13.4944Z" />
      <path d="M38.9309 18.5582C38.9309 16.5667 38.6743 15.1338 38.161 14.2595C37.6722 13.3609 36.7433 12.9116 35.3746 12.9116C34.8857 12.9116 34.348 12.948 33.7614 13.0208C33.1748 13.0937 32.7348 13.1544 32.4415 13.203V28.5764H28.0052V10.1429C28.8607 9.90002 29.9728 9.66929 31.3416 9.45072C32.7348 9.23214 34.2013 9.12285 35.7412 9.12285C37.0611 9.12285 38.1366 9.29285 38.9676 9.63286C39.8231 9.97288 40.5319 10.4222 41.0941 10.9808C41.363 10.7865 41.7051 10.58 42.1207 10.3615C42.5362 10.1429 43.0006 9.94859 43.5139 9.77858C44.0272 9.58429 44.5649 9.42643 45.1271 9.305C45.7137 9.18356 46.3003 9.12285 46.8869 9.12285C48.3779 9.12285 49.6 9.34143 50.5533 9.77858C51.531 10.1915 52.2887 10.7865 52.8264 11.5636C53.3886 12.3165 53.7675 13.2394 53.963 14.3323C54.183 15.4009 54.293 16.5788 54.293 17.866V28.5764H49.8567V18.5582C49.8567 16.5667 49.6123 15.1338 49.1234 14.2595C48.6346 13.3609 47.6935 12.9116 46.3003 12.9116C45.5915 12.9116 44.9193 13.033 44.2838 13.2759C43.6483 13.4944 43.1717 13.713 42.8539 13.9316C43.0495 14.5388 43.1839 15.1823 43.2572 15.8624C43.3306 16.5424 43.3672 17.271 43.3672 18.0482V28.5764H38.9309V18.5582Z" />
    </SvgIcon>
  );
};

export default CrmIconNoDot;
