import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import React from 'react';

import { Stack } from '../../../../../../../Stack';
import { Typography } from '../../../../../../../Typography';

type TitleSentiment = 'positive' | 'negative';
type SubtitleSentiment = 'positive' | 'negative' | 'neutral';

type TitleProps =
  | { sentimentTitle: string; sentimentTitleType: TitleSentiment }
  | { sentimentTitle?: never; sentimentTitleType?: never };

type SubtitleProps =
  | { sentimentSubtitle: string; sentimentSubtitleType: SubtitleSentiment }
  | { sentimentSubtitle?: never; sentimentSubtitleType?: never };

export type ParagraphSentimentProps = {
  description: string;
} & TitleProps &
  SubtitleProps;

const ParagraphContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.tokens.spacing.xsmall} 0`,
  gap: theme.tokens.spacing.xxsmall,
}));

const titleSentimentColorMap: Record<TitleSentiment, ColorTokenKey> = {
  positive: 'text.success',
  negative: 'text.danger',
};

const subtitleSentimentColorMap: Record<SubtitleSentiment, ColorTokenKey> = {
  positive: 'text.success',
  negative: 'text.danger',
  neutral: 'text.regular',
};

const ParagraphSentiment: React.FC<ParagraphSentimentProps> = ({
  sentimentTitle,
  sentimentTitleType,
  sentimentSubtitle,
  sentimentSubtitleType,
  description,
}) => {
  return (
    <ParagraphContainer>
      {sentimentTitle && sentimentTitleType && (
        <Typography
          variant="body.bold-condensed"
          color={titleSentimentColorMap[sentimentTitleType]}
        >
          {sentimentTitle}
        </Typography>
      )}
      <Typography variant="body.small-condensed" lineClamp={6}>
        {description}
      </Typography>
      {sentimentSubtitle && sentimentSubtitleType && (
        <Typography
          variant="body.small-condensed"
          color={subtitleSentimentColorMap[sentimentSubtitleType]}
        >
          {sentimentSubtitle}
        </Typography>
      )}
    </ParagraphContainer>
  );
};

export default ParagraphSentiment;
