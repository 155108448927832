import { BorderRadiusTokenValue } from '../../../types';

// Define token object with strict type checking
const borderRadius = {
  '0': '0px',
  '4': '4px',
  '12': '12px',
  '24': '24px',
  '9999': '9999px',
  none: '0px',
  small: '4px',
  'small-medium': '8px',
  medium: '12px',
  large: '24px',
  round: '9999px',
} as const satisfies Record<string, BorderRadiusTokenValue>;

export default borderRadius;
