import React from 'react';

import SvgIcon, { SvgIconProps } from '../../icons/SvgIcon';

const GraIconNoDot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 50 35" {...props}>
      <path d="M5.41877 18.4305C5.41877 20.2676 5.81819 21.6155 6.61703 22.4744C7.44008 23.3095 8.4931 23.727 9.77609 23.727C10.4781 23.727 11.1317 23.6316 11.7369 23.4407C12.3663 23.2498 12.8746 23.0232 13.262 22.7607V13.0624C12.9473 12.9908 12.5599 12.9312 12.1 12.8834C11.6401 12.8119 11.0591 12.7761 10.3571 12.7761C8.75938 12.7761 7.53691 13.301 6.68966 14.3507C5.8424 15.3766 5.41877 16.7365 5.41877 18.4305ZM17.6556 26.3753C17.6556 29.3575 16.881 31.5406 15.3317 32.9243C13.8066 34.3081 11.4585 35 8.28734 35C7.12539 35 5.98764 34.9046 4.8741 34.7137C3.78477 34.5228 2.79227 34.2723 1.8966 33.9622L2.69544 30.2761C3.44587 30.5862 4.29313 30.8367 5.23721 31.0276C6.20551 31.2185 7.24642 31.3139 8.35996 31.3139C10.1271 31.3139 11.3859 30.956 12.1363 30.2403C12.8867 29.5245 13.262 28.4629 13.262 27.0552V26.3395C12.8262 26.5542 12.2452 26.7689 11.519 26.9836C10.817 27.1984 10.0061 27.3057 9.08618 27.3057C7.87582 27.3057 6.76228 27.1149 5.74557 26.7331C4.75307 26.3514 3.8937 25.7907 3.16748 25.0511C2.46547 24.3115 1.9087 23.393 1.49718 22.2955C1.10986 21.1742 0.916199 19.8858 0.916199 18.4305C0.916199 17.0706 1.12196 15.818 1.53349 14.6728C1.96922 13.5276 2.58651 12.5494 3.38535 11.7383C4.2084 10.9271 5.2009 10.2948 6.36286 9.84153C7.52481 9.38822 8.84411 9.16157 10.3208 9.16157C11.749 9.16157 13.1046 9.26893 14.3876 9.48366C15.6706 9.69838 16.7599 9.92503 17.6556 10.1636V26.3753Z" />
      <path d="M32.3801 13.456C32.017 13.3368 31.5086 13.2175 30.855 13.0982C30.2256 12.955 29.4873 12.8834 28.64 12.8834C28.1559 12.8834 27.6354 12.9312 27.0787 13.0266C26.5461 13.122 26.1709 13.2055 25.953 13.2771V28.272H21.5594V10.4499C22.4066 10.1398 23.4597 9.85346 24.7184 9.59102C26.0014 9.30472 27.4176 9.16157 28.9668 9.16157C29.2573 9.16157 29.5962 9.18543 29.9835 9.23315C30.3709 9.257 30.7582 9.30472 31.1455 9.37629C31.5328 9.42401 31.908 9.49559 32.2711 9.59102C32.6342 9.66259 32.9247 9.73417 33.1426 9.80574L32.3801 13.456Z" />
      <path d="M42.5225 25.2301C43.8781 25.2301 44.9069 25.1585 45.6089 25.0153V20.2198C45.3668 20.1483 45.0158 20.0767 44.5559 20.0051C44.096 19.9335 43.5876 19.8978 43.0308 19.8978C42.5467 19.8978 42.0504 19.9335 41.5421 20.0051C41.0579 20.0767 40.6101 20.2079 40.1986 20.3988C39.8113 20.5896 39.4966 20.864 39.2545 21.2219C39.0124 21.5559 38.8914 21.9853 38.8914 22.5102C38.8914 23.5361 39.2182 24.2519 39.8718 24.6575C40.5254 25.0392 41.4089 25.2301 42.5225 25.2301ZM42.1594 9.09C43.6118 9.09 44.8343 9.26893 45.8268 9.6268C46.8193 9.98468 47.606 10.4857 48.187 11.1299C48.7922 11.774 49.2158 12.5614 49.4579 13.4918C49.7242 14.3984 49.8573 15.4005 49.8573 16.498V27.8425C49.1795 27.9857 48.1507 28.1527 46.7709 28.3436C45.4153 28.5583 43.8781 28.6656 42.1594 28.6656C41.0216 28.6656 39.9807 28.5583 39.0366 28.3436C38.0925 28.1288 37.2816 27.7829 36.6038 27.3057C35.9502 26.8286 35.4297 26.2083 35.0424 25.4448C34.6793 24.6813 34.4977 23.7389 34.4977 22.6176C34.4977 21.544 34.7035 20.6374 35.115 19.8978C35.5508 19.1582 36.1317 18.5617 36.858 18.1084C37.5842 17.6312 38.4193 17.2972 39.3634 17.1063C40.3317 16.8916 41.3363 16.7843 42.3772 16.7843C42.8614 16.7843 43.3697 16.8201 43.9023 16.8916C44.4349 16.9393 45.0037 17.0348 45.6089 17.1779V16.4622C45.6089 15.9612 45.5484 15.484 45.4274 15.0307C45.3063 14.5774 45.0885 14.1837 44.7738 13.8497C44.4833 13.4918 44.0839 13.2175 43.5755 13.0266C43.0914 12.8357 42.4741 12.7403 41.7236 12.7403C40.7069 12.7403 39.7749 12.8119 38.9277 12.955C38.0804 13.0982 37.3905 13.2652 36.858 13.456L36.3133 9.94889C36.8701 9.75802 37.681 9.56716 38.7461 9.37629C39.8113 9.18543 40.949 9.09 42.1594 9.09Z" />
    </SvgIcon>
  );
};

export default GraIconNoDot;
